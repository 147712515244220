import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'avn/services/api.service';
import { SharedCompsService } from 'avn/shared/shared-comps.service';
import { debounceTime } from 'rxjs';

import { User } from '../../../models/user';
import { AuthAppService } from '../../../services/auth-app/auth-app.service';
import { MegaMenuComponent } from '../mega-menu/mega-menu.component';
import { MegaMenuMobileComponent } from '../mega-menu-mobile/mega-menu-mobile.component';
import { NotificationsComponent } from '../notifications/notifications.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedUser: any;
  showBusinessDashboard = true;
  business_uuid: string;

  notif_timeout: any;
  notificationCount: any;

  constructor(
    public router: Router,
    private authService: AuthAppService,
    private dialog: MatDialog,
    private api: ApiService,
    private shared: SharedCompsService
  ) {
    this.authService.loggedUser.subscribe((userData: any) => {
      this.loggedUser = userData;
      if (userData?.email && userData?.businesses?.length > 0) {
        this.business_uuid = userData?.businesses[0]?.uuid;
        this.fetchNotifications();
      } else {
        this.business_uuid = '';
        this.notificationList = [];
      }
    });

    this.shared.notificationChangeDetect.pipe(debounceTime(300)).subscribe(() => {
      this.fetchNotifications();
    });
  }

  @Input() user: User;

  activeMenu: string | null = null;

  isDashboard = false;
  isSearchEnabled = false;
  isServicePage = false;
  isOnlySearchPage = false;

  notificationList = [];

  // notificationList = [
  //   {
  //     title: 'LH12345',
  //     message: 'waiting at 1235'
  //   },
  //   {
  //     title: 'LH12335',
  //     message: 'waiting at 123235'
  //   }
  // ];

  ngOnInit(): void {
    this.setDefaultData();
  }

  setDefaultData() {
    const url_split = this.router.url.split('/');
    if (url_split[1] === 'business' || url_split[1] === 'wellbeing') {
      this.isDashboard = true;
    } else {
      this.isDashboard = false;
    }
    if (url_split[1] === 'search' || url_split[1] === 'category') {
      this.isSearchEnabled = true;
    } else {
      this.isSearchEnabled = false;
    }
    if (url_split[1] === 'search') {
      this.isOnlySearchPage = true;
    } else {
      this.isOnlySearchPage = false;
    }
    this.isServicePage = url_split[1] === 'search';
  }

  checkIsSearchPage() {
    this.setDefaultData();
    return this.isSearchEnabled;
  }

  checkIsOnlySearchPage() {
    this.setDefaultData();
    return this.isOnlySearchPage;
  }

  toggleMenu(menu: string) {
    if (this.activeMenu === menu) {
      this.activeMenu = null;
    } else {
      this.activeMenu = menu;
    }
  }

  menuClosed(menu: string) {
    if (this.activeMenu === menu) {
      this.activeMenu = null;
    }
  }

  openMainMenu(menu: string) {
    this.dialog.open(MegaMenuComponent, {
      width: '900px',
      height: '70vh',
      backdropClass: 'mega-menu-backdrop',
      panelClass: ['mega-menu-panel', 'popup-container'],
      position: {
        top: '67px'
      },
      data: {
        menu
      }
    });
  }

  openMobileMainMenu(menu: string) {
    const backdropClass =
      this.isDashboard || this.isSearchEnabled ? ['mobile-mega-menu-backdrop', 'full'] : 'mobile-mega-menu-backdrop';
    this.dialog.open(MegaMenuMobileComponent, {
      width: '100%',
      maxWidth: '375px',
      backdropClass: backdropClass,
      panelClass: ['mobile-mega-menu-panel', 'popup-container'],
      position: {
        top: '67px'
      },
      data: {
        menu
      }
    });
  }

  loginPage() {
    this.router.navigate(['/login']);
  }

  navigateToBusinessDashboard() {
    console.warn(this.loggedUser);
    const business_id: any = this.loggedUser?.businesses[0]?.id;
    const dashboardUrl = 'business/' + business_id + '/profile';
    this.router.navigate([dashboardUrl]);
  }

  navigateToLogin() {
    if (this.router.url.indexOf('/login?') === -1) {
      this.router.navigate(['/login'], { queryParams: { redirect: this.router.url } });
    } else {
      this.router.navigate(['/login'], { queryParams: { redirect: '' } });
    }
  }
  navigateToProviderLogin() {
    if (this.router.url.indexOf('/login?') === -1) {
      this.router.navigate(['/login'], { queryParams: { redirect: this.router.url, action: 'provider_signin' } });
    } else {
      this.router.navigate(['/login'], { queryParams: { redirect: '', action: 'provider_signin' } });
    }
  }
  navigateToSignup() {
    if (this.router.url.indexOf('/login?') === -1) {
      this.router.navigate(['/login'], { queryParams: { redirect: this.router.url, action: 'signup' } });
    } else {
      this.router.navigate(['/login'], { queryParams: { redirect: '', action: 'signup' } });
    }
  }

  fetchNotifications() {
    if (this.loggedUser && this.loggedUser.email) {
      if (this.notif_timeout) {
        clearTimeout(this.notif_timeout);
      }
      this.api.fetchNotifications().subscribe((response: any) => {
        this.notificationList = response.data;
        const unreadNotifications = this.notificationList.filter((x: any) => x?.is_read === false);
        this.notificationCount = unreadNotifications?.length > 9 ? '9+' : unreadNotifications?.length;
        this.shared.notificationsList = response.data;
        this.notif_timeout = setTimeout(() => {
          this.fetchNotifications();
        }, 15000);
      });
    }
  }

  openNotificationMenu() {
    const backdropClass = this.isDashboard
      ? ['mobile-notification-menu-backdrop', 'full']
      : 'mobile-notification-menu-backdrop';
    this.dialog.open(NotificationsComponent, {
      width: '100%',
      maxWidth: '375px',
      backdropClass: backdropClass,
      panelClass: ['mobile-notification-menu-panel', 'popup-container'],
      position: {
        top: '67px'
      },
      data: {
        list: [...this.notificationList],
        businessId: this.business_uuid
      }
    });
  }

  checkNotifications() {
    return this.loggedUser?.email && this.business_uuid;
  }
}
