import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'avn/services/api.service';
import { SharedCompsService } from 'avn/shared/shared-comps.service';

export interface INotificationObj {
  all_user: boolean;
  associated: string;
  business: string;
  created_at: string;
  id: number;
  image: string;
  is_seen: boolean;
  is_read: boolean;
  is_deleted: boolean;
  message: string;
  platform: 'EML' | 'Trinity' | 'HII' | 'Logic Health' | 'Avaana';
  reference: string;
  title: string;
  type: 'Booking' | 'AHTR Forms' | 'Case Note Forms';
  updated_at: string;
  user: number;
  uuid: string;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<NotificationsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private api: ApiService,
    private shared: SharedCompsService
  ) {}

  notifications: any = [];

  activeTab: 'new' | 'bookings' | 'forms' = 'new';

  ngOnInit(): void {
    this.notifications = this.data.list;
  }

  gotoNotificationItem(item: INotificationObj) {
    this.readNotification(item.uuid);
    this.seenNotification(item.uuid);
    if (item.type === 'Booking' && item.business) {
      switch (item.platform.toLowerCase()) {
        case 'eml':
        case 'trinity':
        case 'hii':
        case 'logic health':
          this.router.navigate([
            `/business/${item.business}/bookings/workcover/${item.platform.toLowerCase().replace(/ /g, '')}/${
              item.associated
            }`
          ]);
          this.closeModal();
          break;
        case 'avaana':
          this.router.navigate([`/business/${item.business}/bookings/marketplace/${item.associated}`]);
          this.closeModal();
          break;
        default:
          break;
      }
    } else if (item.type === 'AHTR Forms') {
      this.router.navigate([`/business/${item.business}/client-list/`], {
        queryParams: {
          uuid: item.associated
        }
      });
      this.closeModal();
    } else if (item.type === 'Case Note Forms') {
      this.router.navigate([`/business/${item.business}/client-list/`], {
        queryParams: {
          uuid: item.associated,
          type: 'case_note'
        }
      });
      this.closeModal();
    }
    return;
  }

  closeModal() {
    this.dialogRef.close();
  }

  unreadAll() {
    this.api.unreadAllNotifications().subscribe({
      next: () => {
        this.shared.notificationChangeDetect.next(true);
      }
    });
  }

  readNotification(uuid: string) {
    this.api.readNotification(uuid).subscribe({
      next: () => {
        this.shared.notificationChangeDetect.next(true);
      }
    });
  }

  seenNotification(uuid: string) {
    this.api.seenNotification(uuid).subscribe({
      next: () => {
        this.shared.notificationChangeDetect.next(true);
      }
    });
  }

  readAllClick() {
    this.api.readAllNotifications().subscribe({
      next: () => {
        this.shared.notificationChangeDetect.next(true);
      }
    });
  }

  getTabCount(tab: string) {
    const list = this.getNotifications(tab);
    return list.filter((x: any) => !x.is_read).length;
  }

  getNotifications(tab: string) {
    if (tab === 'bookings') {
      return this.shared.notificationsList.filter((x: any) => x.type === 'Booking');
    } else if (tab === 'forms') {
      return this.shared.notificationsList.filter(
        (x: any) => x.type === 'Case Note Forms' || x.type === 'AHTR Forms' || x.type === 'Forms'
      );
    } else if (tab === 'new') {
      return this.shared.notificationsList.filter((x: any) => !x.is_read);
    }
    return this.shared.notificationsList || [];
  }
}
