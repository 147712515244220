<ng-container *ngIf="viewType === 'normal'">
  <div class="search-box {{dropdownType}} {{searchType}} desss" [ngClass]="{'in-header-search': isHeader}">
    <div class="dess search-input-box border service" [ngClass]="{'show': dropdownType === 'search'}">
      <button aria-label="Search"><img src="assets/images/search.svg" alt=""></button>
      <input type="text" (click)="openDropdown('search')" placeholder="Service, provider or symptom" [(ngModel)]="serviceText" (input)="searchInput()">
      <div class="search-service-pop">
        <div class="mobile-input">
          <button aria-label="Search"><img src="assets/images/search.svg" alt=""></button>
          <input type="text" id="searchInputMob" (click)="openDropdown('search')" placeholder="Service, provider or symptom" [(ngModel)]="serviceText" (input)="searchInput()">
          <button (click)="openDropdown('')">Cancel</button>
        </div>
        <div class="search-service-wrapper for-service">
          <div class="popular-services" *ngIf="filteredPopularCategories()?.length > 0">
            <p class="services-title">Popular services</p>
            <ul>
              <ng-container *ngFor="let popular of filteredPopularCategories()">
                <li [innerHTML]="popular.name | highlight:getServiceText()" (click)="selectOption(popular.name, 'service')"></li>
              </ng-container>
            </ul>
          </div>
          <div class="more-services" *ngIf="filteredAllServices()?.length > 0">
            <p class="services-title">More services (a-z)</p>
            <ul>
              <ng-container *ngFor="let service of filteredAllServices()">
                <li [innerHTML]="service.name | highlight:getServiceText()" (click)="selectOption(service.name, 'service')"></li>
              </ng-container>
            </ul>
          </div>
          <div class="more-services" *ngIf="filteredBusinesses()?.length > 0">
            <p class="services-title">Wellbeing Businesses</p>
            <ul>
              <ng-container *ngFor="let biz of filteredBusinesses() | slice : 0 : 10">
                <li [innerHTML]="biz.name | highlight:getServiceText()" (click)="selectBizOption(biz, 'business', biz.slug)"></li>
              </ng-container>
            </ul>
          </div>
          <div class="more-services" *ngIf="filteredHealthConditions()?.length > 0">
            <p class="services-title">Health Conditions</p>
            <ul>
              <ng-container *ngFor="let health_condition of filteredHealthConditions() | slice : 0 : 10">
                <li [innerHTML]="health_condition.name | highlight:getServiceText()" (click)="selectOption(health_condition.name, 'health_condition')"></li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="dess search-input-box border location" *ngIf="searchType !== 'business'" [ngClass]="{'show': dropdownType === 'location'}">
      <button aria-label="Location Pin"><img src="assets/images/pin.svg" alt=""></button>
      <input type="text" id="location-input" #locationInput placeholder="Select a location" (focus)="locationFocus()" (blur)="locationFocusOut($event)" (input)="onLocationTextChanged($event)" (click)="openDropdown('location')">
      <i class="fa fa-spin fa-spinner fetching-loading" *ngIf="fetchingLocation"></i>
      <div class="search-service-pop">
        <div class="mobile-input">
          <button aria-label="Location Pin"><img src="assets/images/pin.svg" alt=""></button>
          <input type="text" id="locationMainInput" placeholder="Select a location" (input)="onLocationTextChanged($event)" (click)="openDropdown('location')">
          <button (click)="openDropdown('')">Cancel</button>
        </div>
        <div class="search-service-wrapper">
          <button class="current-location-btn" (click)="getmeraLocation()"><img src="assets/images/map-location-arrow.svg" alt="location"> USE MY CURRENT LOCATION</button>
          <ul>
            <ng-container *ngFor="let location of filteredLocationsList">
              <li (click)="selectLocation(location, true)">{{location.displayText}}</li>
            </ng-container>
          </ul>
          <div class="online-section">
            <p>Looking for online telehealth? Select the button below for online appointments.</p>
            <button class="online-btn" (click)="changeToOnline()"><img src="assets/images/video.svg" alt="video"> ONLINE AND TELEHEALTH APPOINTMENTS</button>
          </div>
        </div>
      </div>
    </div>
    <div class="dess search-input-box calendar" id="calendar-input-id">
      <button aria-label="Calendar"><img src="assets/images/calendar.svg" alt=""></button>
      <app-forms-datepicker [value]="selectedDate" [hasIcon]="true" [min]="minDate" (changed)="dateChanged($event)"></app-forms-datepicker>
      <!-- <input placeholder="Today" [(ngModel)]="selectedDate" (click)="openDropdown('calendar');calender.open()" (dateChange)="dateChanged($event)" [matDatepicker]="calender" [min]="todayDate">
      <mat-datepicker #calender></mat-datepicker> -->
    </div>
    <button class="main-search" (click)="searchNow()">Search</button>
  </div>
  <div *ngIf="pageType && isHeader" class="mob-search" (click)="openMobilePop()">
    <img class="search-icon" src="assets/images/search-outlined.svg" alt="search item">
    <div class="content">
      <p class="service">{{getServiceText()}} <span *ngIf="locationText">in {{locationText | slugText : 'capitalize'}}</span></p>
    </div>
  </div>
  <button aria-label="Search" *ngIf="!pageType && isHeader" class="mobile-search" (click)="openMobilePop()">Search for health and wellbeing <span><img src="assets/images/search.svg" alt=""></span></button>
</ng-container>

<ng-container *ngIf="viewType === 'mobile'">
  <div class="mob-header">
    <div class="back" *ngIf="showBack" (click)="back()"><img src="assets/images/booking/left-arrow.svg" alt="left arrow"></div>
    <div *ngIf="getPageType()" class="mob-search" [ngClass]="{'show': viewType === 'mobile'}" (click)="openMobilePop()">
      <img class="search-icon" src="assets/images/search-outlined.svg" alt="search item">
      <div class="content">
        <p class="service">{{getServiceText()}} <span *ngIf="locationText">in {{locationText | slugText : 'capitalize'}}</span></p>
      </div>
    </div>
    <button class="map-list" *ngIf="currentPage === 'search'" (click)="changeCurrentView()">
      <img src="{{currentView === 'list' ? 'assets/images/search/map-icon.svg' : 'assets/images/search/list-icon.svg'}}" alt="">
    </button>
  </div>
  <button *ngIf="!getPageType()" aria-label="Search" class="mob" [ngClass]="{'show': viewType === 'mobile'}" (click)="openMobilePop()">Search for health and wellbeing <span><img src="assets/images/search.svg" alt=""></span></button>
</ng-container>
