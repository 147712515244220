export class User {
  id: number;
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  avatar: string;
  provider: string;
  correct_image: string;
  phone_no: string;
  hakunate_id: string;
  age: number;
  last_login: Date;
  is_group_owner: boolean;
  is_superuser: boolean;
  is_active: boolean;
  is_admin: boolean;
  is_staff: boolean;
  is_customer: boolean;
  is_owner: boolean;
  is_practitioner: boolean;
  created_at: Date;
  updated_at: Date;
  allow_sms: boolean;
  allow_push: boolean;
  allow_email: boolean;
  businesses = [];
  header_detail: any;
  gender?: string;
  is_wellbeing_owner?: boolean;
  is_wellbeing_employees?: boolean;

  copy(jsonData: any) {
    this.email = jsonData.email;
    this.password = jsonData.password;
    this.first_name = jsonData.first_name;
    this.last_name = jsonData.last_name;
    this.avatar = jsonData.avatar;
    this.provider = jsonData.provider;
    this.correct_image = jsonData.correct_image;
    this.phone_no = jsonData.phone_no;
    this.hakunate_id = jsonData.hakunate_id;
    //		this.last_login = new Date(jsonData.last_login);
    this.is_superuser = jsonData.is_superuser;
    this.is_active = jsonData.is_active;
    this.is_admin = jsonData.is_admin;
    this.is_staff = jsonData.is_staff;
    this.is_customer = jsonData.is_customer;
    this.is_owner = jsonData.is_owner;
    this.is_practitioner = jsonData.is_practitioner;
    //		this.created_at = new Date(jsonData.created_at);
    //		this.updated_at = new Date(jsonData.updated_at);
    this.id = jsonData.id;
  }
}
