import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import {
  AppointmentRequest,
  ICurrentWeekParams,
  IEarliestAvailability,
  SearchRequest
} from '../modules/service-list/search-page/service.interface';

import { Constants } from './constants';
import { StoreService } from './store.service';

const BASE_URL = Constants.BASE_URL;
const BASE_URL_withoutAPI = Constants.BASE_URL_withoutAPI;

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private store: StoreService,
    private injector: Injector
  ) {
    this.errorHandler = this.injector.get(ErrorHandler);
  }

  private errorHandler: ErrorHandler;

  // HOME PAGE SERVICES

  getHeaderUrls(): Observable<any> {
    const endPoint = '/mega-menu-categories/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getAllAvailableBusiness(): Observable<any> {
    const endPoint = `/all-business-search-list/?v=${this.store.getRandomNumber()}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getHealthConditions(): Observable<any> {
    const endPoint = `/symptoms/?v=${this.store.getRandomNumber()}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getPopularList(): Observable<any> {
    const endPoint = `/get-avaana-categories/?limit=15&v=${this.store.getRandomNumber()}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getAllServices(): Observable<any> {
    const endPoint = `/get-avaana-categories/?v=${this.store.getRandomNumber()}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getGeoCodingByAddress(address: string): Observable<any> {
    const endPoint = '/get-geocode/?address=' + encodeURI(address);
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  wellbeingBusinessesNearMe(searchRequest: any, page_no = 1, params?: any, orderby?: any): Observable<any> {
    let endPoint = `/search-service/?page=${page_no}&v=${this.store.getRandomNumber()}`;
    if (params) {
      endPoint += `&${params}`;
    }
    if (orderby) {
      endPoint += `&order_by=${orderby}`;
    }
    return this.httpClient.post(BASE_URL + endPoint, searchRequest).pipe(catchError(this.handleError.bind(this)));
  }

  symptomBusinessesNearMe(searchRequest: any, page_no = 1): Observable<any> {
    const endPoint = `/search-health-condition/?page=${page_no}&v=${this.store.getRandomNumber()}`;
    return this.httpClient.post(BASE_URL + endPoint, searchRequest).pipe(catchError(this.handleError.bind(this)));
  }

  // HEALTH HUB APIs

  getFeaturedPosts(search?: string): Observable<any> {
    let endPoint = '?per_page=10';
    if (search) {
      endPoint += '&search=' + search;
    }
    return this.httpClient.get(Constants.BLOG_POST_API_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getCategoryById(id: number): Observable<any> {
    const endPoint = '/' + id;
    return this.httpClient
      .get(Constants.BLOG_CATEGORIES_API_URL + endPoint)
      .pipe(catchError(this.handleError.bind(this)));
  }

  fetchAuthorById(id: number): Observable<any> {
    const endPoint = '/' + id;
    return this.httpClient.get(Constants.BLOG_USER_API_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  // getFeaturedPosts(): Observable<any> {
  //   const endPoint = '/wp-content/';
  //   const blog_url = { url: Constants.BLOG_URL + '/posts-ordered?_fields=author,category' };
  //   return this.httpClient.post(BASE_URL + endPoint, blog_url);
  // }

  getAllSuburbLocationlist(): Observable<any> {
    const endPoint = `/business-categories-suburbs/?v=${this.store.getRandomNumber()}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  earliestDates(
    prac_id: string,
    serviceId: string,
    appointment_date: string,
    current_time: string,
    timezone: string
  ): Observable<IEarliestAvailability> {
    const endPoint = `/slot/availability/${serviceId}/${prac_id}/?v=${this.store.getRandomNumber(
      true
    )}&appointment_date=${appointment_date}&current_time=${current_time}&timezone=${timezone}`;
    return this.httpClient
      .get<IEarliestAvailability>(BASE_URL + endPoint)
      .pipe(catchError(this.handleError.bind(this)));
  }

  getAvailableTimes(params: ICurrentWeekParams) {
    let endPoint = '';
    if (params.practitioner_id === 'any') {
      endPoint = `/booking-slots/${params.service_id}/?v=${this.store.getRandomNumber(true)}&start_date=${
        params.start_date
      }&end_date=${params.end_date}&current_time=${params.current_date}&timezone=${params.timezone}`;
    } else {
      endPoint = `/booking-slots/${params.service_id}/${params.practitioner_id}/?v=${this.store.getRandomNumber(
        true
      )}&start_date=${params.start_date}&end_date=${params.end_date}&current_time=${params.current_date}&timezone=${
        params.timezone
      }`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getClassAvailableTimes(params: any) {
    let endPoint = '';
    if (params.practitioner_id === 'any') {
      endPoint = `/class-booking-slots/${params.service_id}/?v=${this.store.getRandomNumber(true)}&start_date=${
        params.start_date
      }&end_date=${params.end_date}&current_time=${params.current_date}`;
    } else {
      endPoint = `/class-booking-slots/${params.service_id}/${params.practitioner_id}/?v=${this.store.getRandomNumber(
        true
      )}&start_date=${params.start_date}&end_date=${params.end_date}&current_time=${params.current_date}`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getPractitionersList(searchRequest: SearchRequest, page_no = 1, params?: any, orderby?: any): Observable<any> {
    let endPoint = `/practitioners-listing/?page=${page_no}&v=${this.store.getRandomNumber()}`;

    if (params) {
      endPoint += `&${params}`;
    }
    if (orderby) {
      endPoint += `&order_by=${orderby}`;
    }
    const body = searchRequest;
    return this.httpClient.post(BASE_URL + endPoint, body).pipe(catchError(this.handleError.bind(this)));
  }

  fetchBusinessServiceDetail(id: string): Observable<any> {
    const endPoint = `/fetch-business-service-detail/${id}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  fetchBusinessClassDetail(id: string): Observable<any> {
    const endPoint = `/fetch-business-class-detail/${id}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  createTemporaryAppointment(apointmentRequest: AppointmentRequest): Observable<any> {
    const endPoint = `/create-temporary-appointment-details/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post(BASE_URL + endPoint, apointmentRequest).pipe(catchError(this.handleError.bind(this)));
  }

  getTemporaryAppointment(temp_id: string): Observable<any> {
    const endPoint = `/get-temporary-appointment-details/${temp_id}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  updateTemporaryAppointment(temp_id: string, data: any): Observable<any> {
    const endPoint = `/update-temporary-appointment-details/${temp_id}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  validateTemporaryAppointment(temp_id: string): Observable<any> {
    const endPoint = `/validate-temporary-appointment-request/${temp_id}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getAppointmentDetails(slug: string): Observable<any> {
    const endPoint = `/get-appointment-details/${slug}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getBookingDetails(slug: string): Observable<any> {
    const endPoint = `/appointment-detail/${slug}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getCustomerBookingDetails(slug: string): Observable<any> {
    const endPoint = `/customer-booking-detail/${slug}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  editJourney(data: any) {
    const endPoint = 'wellbeing/edit-journey/';
    return this.httpClient.post(BASE_URL_withoutAPI + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  getWellbeingJourneysList(): Observable<any> {
    const endPoint = `wellbeing/journey-list/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL_withoutAPI + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getCategoryContent(url: string): Observable<any> {
    const endPoint = '/wp-content/';
    const blog_url = { url: Constants.BLOG_CONTENT_API_URL + url };
    return this.httpClient.post(BASE_URL + endPoint, blog_url).pipe(catchError(this.handleError.bind(this)));
  }

  getCategoryFAQ(category: string): Observable<any> {
    const endPoint = 'faq-' + category;
    return this.httpClient.get(Constants.BLOG_CONTENT_API_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getSearchResultSubUrb(params: any): Observable<any> {
    const endPoint = '/search-result-suburb/';
    return this.httpClient.post(BASE_URL + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }

  rescheduleAppointment(params: any, uuid: string): Observable<any> {
    const endPoint = `/reschedule-appointment/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post<any>(BASE_URL + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }

  confirmRescheduleAppointment(params: any, uuid: string): Observable<any> {
    const endPoint = `/appointment-reschedule/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post<any>(BASE_URL + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }

  cancelAppointment(params: any, uuid: string): Observable<any> {
    const endPoint = `/cancel-appointment/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post<any>(BASE_URL + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }

  fetchMapData(params: any): Observable<any> {
    const endPoint = `/practitioners-location-listing/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post<any>(BASE_URL + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }

  fetchProviderDetails(uuid: string): Observable<any> {
    const endPoint = `/get-provider-detail/${uuid}/?v=${this.store.getRandomNumber()}`;
    return this.httpClient.get<any>(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  checkValidSession(): Observable<any> {
    const endPoint = `/validate-token/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get<any>(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  fetchNotifications() {
    const endPoint = `/get-notifications/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get<any>(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  fetchAmenities() {
    const endPoint = `/fetch-amenities/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get<any>(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  // NOTIFICATIONS APIS
  unreadAllNotifications() {
    const endPoint = `/unread-all-notifications/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get<any>(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  readAllNotifications() {
    const endPoint = `/read-all-notifications/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get<any>(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  readNotification(uuid: string) {
    const endPoint = `/read-notification/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get<any>(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  seenNotification(uuid: string) {
    const endPoint = `/seen-notification/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get<any>(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  generateAboutBiz(uuid: string, data: any): Observable<any> {
    const endPoint = `/generate-about-business/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  generatePracBio(uuid: string, data: any): Observable<any> {
    const endPoint = `/generate-practitioner-bio/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: any): Observable<never> {
    // Call the global error handler
    this.errorHandler.handleError(error);

    // Re-throw the error so it can be caught by the component's subscribe error handler
    return throwError(() => error);
  }
  createBusinessLocation(uuid: string, data: any, internal_url?: string): Observable<any> {
    console.info('Has Internal URL:', internal_url);
    const params = {
      locations: data,
      standalone: internal_url === 'internal'
    };
    const endPoint = `/create-business-location/${uuid}/`;
    return this.httpClient.post(Constants.WORKCOVER + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }
  addRegisteredBusinessLocation(uuid: string, data: any): Observable<any> {
    const endPoint = `/add-registered-business-location/${uuid}/`;
    return this.httpClient.post(Constants.WORKCOVER + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  addBusinessCalendar(data: any): Observable<any> {
    const endPoint = `/business-calendar-integration/${data.business}/`;
    return this.httpClient.post(Constants.WORKCOVER + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
}
