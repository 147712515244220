import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthAppService } from 'avn/services/auth-app/auth-app.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessGuard implements CanActivate {
  constructor(
    private auth: AuthAppService,
    private router: Router
  ) {}

  practitionersAccess = [
    'payments',
    'home',
    'services/marketplace',
    'services/workcover',
    'bookings/marketplace',
    'bookings/workcover',
    'practitioner',
    'client-list',
    'forms',
    'partnership',
    'integration'
  ];

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.getSavedUser()?.email && this.auth.getSavedUser()?.businesses?.length > 0) {
      const currentPath = state.url; // Get the current path
      const match = currentPath.match(/(business\/[^/]+)/);
      const basePath = match ? match[1] : '';
      const hasAccess = this.practitionersAccess.some((accessPath: string) => currentPath.includes(accessPath));
      if (this.isOnlyPractitioner() && hasAccess) {
        return true;
      } else if (this.isOnlyPractitioner() && !hasAccess) {
        this.router.navigate([basePath]);
        return false;
      }
      return true;
    } else {
      this.router.navigate(['']);
      return false;
    }
  }

  isOnlyPractitioner() {
    return (
      this.auth.getSavedUser()?.is_owner === false &&
      this.auth.getSavedUser()?.is_group_owner === false &&
      this.auth.getSavedUser()?.is_practitioner === true
    );
  }
}
