import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'canonicalUrl'
})
export class CanonicalUrlPipe implements PipeTransform {
  transform(url: string): string {
    const urlObject = new URL(url);
    const index = urlObject.pathname.indexOf('href');
    if (index > -1) {
      urlObject.pathname = urlObject.pathname.slice(0, index);
    }
    return urlObject.href;
  }
}
