<div class="mobile-menu-slider">

  <!-- Main menu -->
  <div class="mobile-menu" *ngIf="!selectedMain">
    <div class="mobile-header">
      <button class="arrow-btn" (click)="closeModal()"><img loading="lazy" src="assets/images/close.svg" alt=""></button>
    </div>
    <div class="mobile-menu-body">
      <ul class="profile" *ngIf="loggedUser?.email" [ngClass]="{'logged': loggedUser?.email}">
        <li [routerLink]="['/profile']" (click)="closeModal();">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/person-outline.svg" alt=""></button>
            <p class="name">My Profile</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
        <li [routerLink]="fetchBusinessRouting()" (click)="closeModal();" *ngIf="hasBusiness">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/dashboard.svg" alt=""></button>
            <p class="name">Business Dashboard</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
        <li [routerLink]="['wellbeing']" (click)="closeModal();" *ngIf="isWellbeingOwner">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/dashboard.svg" alt=""></button>
            <p class="name">Wellbeing Dashboard</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
      </ul>
      <ul class="logout-holder" *ngIf="loggedUser?.email">
        <li><a (click)="logoutUser()">Logout <i class="fa fa-arrow-right-from-bracket"></i></a></li>
      </ul>
      <ul class="on-mobile">
        <li (click)="selectedMain = 'wellbeing'">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/fitness.svg" alt=""></button>
            <p class="name">Wellbeing Services</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
        <li (click)="selectedMain = 'others'; selectedGroup = 'Online Services'">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/video.svg" alt=""></button>
            <p class="name">Online Services</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
        <li (click)="selectedMain = 'others'; selectedGroup = 'Health Conditions'">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/heart.svg" alt=""></button>
            <p class="name">Health Conditions</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
        <li [routerLink]="['/blog']" (click)="closeModal()">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/notification.svg" alt=""></button>
            <p class="name">Health Hub</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
      </ul>

      <ng-container *ngIf="!loggedUser?.email">
        <app-no-user-menu (closeIt)="closeModal()"></app-no-user-menu>
      </ng-container>
    </div>
  </div>

  <!-- Wellbeing Services -->
  <div class="mobile-menu others" *ngIf="selectedMain === 'wellbeing' && !selectedGroup">
    <div class="mobile-header">
      <button class="arrow-btn" (click)="selectedMain = ''; selectedGroup = ''; selectedService = undefined"><img loading="lazy" src="assets/images/booking/left-arrow.svg" alt="left arrow"></button>
      <h2>Wellbeing Services</h2>
    </div>
    <div class="mobile-menu-body">
      <ul>
        <li (click)="selectedGroup = 'Physical'">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/fitness.svg" alt=""></button>
            <p class="name">Physical</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
        <li (click)="selectedGroup = 'Mental'">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/video.svg" alt=""></button>
            <p class="name">Mental</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
        <li (click)="selectedGroup = 'Natural'">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/heart.svg" alt=""></button>
            <p class="name">Natural</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
        <li (click)="selectedGroup = 'Fitness'">
          <div class="menu">
            <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/notification.svg" alt=""></button>
            <p class="name">Fitness</p>
          </div>
          <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
        </li>
      </ul>
    </div>
  </div>

  <!-- Physical | Mental | Natural | Fitness | Online | Health Conditions -->
  <div class="mobile-menu others" *ngIf="selectedGroup && !(selectedService)">
    <div class="mobile-header">
      <button class="arrow-btn" (click)="goBackFromGroups()"><img loading="lazy" src="assets/images/booking/left-arrow.svg" alt="left arrow"></button>
      <h2>{{selectedGroup}}</h2>
    </div>
    <div class="mobile-menu-body">
      <ul>
        <ng-container *ngIf="selectedGroup !== 'Health Conditions'; else healthCondition">
          <li *ngFor="let item of headerCategories[selectedGroup]">
            <div class="menu">
              <p class="name" (click)="goToCategoryPage(item)">{{item.name}}</p>
            </div>
            <button class="arrow-btn no-bg" (click)="selectService(item)"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
          </li>
        </ng-container>
        <ng-template #healthCondition>
          <li *ngFor="let item of healthConditionList" (click)="selectService(item)">
            <div class="menu">
              <!-- <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/fitness.svg" alt=""></button> -->
              <p class="name">{{item.name}}</p>
            </div>
            <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>



  <!-- Suburbs -->
  <div class="mobile-menu others" *ngIf="selectedGroup && selectedService">
    <div class="mobile-header">
      <button class="arrow-btn" (click)="selectedService = undefined"><img loading="lazy" src="assets/images/booking/left-arrow.svg" alt="left arrow"></button>
      <h2>{{selectedService.name}}</h2>
    </div>
    <div class="mobile-menu-body">
      <ul>
        <ng-container *ngIf="selectedGroup !== 'Health Conditions'; else healthConditionSubUrbs">
          <ng-container *ngFor="let city of selectedService.cities">
            <li *ngFor="let suburb of city.suburbs" (click)="goToServicePage(selectedService, suburb)">
              <div class="menu">
                <!-- <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/fitness.svg" alt=""></button> -->
                <p class="name" (click)="goToCategoryPage(selectedService, suburb)">{{suburb.name}}</p>
              </div>
              <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
            </li>
          </ng-container>
        </ng-container>
        <ng-template #healthConditionSubUrbs>
          <li *ngFor="let suburb of selectedService.suburb" (click)="goToHealthConditionPage(selectedService, suburb)">
            <div class="menu">
              <!-- <button class="arrow-btn"><img loading="lazy" src="assets/images/menu/fitness.svg" alt=""></button> -->
              <p class="name">{{suburb.city}}</p>
            </div>
            <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>

</div>
