import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { AuthAppService } from 'avn/services/auth-app/auth-app.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileGaurd implements CanActivate {
  constructor(
    private auth: AuthAppService,
    private router: Router
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.getSavedUser()?.email) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
