import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { timezoneConstants, TimezoneInfo } from 'avn/modules/booking-flow/timezone.constatnts';
import { ConfirmPopComponent } from 'avn/shared/components/confirm-pop/confirm-pop.component';
import * as moment from 'moment';
import * as momentz from 'moment-timezone';
import { BehaviorSubject, Subject } from 'rxjs';

import { SaveListComponent } from './components/save-list/save-list.component';

export class ConfirmPopData {
  title = 'Confirm';
  message = 'Are you sure?';
  cancelButtonText = 'Cancel';
  okButtonText = 'Confirm';
  okClassName: 'btn-success' | 'btn-warning' | 'btn-error' = 'btn-success';
  secondaryButtons?: { name: string; class_name: string; value: string }[] = [];
  cancelType: 'button' | 'icon' = 'button';
}

@Injectable({
  providedIn: 'root'
})
export class SharedCompsService {
  constructor(private dialog: MatDialog) {}

  private snackBarSubject = new BehaviorSubject<any>({});
  public snackBar = this.snackBarSubject.asObservable();

  snackBarClose = new Subject<boolean>();

  locationDropdownUpdate = new Subject<any>();

  pageChangeDetect = new Subject();

  notificationChangeDetect = new Subject();

  notificationsList: any[] = [];

  showSnackBar(message: string, duration = 5, buttonText = 'Dismiss', sendBack = false, routerLink?: any) {
    this.snackBarSubject.next({ message, duration, buttonText, sendBack, routerLink });
  }

  confirmPop(options: ConfirmPopData) {
    const confirm = this.dialog.open(ConfirmPopComponent, {
      width: '400px',
      data: { ...options },
      disableClose: true,
      panelClass: ['confirm-pop-modal', 'popup-container']
    });

    return confirm;
  }

  openSaveListPop(options: any) {
    const confirm = this.dialog.open(SaveListComponent, {
      width: '90%',
      maxWidth: '500px',
      maxHeight: '70%',
      data: { ...options },
      disableClose: true,
      panelClass: ['save-list-pop-modal', 'popup-container']
    });

    return confirm;
  }

  fetchOnlyAustraliaTimezone() {
    let timezone = momentz.tz.guess();
    const index = timezoneConstants.timezones.findIndex((x: TimezoneInfo) => x.timezone === timezone);
    if (index === -1) {
      timezone = 'Australia/Melbourne';
    }

    return timezone;
  }

  convertedCurrentTimeWithTimezone() {
    return momentz.tz(moment(), this.fetchOnlyAustraliaTimezone());
  }

  getLocation(loc: any) {
    const location = [];
    if (loc?.apartment) {
      location.push(loc?.apartment);
    }
    if (loc?.address) {
      location.push(loc?.address);
    }
    const city_name = loc?.city;
    const address = loc?.address;
    const post_code = loc?.postal_code;
    if ((city_name && !address) || (city_name && address && !address.toLowerCase().includes(city_name.toLowerCase()))) {
      location.push(city_name);
    }
    if (loc?.postal_code && !address.toLowerCase().includes(post_code.toLowerCase())) {
      location.push(loc?.postal_code);
    }
    return location.join(', ');
  }
}
