import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { User } from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthAppService {
  private loggedUserSubject = new BehaviorSubject<User>(this.getSavedUser());
  public loggedUser = this.loggedUserSubject.asObservable().pipe(distinctUntilChanged());

  private wellBeingDetailSubject = new BehaviorSubject<any>(this.getWellbeingDetails());
  public wellBeingDetail = this.wellBeingDetailSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  private static readonly key_frontend = 'JWT_KEY';
  private static readonly key_backend = 'JWT_KEY_BACKEND';

  clientIdType: 'frontend' | 'backend' = 'frontend';

  get isLoggedIn(): boolean {
    try {
      return !!localStorage.getItem(AuthAppService.key_frontend);
    } catch (error) {
      return false;
    }
  }

  clientType() {
    try {
      return localStorage.getItem('ClientType');
    } catch (error) {
      return 'frontend';
    }
  }

  getSavedUser() {
    try {
      const cuser = localStorage.getItem('loggedUser');
      if (cuser) {
        const loggedUser = JSON.parse(cuser);
        return loggedUser;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  setAuth(token: string) {
    localStorage.setItem(AuthAppService.key_frontend, token);
    localStorage.setItem('ClientType', 'frontend');
    this.isAuthenticatedSubject.next(true);
  }

  setBackendAuth(token: string) {
    localStorage.setItem(AuthAppService.key_backend, token);
    localStorage.setItem('ClientType', 'backend');
  }

  getToken() {
    try {
      return localStorage.getItem(AuthAppService.key_frontend);
    } catch (error) {
      return '';
    }
  }

  setloggedUser(user: User) {
    localStorage.setItem('loggedUser', JSON.stringify(user));
    localStorage.setItem('lastLogin', new Date().toString());
    this.loggedUserSubject.next(user);
  }

  setWellbeingDetails(wellbeing: any) {
    localStorage.setItem('wellbeing', JSON.stringify(wellbeing));
    this.wellBeingDetailSubject.next(wellbeing);
  }

  getWellbeingDetails() {
    try {
      return JSON.parse(localStorage.getItem('wellbeing') || '{}');
    } catch (error) {
      return '{}';
    }
  }

  getloggedUser(): User {
    return this.loggedUserSubject.value;
  }

  clearUserData() {
    localStorage.removeItem('loggedUser');
    localStorage.removeItem('lastLogin');
    localStorage.removeItem('wellbeing');
    localStorage.removeItem(AuthAppService.key_frontend);
  }

  purgeAuth() {
    // Remove token  from localstorage
    this.clearUserData();
    // Set current user to an empty object
    this.loggedUserSubject.next(new User());
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    return;
  }
}
