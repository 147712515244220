import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProfileNewService } from 'avn/modules/profile-new/profile-new.service';
import { SharedCompsService } from 'avn/shared/shared-comps.service';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-save-list',
  templateUrl: './save-list.component.html',
  styleUrls: ['./save-list.component.scss']
})
export class SaveListComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<SaveListComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private api: ProfileNewService,
    private shared: SharedCompsService
  ) {}

  saveListForm = this.fb.group({
    list_name: ['', [Validators.required, this.noWhitespaceValidator]],
    description: ['']
  });

  noWhitespaceValidator(control: FormControl) {
    if (control.value && control.value.trim().length === 0) {
      return { whitespace: true };
    }
    return null;
  }

  savedList: any[] = [];

  ngOnInit(): void {
    this.getLists();
    this.saveListForm
      .get('list_name')
      ?.valueChanges.pipe(debounceTime(2000))
      .subscribe((value: any) => {
        if (value) {
          this.saveListForm.get('list_name')?.setValue((value || '').trim(), { emitEvent: false });
        }
      });
  }

  closeModal(result: any) {
    this.dialogRef.close(result);
  }

  getLists() {
    this.api.getMyList().subscribe((response: any) => {
      console.info(response);
      this.savedList = response.data;
    });
  }

  getMyListDetail(uuid: any) {
    this.api.getMyListDetail(uuid).subscribe((response) => {
      console.info('List Detail ' + JSON.stringify(response));
    });
  }

  createList() {
    console.info(this.saveListForm);
    const params = { ...this.saveListForm.getRawValue() };
    this.api.createMyList(params).subscribe((response: any) => {
      this.addItemToList(response.data.uuid);
    });
    // const response = {
    //   message: 'List created successfully',
    //   data: {
    //     id: 195,
    //     items: 0,
    //     uuid: '1f444331-ff38-43b0-9a1c-cced74b9d741',
    //     list_name: 'Kamal List',
    //     description: '',
    //     is_active: true,
    //     created_at: '2024-02-01T12:21:58.005921Z',
    //     updated_at: '2024-02-01T12:21:58.005950Z',
    //     user: 2150
    //   }
    // };
  }

  addItemToList(uuid: string) {
    const business_id = this.data.business_id;
    let service_id = '';
    let practitioner_id = '';
    if (this.data.item_type === 'Service') {
      service_id = this.data.item_id;
    } else if (this.data.item_type === 'Practitioner') {
      practitioner_id = this.data.item_id;
    }
    const params = {
      list_type: this.data.item_type,
      business: business_id,
      service: service_id || this.data.service_id,
      category: this.data.category,
      practitioner: practitioner_id
    };

    console.info(params);
    this.api.addToList(uuid, params).subscribe((response: any) => {
      console.info(response);
      this.shared.showSnackBar(response.message, 5);
      this.getLists();
      this.closeModal(true);
    });
  }
}
