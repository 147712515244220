<div class="footer-section">
  <div class="footer-wrapper">
    <div class="footer-column">
      <p>About</p>
      <ul>
        <li><a [routerLink]="['/about-avaana']" routerLinkActive="router-link-active" >Our story</a></li>
        <li><a [routerLink]="['/blog']">Blog</a></li>
        <li><a href="mailto:hello@avaana.com.au" target="_blank">Customer support</a></li>
        <li><a [routerLink]="['/sitemap']">Sitemap</a></li>
      </ul>
    </div>
    <div class="footer-column">
      <p>For Providers</p>
      <ul>
        <li><a href="https://listyourbusiness.avaana.com.au/" target="_blank">List your business</a></li>
        <li><a [routerLink]="['/provider-terms']">Provider Terms</a></li>
        <li><a href="mailto:providers@avaana.com.au" target="_blank">Support</a></li>
      </ul>
    </div>
    <div class="footer-column">
      <p>Legal</p>
      <ul>
        <li><a [routerLink]="['/disclaimer']">Disclaimer</a></li>
        <li><a [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
        <li><a [routerLink]="['/booking-terms']">Terms and Agreements</a></li>
        <li><a [routerLink]="['/community-guidelines']">Community Guidelines</a></li>
        <li><a [routerLink]="['/collection-statements']">Collection Statements</a></li>
      </ul>
    </div>
    <div class="footer-column">
      <p>Find us on our socials</p>
      <div class="social-icons">
        <a href="https://www.instagram.com/avaanawellbeing/" target="_blank"><img src="assets/images/instagram.svg" alt="instagram"></a>
        <a href="https://www.linkedin.com/company/avaana/" target="_blank"><img src="assets/images/linkedin.svg" alt="linkedin"></a>
        <!-- <a href="https://www.facebook.com/avaanawellbeing/" target="_blank"><img src="assets/images/facebook.svg" alt="facebook"></a> -->
      </div>
    </div>
  </div>
</div>
<div class="logo-trademark">
    <img src="assets/avaana-logo.svg" alt="avaana-logo">
    <span class="txt"> &copy; {{currentYear}} Avaana, Pty Ltd. All rights reserved</span>
</div>
