import { DOCUMENT, isPlatformBrowser, Location as PageLocation } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ActivatedRoute, Router } from '@angular/router';
import { SlugTextPipe } from 'avn/shared/pipes/slug-text.pipe';
import * as moment from 'moment';
import { Subject, debounceTime } from 'rxjs';

import {
  AvaanaCategory,
  BusinessSearchList,
  HealthConditionList,
  Location,
  LocationDropdownList,
  PopularServiceDatum,
  Prediction
} from '../../../interfaces/homepage';
import { ApiService } from '../../../services/api.service';
import { StoreService } from '../../../services/store.service';

import { SearchBoxPopComponent } from './search-box-pop/search-box-pop.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  dropdownType = '';
  todayDate = new Date();
  popularCategories: PopularServiceDatum[] = [];
  allServices: AvaanaCategory[] = [];
  serviceText = '';
  locationText = '';
  businessList: BusinessSearchList[] = [];
  healthConditions: HealthConditionList[] = [];
  searchType = '';
  locationTextChange = new Subject<string>();
  filteredLocationsList: LocationDropdownList[] = [];
  searchSlug = '';
  locationSlug = '';
  postCodeSlug = '';
  selectedDate = moment(this.todayDate);
  pageType = false;
  currentPage = '';

  documentSize: number;

  constructor(
    private api: ApiService,
    private store: StoreService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private slugText: SlugTextPipe,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: any,
    private pageLocation: PageLocation,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private dom: any
  ) {}

  currentView = 'list';

  @Input() showBack = true;
  @Input() viewType = 'normal';
  @Input() label = 'city, state';
  @Input() isHeader = false;
  @Input() minDate: string;
  @ViewChild('locationInput') locationInput: ElementRef;

  clickListener: any;

  ngOnInit(): void {
    this.onResize();
    if (isPlatformBrowser(this.platformId)) {
      this.clickListener = (event: any) => {
        this.dropdownClickHandler(event);
      };
      document.addEventListener('click', this.clickListener);
    }

    this.store.searchBoxParams.subscribe((params: any) => {
      const url_split = this.router.url.split('/');
      this.pageType = ['search', 'service', 'category', 'symptom'].includes(url_split[1]);
      this.currentPage = url_split[1];
      if (this.pageType) {
        if (url_split[1] === 'category') {
          this.serviceText = this.slugText.transform(params['category_name'], 'capitalize') as string;
        } else {
          this.serviceText = this.slugText.transform(params['service'], 'capitalize') as string;
        }
      }
      this.cd.detectChanges();
    });

    this.activatedRoute.queryParams.subscribe((params: any) => {
      if (params?.['dt']) {
        this.selectedDate = moment(params?.['dt'], 'YYYY-MM-DD');
      }
    });

    this.store.meraLocationSub.subscribe(() => {
      setTimeout(() => {
        this.setLocation();
      }, 10);
    });

    this.locationTextChange.pipe(debounceTime(500)).subscribe((value) => {
      this.getPlaces(value);
    });

    if (isPlatformBrowser(this.platformId)) {
      this.fetchPopularCategories();
      this.fetchAllServices();
      this.fetchAllBusinessList();
      this.fetchAllHealthConditions();
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.removeEventListener('click', this.clickListener);
    }
  }

  @HostListener('window:resize')
  onResize() {
    this.documentSize = this.dom.documentElement.clientWidth;
  }

  dropdownClickHandler(event: any) {
    const dropdowns = document.querySelectorAll('.search-input-box');
    if (dropdowns) {
      let opened = false;
      dropdowns.forEach((dropdown: Element) => {
        if (dropdown && !dropdown.contains(event.target)) {
          dropdown.classList.remove('show');
        }
        if (dropdown.classList.contains('show')) {
          opened = true;
        }
      });
      if (!opened) {
        this.dropdownType = '';
      }
    }
  }

  getPageType() {
    const url_split = this.router.url.split('/');
    return ['search', 'service', 'category', 'symptom'].includes(url_split[1]);
  }

  getServiceText() {
    return this.serviceText;
  }

  back() {
    this.pageLocation.back();
  }

  retryed = 0;

  setLocation() {
    const location = this.store.meraLocation;
    if (!location.city_name && this.retryed < 5) {
      setTimeout(() => {
        this.retryed += 1;
        console.info('Retryed location: ' + this.retryed);
        this.setLocation();
      }, 500);
    } else if (!location.city_name && this.retryed >= 5) {
      this.store.setDefaultLocation();
    }
    if (location.city_name) {
      const locInput = this.locationInput?.nativeElement;
      let locList: any = [];
      if (this.label) {
        this.label.split(', ').forEach((key: any) => {
          locList.push(this.store.meraLocation?.[key]);
        });
      } else {
        locList = [this.store.meraLocation.city_name, this.store.meraLocation.state_name].filter(
          (value) => value !== null && value !== undefined && value !== ''
        );
      }
      locList = [...new Set(locList)];
      if (locInput) {
        locInput.value = locList.join(', ');
      }
      this.locationText = locList.join(', ');
      this.locationSlug = (location.city_name || '').toLowerCase().replace(/ /g, '-');
      this.postCodeSlug = (location.post_code || '').toLowerCase();
    }
  }

  filteredPopularCategories() {
    if (this.serviceText) {
      return this.popularCategories.filter((c) => {
        return c.name.toLowerCase().includes(this.serviceText.toLowerCase());
      });
    }
    return this.popularCategories;
  }

  filteredAllServices() {
    if (this.serviceText) {
      return this.allServices.filter((c) => {
        return c.name.toLowerCase().includes(this.serviceText.toLowerCase());
      });
    }
    return this.allServices;
  }

  filteredBusinesses() {
    if (this.serviceText) {
      return this.businessList.filter((c) => {
        return c.name.toLowerCase().includes(this.serviceText.toLowerCase());
      });
    }
    return [];
  }

  filteredHealthConditions() {
    if (this.serviceText) {
      return this.healthConditions.filter((c) => {
        return c.name.toLowerCase().includes(this.serviceText.toLowerCase());
      });
    }
    return [];
  }

  // Fetching Data

  fetchPopularCategories() {
    this.api.getPopularList().subscribe({
      next: (response: any) => {
        this.popularCategories = response.data;
      }
    });
  }

  fetchAllServices() {
    this.api.getAllServices().subscribe({
      next: (response: any) => {
        this.allServices = response.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
      }
    });
  }

  fetchAllBusinessList() {
    this.api.getAllAvailableBusiness().subscribe({
      next: (response) => {
        this.businessList = response.data;
      }
    });
  }

  fetchAllHealthConditions() {
    this.api.getHealthConditions().subscribe({
      next: (response) => {
        this.healthConditions = response;
      }
    });
  }

  searchInput() {
    if (this.serviceText === '') {
      this.searchType = '';
      this.searchSlug = '';
    }
  }

  dateChanged(e: any) {
    this.selectedDate = moment(e);
  }

  openDropdown(dropdown_type: string) {
    this.dropdownType = dropdown_type;
    if (dropdown_type && this.documentSize < 1100) {
      setTimeout(() => {
        if (dropdown_type === 'search') {
          document.getElementById('searchInputMob')?.focus();
        } else if (dropdown_type === 'location') {
          document.getElementById('locationMainInput')?.focus();
        }
      }, 200);
    }
  }

  selectOption(name: string, search_type: string) {
    this.serviceText = name;
    this.dropdownType = '';
    this.searchType = this.searchType !== 'online' ? search_type : 'online';
    this.searchSlug = name.toLowerCase().replace(/ /g, '-');
  }

  selectBizOption(biz: any, search_type: string) {
    this.serviceText = biz.name;
    this.dropdownType = '';
    this.searchType = this.searchType !== 'online' ? search_type : 'online';
    this.searchSlug = biz.slug;
    this.locationSlug = biz.city.toLowerCase().replace(/ /g, '-');
  }

  selectLocation(location: LocationDropdownList, desktop = false) {
    this.locationText = location.displayText;
    const locationInput = document.getElementById('location-input') as HTMLInputElement;
    if (locationInput) {
      locationInput.value = location.displayText;
    }
    this.dropdownType = '';
    this.getReverseGeoCodeByAddress(location.prediction, desktop);
  }

  getmeraLocation() {
    this.store.fetchmeraLocation.next(true);
    this.dropdownType = '';
  }

  changeToOnline() {
    this.locationText = 'Online';
    this.searchType = 'online';
    const locationInput = document.getElementById('location-input') as HTMLInputElement;
    if (locationInput) {
      locationInput.value = 'Online';
    }
    this.dropdownType = '';
  }

  onLocationTextChanged(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    const locInput = inputElement.value;
    if (locInput === '' || locInput === null) {
      this.clearLocation();
      return;
    }
    this.locationTextChange.next(locInput);
  }

  clearLocation() {
    this.filteredLocationsList = [];
  }

  private getPlaces(value: string) {
    this.filteredLocationsList = [];
    const query_string = value;
    this.getPlacePredictions(query_string).then((predictions) => {
      const predictions_list = <Prediction[]>predictions;
      for (let i = 0; i < predictions_list.length; i++) {
        const description = predictions_list[i].description;
        const idx = description.indexOf(', Australia');
        let locationDisplayText = description;
        if (idx !== -1) {
          locationDisplayText = description.substring(0, idx);
        }
        const item = {
          text: description,
          displayText: locationDisplayText,
          place_id: predictions_list[i].place_id,
          prediction: predictions_list[i]
        };
        this.filteredLocationsList.push(item);
      }
    });
  }

  private getPlacePredictions(query: string): Promise<any> {
    this.filteredLocationsList = [];
    const autocompleteSrv = new google.maps.places.AutocompleteService();
    return new Promise((resolve, reject) => {
      autocompleteSrv.getPlacePredictions(
        {
          types: ['geocode'],
          input: query,
          componentRestrictions: { country: 'AU' }
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        }
      );
    });
  }

  toSetLocation = new Location();
  fetchingLocation = false;
  getReverseGeoCodeByAddress(prediction: Prediction, desktop = false) {
    const address = prediction.description;
    this.fetchingLocation = true;
    const responseObs = this.api.getGeoCodingByAddress(address);
    responseObs.subscribe({
      next: (response) => {
        let state = '';
        let city = '';
        try {
          let postcode = '';

          if (response.geocode_details.results[0].address_components) {
            for (let i = 0; i < response.geocode_details.results[0].address_components.length; i++) {
              const component = response.geocode_details.results[0].address_components[i];
              if (component.types[0] === 'postal_code') {
                postcode = component.long_name;
              } else if (component.types[0] === 'administrative_area_level_1') {
                state = component.long_name;
              } else if (
                component.types[0] === 'locality' ||
                (component.types.length > 2 && component.types[1] === 'locality') ||
                (component.types.length > 3 && component.types[2] === 'locality')
              ) {
                city = component.long_name;
              }
            }
          }
          if (!city) {
            city = state;
          }
          if (postcode === '') {
            prediction.postal_code = this.setMajorCityAndSuburbInfo(city);
          } else {
            prediction.postal_code = postcode;
          }
          const lng = response.geocode_details.results[0].geometry.location.lng;
          const lat = response.geocode_details.results[0].geometry.location.lat;
          prediction.longitude = lng;
          prediction.latitude = lat;
        } catch (e) {
          console.info(e);
        }

        this.toSetLocation.lat = prediction.latitude;
        this.toSetLocation.lng = prediction.longitude;
        this.toSetLocation.post_code = prediction.postal_code;
        this.toSetLocation.city_name = city;
        this.toSetLocation.state_name = state;

        this.locationSlug = city.toLowerCase().replace(/ /g, '-');
        this.postCodeSlug = prediction.postal_code;
        this.fetchingLocation = false;

        if (this.toSetLocation?.city_name && this.toSetLocation.state_name && desktop) {
          this.store.setmeraLocation(this.toSetLocation);
        }
      }
    });
  }

  setMajorCityAndSuburbInfo(suburb: string) {
    const mc = suburb.toLowerCase();
    if (mc === 'brisbane' || mc === 'queensland') {
      return '4000';
    } else if (mc === 'perth' || mc === 'western australia') {
      return '6000';
    } else if (mc === 'melbourne' || mc === 'victoria') {
      return '3000';
    } else if (mc === 'adelaide' || mc === 'south australia') {
      return '5000';
    } else if (mc === 'sydney' || mc === 'new south wales') {
      return '2000';
    }
    return '';
  }

  searchNow() {
    let url = '';
    const queryParams: any = {};
    if (this.searchType === 'online') {
      url = `/online/${this.searchSlug}`;
    } else {
      if (this.searchType === 'business') {
        url = `/provider/${this.searchSlug}/${this.locationSlug}`;
      } else if (this.searchType === 'service') {
        url = `/search/${this.searchSlug}/${this.locationSlug}`;
      } else if (this.searchType === 'health_condition') {
        url = `/search/health-condition/${this.searchSlug}/${this.locationSlug}`;
      } else {
        url = `/search/health-and-wellbeing-services/${this.locationSlug}`;
      }
      if (this.searchType !== 'business' && this.searchType !== 'online' && this.postCodeSlug) {
        url += `/${this.postCodeSlug}`;
      }
      if (this.searchType !== 'business' && this.selectedDate) {
        queryParams.dt = this.selectedDate.format('YYYY-MM-DD');
      }
    }
    if (this.toSetLocation?.city_name && this.toSetLocation.state_name) {
      this.store.setmeraLocation(this.toSetLocation);
    }
    this.router.navigate([url], {
      queryParams: queryParams
    });
  }

  locationFocus() {
    this.locationInput.nativeElement.value = '';
    this.locationText = '';
  }

  locationFocusOut(e: Event) {
    if ((e.target as HTMLInputElement).value === '') {
      let locList: any = [];
      if (this.label) {
        this.label.split(', ').forEach((key: any) => {
          locList.push(this.store.meraLocation?.[key]);
        });
      } else {
        locList = [this.store.meraLocation.city_name, this.store.meraLocation.state_name].filter(
          (value) => value !== null && value !== undefined && value !== ''
        );
      }
      locList = [...new Set(locList)];
      this.locationText = locList.join(', ');
      this.locationInput.nativeElement.value = locList.join(', ');
    }
  }
  openMobilePop() {
    const dialogData: MatDialogConfig = {
      disableClose: false,
      hasBackdrop: true,
      width: '100%',
      maxWidth: '400px',
      height: '100%',
      maxHeight: '400px',
      panelClass: ['searchboxPanel', 'popup-container'],
      data: {
        service: this.serviceText,
        location: this.locationText,
        date: this.selectedDate
      }
    };
    this.dialog.open(SearchBoxPopComponent, dialogData);
  }

  changeCurrentView() {
    this.currentView = this.currentView === 'list' ? 'map' : 'list';
    this.store.currentPageType.next(this.currentView);
  }
}
