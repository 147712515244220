import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-no-user-menu',
  templateUrl: './no-user-menu.component.html',
  styleUrls: ['./no-user-menu.component.scss']
})
export class NoUserMenuComponent {
  @Output() closeIt = new EventEmitter();

  closeModal() {
    this.closeIt.emit();
  }
}
