import { Platform } from '@angular/cdk/platform';
import { isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SlugTextPipe } from 'avn/shared/pipes/slug-text.pipe';
import * as moment from 'moment';
import { Subject, debounceTime } from 'rxjs';

import {
  AvaanaCategory,
  BusinessSearchList,
  HealthConditionList,
  Location,
  LocationDropdownList,
  PopularServiceDatum,
  Prediction
} from '../../../../interfaces/homepage';
import { ApiService } from '../../../../services/api.service';
import { StoreService } from '../../../../services/store.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
@Component({
  selector: 'app-search-box-pop',
  templateUrl: './search-box-pop.component.html',
  styleUrls: ['./search-box-pop.component.scss']
})
export class SearchBoxPopComponent implements OnInit, OnDestroy {
  dropdownType = '';
  todayDate = moment().format('YYYY-MM-DD');
  popularCategories: PopularServiceDatum[] = [];
  allServices: AvaanaCategory[] = [];
  serviceText = '';
  locationText = '';
  businessList: BusinessSearchList[] = [];
  healthConditions: HealthConditionList[] = [];
  searchType = 'service';
  locationTextChange = new Subject<string>();
  filteredLocationsList: LocationDropdownList[] = [];
  searchSlug = '';
  locationSlug = '';
  postCodeSlug = '';
  selectedDate = moment(this.todayDate);

  dateForm = this.fb.group({
    date: ['']
  });

  browserTest: any;

  selectedLocation: any;

  clickListener: any;

  @ViewChild('locationInput') locationInput: ElementRef;

  constructor(
    private api: ApiService,
    private store: StoreService,
    private router: Router,
    private slugText: SlugTextPipe,
    private dialogRef: MatDialogRef<SearchBoxPopComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(PLATFORM_ID) private platformId: any,
    private platform: Platform,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.browserTest = this.platform;
    if (isPlatformBrowser(this.platformId)) {
      this.clickListener = (event: any) => {
        this.dropdownClickHandler(event);
      };
      document.addEventListener('click', this.clickListener);
    }
    if (this.data) {
      this.serviceText = this.slugText.transform(this.data['service'], 'capitalize') as string;
      this.searchSlug = this.data['service'];
      this.selectedDate = moment(this.data['date']);
      this.locationText = this.data['location'];
      this.locationSlug = this.store.meraLocation.city_name.toLowerCase().replace(/ /g, '-');
      this.postCodeSlug = this.store.meraLocation.post_code.toLowerCase();
    }
    this.locationTextChange.pipe(debounceTime(500)).subscribe((value) => {
      this.getPlaces(value);
    });

    this.fetchPopularCategories();
    this.fetchAllServices();
    this.fetchAllBusinessList();
    this.fetchAllHealthConditions();
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      document.removeEventListener('click', this.clickListener);
    }
  }

  dropdownClickHandler(event: any) {
    const dropdowns = document.querySelectorAll('.search-input-box');
    if (dropdowns) {
      let opened = false;
      dropdowns.forEach((dropdown: Element) => {
        if (dropdown && !dropdown.contains(event.target)) {
          dropdown.classList.remove('show');
        }
        if (dropdown.classList.contains('show')) {
          opened = true;
        }
      });
      if (!opened) {
        this.dropdownType = '';
      }
    }
  }

  filteredPopularCategories() {
    if (this.serviceText) {
      return this.popularCategories.filter((c) => {
        return c.name.toLowerCase().includes(this.serviceText.toLowerCase());
      });
    }
    return this.popularCategories;
  }

  filteredAllServices() {
    if (this.serviceText) {
      return this.allServices.filter((c) => {
        return c.name.toLowerCase().includes(this.serviceText.toLowerCase());
      });
    }
    return this.allServices;
  }

  filteredBusinesses() {
    if (this.serviceText) {
      return this.businessList.filter((c) => {
        return c.name.toLowerCase().includes(this.serviceText.toLowerCase());
      });
    }
    return [];
  }

  filteredHealthConditions() {
    if (this.serviceText) {
      return this.healthConditions.filter((c) => {
        return c.name.toLowerCase().includes(this.serviceText.toLowerCase());
      });
    }
    return [];
  }

  // Fetching Data

  fetchPopularCategories() {
    this.api.getPopularList().subscribe({
      next: (response: any) => {
        this.popularCategories = response.data;
      }
    });
  }

  fetchAllServices() {
    this.api.getAllServices().subscribe({
      next: (response: any) => {
        this.allServices = response.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
      }
    });
  }

  fetchAllBusinessList() {
    this.api.getAllAvailableBusiness().subscribe({
      next: (response) => {
        this.businessList = response.data;
      }
    });
  }

  fetchAllHealthConditions() {
    this.api.getHealthConditions().subscribe({
      next: (response) => {
        this.healthConditions = response;
      }
    });
  }

  searchInput() {
    if (this.serviceText === '') {
      this.searchType = '';
      this.searchSlug = '';
    }
  }

  dateChanged(e: any) {
    this.selectedDate = moment(e);
    this.cd.detectChanges();
  }

  tempSearchText = '';

  openDropdown(dropdown_type: string, element?: any) {
    this.dropdownType = dropdown_type;
    if (dropdown_type === 'search' && this.serviceText === '' && this.tempSearchText) {
      this.serviceText = this.tempSearchText;
      this.searchSlug = this.serviceText.toLowerCase().replace(/ /g, '-');
    } else if (dropdown_type === 'search') {
      this.tempSearchText = this.serviceText;
      this.serviceText = '';
    }
    if (element && dropdown_type !== 'calendar') {
      element.value = '';
      this.filteredLocationsList = [];
      setTimeout(() => {
        element.focus();
      }, 200);
    }
    if (dropdown_type === '' && this.serviceText === '' && this.tempSearchText) {
      this.serviceText = this.tempSearchText;
      this.searchSlug = this.serviceText.toLowerCase().replace(/ /g, '-');
      this.tempSearchText = '';
    }
    this.cd.detectChanges();
  }

  selectOption(name: string, search_type: string) {
    this.serviceText = name;
    this.dropdownType = '';
    this.searchType = search_type;
    this.searchSlug = name.toLowerCase().replace(/ /g, '-');
    this.tempSearchText = '';
  }

  selectBizOption(biz: any, search_type: string) {
    this.serviceText = biz.name;
    this.dropdownType = '';
    this.searchType = search_type;
    this.searchSlug = biz.slug;
    this.locationSlug = biz.city.toLowerCase().replace(/ /g, '-');
  }

  selectLocation(location: LocationDropdownList) {
    this.locationText = location.displayText;
    if (this.locationInput) {
      (this.locationInput.nativeElement as HTMLElement).innerText = location.displayText;
    }
    this.dropdownType = '';
    this.getReverseGeoCodeByAddress(location.prediction);
  }

  changeToOnline() {
    this.locationText = 'Online';
    this.searchType = 'online';
    this.dropdownType = '';
  }

  getmeraLocation() {
    this.store.fetchmeraLocation.next(true);
    this.dropdownType = '';
  }

  onLocationTextChanged(e: Event) {
    const inputElement = e.target as HTMLInputElement;
    const locInput = inputElement.value;
    if (locInput === '' || locInput === null) {
      this.clearLocation();
      return;
    }
    this.locationTextChange.next(locInput);
  }

  clearLocation() {
    this.filteredLocationsList = [];
  }

  private getPlaces(value: string) {
    this.filteredLocationsList = [];
    const query_string = value;
    this.getPlacePredictions(query_string).then((predictions) => {
      const predictions_list = <Prediction[]>predictions;
      for (let i = 0; i < predictions_list.length; i++) {
        const description = predictions_list[i].description;
        const idx = description.indexOf(', Australia');
        let locationDisplayText = description;
        if (idx !== -1) {
          locationDisplayText = description.substring(0, idx);
        }
        const item = {
          text: description,
          displayText: locationDisplayText,
          place_id: predictions_list[i].place_id,
          prediction: predictions_list[i]
        };
        this.filteredLocationsList.push(item);
      }
    });
  }

  private getPlacePredictions(query: string): Promise<any> {
    this.filteredLocationsList = [];
    const autocompleteSrv = new google.maps.places.AutocompleteService();
    return new Promise((resolve, reject) => {
      autocompleteSrv.getPlacePredictions(
        {
          types: ['geocode'],
          input: query,
          componentRestrictions: { country: 'AU' }
        },
        (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(predictions);
          } else {
            reject(status);
          }
        }
      );
    });
  }

  loading = false;
  getReverseGeoCodeByAddress(prediction: Prediction) {
    const address = prediction.description;
    this.loading = true;
    const responseObs = this.api.getGeoCodingByAddress(address);
    responseObs.subscribe({
      next: (response) => {
        this.loading = false;
        let state = '';
        let city = '';
        try {
          let postcode = '';

          if (response.geocode_details.results[0].address_components) {
            for (let i = 0; i < response.geocode_details.results[0].address_components.length; i++) {
              const component = response.geocode_details.results[0].address_components[i];
              if (component.types[0] === 'postal_code') {
                postcode = component.long_name;
              } else if (component.types[0] === 'administrative_area_level_1') {
                state = component.long_name;
              } else if (
                component.types[0] === 'locality' ||
                (component.types.length > 2 && component.types[1] === 'locality') ||
                (component.types.length > 3 && component.types[2] === 'locality')
              )
                city = component.long_name;
            }
          }
          if (postcode === '') {
            prediction.postal_code = this.setMajorCityAndSuburbInfo(city);
          } else {
            prediction.postal_code = postcode;
          }
          const lng = response.geocode_details.results[0].geometry.location.lng;
          const lat = response.geocode_details.results[0].geometry.location.lat;
          prediction.longitude = lng;
          prediction.latitude = lat;
        } catch (e) {
          console.info(e);
        }
        this.selectedLocation = new Location();
        this.selectedLocation.lat = prediction.latitude;
        this.selectedLocation.lng = prediction.longitude;
        this.selectedLocation.post_code = prediction.postal_code;
        this.selectedLocation.city_name = city;
        this.selectedLocation.state_name = state;
        this.locationSlug = city.toLowerCase().replace(/ /g, '-');
        this.postCodeSlug = prediction.postal_code;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  setMajorCityAndSuburbInfo(suburb: string) {
    const mc = suburb.toLowerCase();
    if (mc === 'brisbane') {
      return '4000';
    } else if (mc === 'perth') {
      return '6000';
    } else if (mc === 'melbourne') {
      return '3000';
    } else if (mc === 'adelaide') {
      return '5000';
    } else if (mc === 'sydney') {
      return '2000';
    }
    return '';
  }

  searchNow() {
    if (this.searchSlug?.toLowerCase() === 'health and wellbeing services') {
      this.searchSlug = '';
    }
    this.searchSlug = this.searchSlug || 'health-and-wellbeing-services';
    let url = '';
    const queryParams: any = {};
    if (this.searchType === 'online') {
      url = `/online/${this.searchSlug}`;
    } else {
      if (this.searchType === 'business') {
        url = `/provider/${this.searchSlug}/${this.locationSlug}`;
      } else if (this.searchType === 'service') {
        url = `/search/${this.searchSlug}/${this.locationSlug}`;
      } else if (this.searchType === 'health_condition') {
        url = `/search/health-condition/${this.searchSlug}/${this.locationSlug}`;
      } else {
        url = `/search/health-and-wellbeing-services/${this.locationSlug}`;
      }
      if (this.searchType !== 'business' && this.searchType !== 'online' && this.postCodeSlug) {
        url += `/${this.postCodeSlug}`;
      }
      if (this.searchType !== 'business' && this.selectedDate) {
        queryParams.dt = this.selectedDate.format('YYYY-MM-DD');
      }
    }
    if (this.selectedLocation) {
      localStorage.setItem('meraLocation', JSON.stringify(this.selectedLocation));
      this.store.setmeraLocation(this.selectedLocation);
      this.selectedLocation = undefined;
    }
    this.router.navigate([url], {
      queryParams: queryParams
    });
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }

  locationFocus() {
    this.locationInput.nativeElement.value = '';
  }

  locationFocusOut(e: Event) {
    if ((e.target as HTMLInputElement).value === '') {
      this.locationInput.nativeElement.value =
        this.store.meraLocation.city_name + ', ' + this.store.meraLocation.state_name;
    }
  }
}
