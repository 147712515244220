import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { hide, shutdown } from '@intercom/messenger-js-sdk';
import { SsrReplaceService } from 'avn/guards/ssr-replace.service';
import { User } from 'avn/models/user';
import { AuthAppService } from 'avn/services/auth-app/auth-app.service';
import { Constants } from 'avn/services/constants';
import { StoreService } from 'avn/services/store.service';
import { UserService } from 'avn/services/user.service';

import { AuthSigninCheckComponent } from '../auth-signin-check/auth-signin-check.component';
import { MegaMenuService } from '../mega-menu/mega-menu.service';

@Component({
  selector: 'app-mega-menu-mobile',
  templateUrl: './mega-menu-mobile.component.html',
  styleUrls: ['./mega-menu-mobile.component.scss']
})
export class MegaMenuMobileComponent extends AuthSigninCheckComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<MegaMenuMobileComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private api: MegaMenuService,
    private user_api: UserService,
    private store: StoreService,
    private router: Router,
    private auth_: AuthAppService,
    private socialAuth: SocialAuthService,
    private auth0: AuthService,
    private ssr_: SsrReplaceService
  ) {
    super(auth0, ssr_, auth_);
  }

  loginType = Constants.LOGIN_TYPE;

  selectedMain: 'wellbeing' | 'others' | '' = '';
  selectedGroup: 'Physical' | 'Mental' | 'Natural' | 'Fitness' | 'Online Services' | 'Health Conditions' | '' = '';
  selectedService: any;

  hasBusiness = false;
  isWellbeingOwner = false;

  headerCategories: any = {};
  healthConditionList: any[] = [];
  loggedUser: any;

  ngOnInit(): void {
    this.auth_.loggedUser.subscribe((user) => {
      this.loggedUser = user;
      this.hasBusiness = user?.businesses?.length > 0 || false;
      this.isWellbeingOwner = user?.is_wellbeing_owner || false;
    });
    this.fetchHeaderUrls();
    this.fetchHealthConditionList();
  }

  fetchBusinessRouting() {
    const businesses = this.loggedUser.businesses;
    const business_id = businesses[0].uuid;

    if (
      this.loggedUser.header_detail.workcover.workcover_enabled &&
      this.loggedUser.header_detail.workcover_incomplete_business
    ) {
      return [`/work-cover/sign-up/${business_id}`];
    }
    return [`/business/${business_id}`];
  }

  fetchHeaderUrls() {
    if (Object.keys(this.store.headerCategories).length > 0) {
      this.headerCategories = this.store.headerCategories;
      this.headerCategories['Online Services'] = this.headerCategories['Online'];
      console.info(this.headerCategories);
    } else {
      this.api.getHeaderUrls().subscribe((response) => {
        this.headerCategories = response;
        this.store.headerCategories = response;
        this.headerCategories['Online Services'] = this.headerCategories['Online'];
      });
    }
  }

  fetchHealthConditionList() {
    if (this.store.healthConditionList.length > 0) {
      this.healthConditionList = this.store.healthConditionList;
    } else {
      this.api.getPopularHealthConditionList().subscribe((response) => {
        this.healthConditionList = response.filter((x: any) => x.is_online === true);
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  goBackFromGroups() {
    this.selectedGroup = '';
    if (this.selectedMain === 'others') {
      this.selectedMain = '';
    }
  }

  goToServicePage(service: any, suburb: any) {
    const url = `/search/${service.slug}/${suburb.slug}`;
    this.router.navigate([url]);
    this.closeModal();
  }

  goToCategoryPage(service: any, suburb: any) {
    let url = `/category/${service.slug}`;
    if (suburb) {
      url += `/${suburb.slug}`;
    }
    this.router.navigate([url]);
    this.closeModal();
  }

  goToHealthConditionPage(service: any, suburb: any) {
    const url = `/${service.name.toLowerCase().replace(/ /g, '-')}/${suburb.city.toLowerCase().replace(/ /g, '-')}/${
      suburb.post_code
    }`;
    this.router.navigate([url]);
    this.closeModal();
  }

  selectService(item: any) {
    if (this.selectedGroup !== 'Online Services') {
      this.selectedService = item;
      return;
    }
    this.router.navigate([`/online/${item.slug}`]);
    this.closeModal();
  }

  logoutUser() {
    if (this.loginType === 'normal') {
      this.user_api.logout().subscribe({
        next: () => {
          this.closeModal();
          this.auth_.purgeAuth();
          this.auth_.setloggedUser(new User());
          this.socialAuth.signOut();
          this.router.navigate(['/']);
          hide();
          shutdown();
        }
      });
    } else {
      const client = this.auth_.clientType();
      this.auth_.purgeAuth();
      this.auth_.setloggedUser(new User());
      localStorage.clear();
      hide();
      shutdown();
      if (client === 'frontend') {
        this.signOut();
        this.closeModal();
      }
    }
  }
}
