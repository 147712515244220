import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { AbnNumberMaskDirective } from 'avn/modules/business-dashboard/abn-number-mask.directive';
import { SearchBoxPopComponent } from 'avn/shared/components/search-box/search-box-pop/search-box-pop.component';
import { SearchBoxComponent } from 'avn/shared/components/search-box/search-box.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { JsonLdService } from 'ngx-seo';

import { AuthSigninCheckComponent } from './components/auth-signin-check/auth-signin-check.component';
import { BookingDetailComponent } from './components/booking-detail/booking-detail.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { CancelAppointmentComponent } from './components/cancel-appointment/cancel-appointment.component';
import { ConfirmPopComponent } from './components/confirm-pop/confirm-pop.component';
import { CustomerRescheduleConfirmComponent } from './components/customer-reschedule-confirm/customer-reschedule-confirm.component';
import { DateRangePickerComponent } from './components/date-range-picker/date-range-picker.component';
import { FiltersComponent } from './components/filters/filters.component';
import { FooterComponent } from './components/footer/footer.component';
import { ForCategoryComponent } from './components/for-category/for-category.component';
import { FormsDatepickerComponent } from './components/forms-datepicker/forms-datepicker.component';
import { GalleryPopupComponent } from './components/gallery-popup/gallery-popup.component';
import { HeaderComponent } from './components/header/header.component';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { LocationDropdownComponent } from './components/location-dropdown/location-dropdown.component';
import { MegaMenuComponent } from './components/mega-menu/mega-menu.component';
import { MegaMenuMobileComponent } from './components/mega-menu-mobile/mega-menu-mobile.component';
import { NoUserMenuComponent } from './components/no-user-menu/no-user-menu.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { OtpComponent } from './components/otp/otp.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { QuestionnaireComponent } from './components/questionnaire/questionnaire.component';
import { ReschedulePopComponent } from './components/reschedule-pop/reschedule-pop.component';
import { SaveListComponent } from './components/save-list/save-list.component';
import { SchemaService } from './components/seo-extention/schema.service';
import { SeoExtentionComponent } from './components/seo-extention/seo-extention.component';
import { ServiceDropdownOptionComponent } from './components/service-dropdown-option/service-dropdown-option.component';
import { SignaturePadComponent } from './components/signature-pad/signature-pad.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SortComponent } from './components/sort/sort.component';
import { SubSequentBookingCommonComponent } from './components/sub-sequent-booking-common/sub-sequent-booking-common.component';
import { SubSequentPopComponent } from './components/sub-sequent-pop/sub-sequent-pop.component';
import { TimesBlockViewComponent } from './components/times-block-view/times-block-view.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { TelInputComponent } from './form-feilds/tel-input/tel-input.component';
import { CanonicalUrlPipe } from './pipes/canonical-url.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ImageMediaPipe } from './pipes/image-media.pipe';
import { IsEmptyObjectPipe } from './pipes/is-empty-object.pipe';
import { IsTodayTomorrowPipe } from './pipes/is-today-tomorrow.pipe';
import { MomentFormatPipe } from './pipes/moment-format.pipe';
import { SlugTextPipe } from './pipes/slug-text.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { ValidatorComponent } from './validator/validator.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    OtpComponent,
    MegaMenuComponent,
    MegaMenuMobileComponent,
    NotificationsComponent,
    ConfirmPopComponent,
    SeoExtentionComponent,
    SnackbarComponent,
    PaginationComponent,
    LocationDropdownComponent,
    SearchBoxComponent,
    SearchBoxPopComponent,
    FiltersComponent,
    FormsDatepickerComponent,
    BookingDetailComponent,
    SaveListComponent,
    ValidatorComponent,
    ForCategoryComponent,
    SortComponent,
    ReschedulePopComponent,
    CustomerRescheduleConfirmComponent,
    CancelAppointmentComponent,
    ImageCropperComponent,
    AuthSigninCheckComponent,
    TelInputComponent,
    DateRangePickerComponent,
    QuestionnaireComponent,
    GalleryPopupComponent,
    NoUserMenuComponent,
    SubSequentBookingCommonComponent,
    CalendarViewComponent,
    TimesBlockViewComponent,
    SubSequentPopComponent,
    ServiceDropdownOptionComponent,

    SlugTextPipe,
    MomentFormatPipe,
    HighlightPipe,
    IsEmptyObjectPipe,
    CanonicalUrlPipe,
    IsTodayTomorrowPipe,
    SignaturePadComponent,
    TimeAgoPipe,
    ImageMediaPipe,

    OnlyNumberDirective,
    AbnNumberMaskDirective
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    OtpComponent,
    MegaMenuComponent,
    NotificationsComponent,
    ConfirmPopComponent,
    PaginationComponent,
    SnackbarComponent,
    LocationDropdownComponent,
    SearchBoxComponent,
    SearchBoxPopComponent,
    FormsDatepickerComponent,
    BookingDetailComponent,
    ForCategoryComponent,
    ReschedulePopComponent,
    TelInputComponent,
    QuestionnaireComponent,
    GalleryPopupComponent,
    SubSequentBookingCommonComponent,
    CalendarViewComponent,
    TimesBlockViewComponent,
    SubSequentPopComponent,
    ServiceDropdownOptionComponent,

    MomentFormatPipe,
    SlugTextPipe,
    HighlightPipe,
    IsEmptyObjectPipe,
    CanonicalUrlPipe,
    IsTodayTomorrowPipe,
    TimeAgoPipe,
    ImageMediaPipe,

    OnlyNumberDirective,
    AbnNumberMaskDirective
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatSnackBarModule,
    RouterModule,
    MatMenuModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    ImageCropperModule,
    MatTabsModule,
    AngularSignaturePadModule
  ],
  entryComponents: [],
  providers: [JsonLdService, SchemaService, SlugTextPipe, AbnNumberMaskDirective]
})
export class SharedModule {}
