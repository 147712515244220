import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthAppService } from '../auth-app/auth-app.service';
import { AUTHAPIs } from '../authorized-apis';

@Injectable()
export class JWTAuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthAppService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isJWTNeeded(req.url) && this.auth.getToken()) {
      const headers = req.headers
        .set('Authorization', `JWT ${this.auth.getToken()}`)
        .set('Content-Type', 'application/json');
      req = req.clone({ headers });
    } else if (req.url.includes('/login') && typeof req.body === 'string') {
      const headers = req.headers.set('encrypt', 'true').set('decrypt', 'true').set('Content-Type', 'application/json');
      req = req.clone({ headers, responseType: 'text' as 'json' });
    } else if (
      req.url.includes('api-qa-logichealth') ||
      req.url.includes('api-qa-eml') ||
      req.url.includes('api-qa-trinity') ||
      req.url.includes('api-qa-hii')
    ) {
      const headers = req.headers.set('Content-Type', 'application/json');
      req = req.clone({ headers });
    } else {
      const headers = req.headers
        .set('Content-Type', 'application/json')
        .set('encrypt', 'false')
        .set('decrypt', 'false');
      req = req.clone({ headers });
    }

    return next.handle(req);
  }

  isJWTNeeded(url: string): boolean {
    const authApiList = AUTHAPIs.AUTHORIZATIONAPILIST;
    const list = authApiList.filter((x: string) => url.includes(x));
    return list.length > 0;
  }
}
