import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';

import { BusinessProviderServices } from '../interfaces/homepage';

import { AuthAppService } from './auth-app/auth-app.service';
import { Constants } from './constants';
// import { JWTAuthInterceptor } from './interceptors/jwt-auth-interceptor';
import { StoreService } from './store.service';

const BASE_URL = Constants.BASE_URL;

@Injectable()
export class BusinessMainService {
  changeRouteForcefully = new Subject<string>();
  public formEditted = new BehaviorSubject(false);
  constructor(
    private httpClient: HttpClient,
    private store: StoreService,
    private injector: Injector,
    private auth: AuthAppService
  ) {
    this.errorHandler = this.injector.get(ErrorHandler);
  }
  private errorHandler: ErrorHandler;

  getSelectedBusinessDetails(uuid: string): Observable<any> {
    const endPoint = `/get-business-details/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getAboutBusinessDetail(uuid: string): Observable<any> {
    const endPoint = `/get-about-business-details/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  updateBusinessDetails(data: any): Observable<any> {
    const endPoint = '/update-business-details/' + data.id + '/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  getDashboardInsights(uuid: string, data: any): Observable<any> {
    const endPoint = `/business-dashboard-insights/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data);
  }

  createBusinessPhoto(data: any, businessId: string): Observable<any> {
    const endPoint = '/add-business-image/' + businessId + '/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  createBusinessLogo(data: any, businessId: string): Observable<any> {
    const endPoint = '/create-update-business-logo/' + businessId + '/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  removeBusinessLogo(businessId: string): Observable<any> {
    const endPoint = '/remove-business-logo/' + businessId + '/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  removegalleryImage(id: string): Observable<any> {
    const endPoint = `/remove-business-image/${id}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getBusinessOpeningHours(businessId: string): Observable<any> {
    const endPoint = `/get-scheduling-appointment/${businessId}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getCalendarData(business_uuid: string): Observable<any> {
    const endPoint = `/get-business-calendar-practitioner/${business_uuid}/?v=${this.store.getRandomNumber(true)}/`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getBusinessCalendar(practitioner_uuid: string): Observable<any> {
    const endPoint = `/get-business-calendar/${practitioner_uuid}/?v=${this.store.getRandomNumber(true)}/`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  deleteBusinessCalendar(uuid: string): Observable<any> {
    const endPoint = `/delete-business-calendar/${uuid}/?v=${this.store.getRandomNumber(true)}/`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  addBusinessCalendar(data: any): Observable<any> {
    const endPoint = '/add-business-calendar/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  updateCalendarInfo(data: any): Observable<any> {
    const endPoint = '/update-calendar-info/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  syncBookingSystem(uuid: string): Observable<any> {
    const endPoint = '/sync-external-calendar-business/' + uuid + '/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  updateBusinessCalendar(practitioner_uuid: string, data: any): Observable<any> {
    const endPoint = '/update-business-calendar/' + practitioner_uuid + '/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  enableDisablePayAtVenue(data: any): Observable<any> {
    const endPoint = '/enable-disable-pay-at-venue/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  updateSchedulingInformation(data: any, uuid: string): Observable<any> {
    const endPoint = `/update-scheduling-appointment/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  addService(providerServices: BusinessProviderServices): Observable<any> {
    const endPoint = '/create-update-business-appointments/';
    return this.httpClient.post(BASE_URL + endPoint, providerServices).pipe(catchError(this.handleError.bind(this)));
  }

  updateService(uuid: string, params: any): Observable<any> {
    const endPoint = `/update-business-service/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }

  deleteService(serviceId: string): Observable<any> {
    const endPoint = '/remove-business-service/' + serviceId + '/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  deleteClass(serviceId: string): Observable<any> {
    const endPoint = '/remove-business-class/' + serviceId + '/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  serviceStatusChange(data: any): Observable<any> {
    const endPoint = `/update-business-service-status/${data['id']}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  updateBusinessSettings(uuid: string, data: any): Observable<any> {
    const endPoint = `/update-business-settings/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  updateRadiusSuburb(data: any): Observable<any> {
    const endPoint = '/update-service-suburb-radius/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  enableDisableOnlineMobileServices(data: any): Observable<any> {
    const endPoint = '/enable-disable-online-service/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  updateRadiusOnlineSuburb(data: any): Observable<any> {
    const endPoint = '/update-service-software-link/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  removeServiceImage(uuid: string): Observable<any> {
    const endPoint = '/remove-service-image/' + uuid + '/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  removeClassImage(uuid: any): Observable<any> {
    const endPoint = '/remove-class-image/' + uuid + '/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  createClass(data: any): Observable<any> {
    const endPoint = '/create-update-booking-class/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  updateClass(uuid: string, data: any): Observable<any> {
    const endPoint = `/update-business-booking-class/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  addPractitioner(data: any): Observable<any> {
    data.practitioner_gender = data.practitioner_gender === 'Rather not say' ? 'None' : data.practitioner_gender;
    const endPoint = '/create-update-business-practitioner/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  updatePractitioner(uuid: string, data: any): Observable<any> {
    const endPoint = `/update-business-practitioner/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  updatePractitionerHolidaysList(uuid: string, data: any): Observable<any> {
    const endPoint = `/update-practitioner-holidays-list/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  updatePractitionerOverrideSchedule(uuid: string, data: any): Observable<any> {
    const endPoint = `/update-practitioner-override-schedule/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  validateSIRA(uuid: string, data: any): Observable<any> {
    const endPoint = `/validate-sira/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  validateAHPRA(uuid: string, data: any): Observable<any> {
    const endPoint = '/validate-ahpra/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  pracStatusChange(data: any): Observable<any> {
    const endPoint = `/update-business-practitioner-status/${data['id']}/`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  deletePrac(serviceId: any): Observable<any> {
    const endPoint = '/remove-business-practitioner/' + serviceId + '/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  removePracImage(serviceId: any): Observable<any> {
    const endPoint = '/remove-practitioner-image/' + serviceId + '/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getListOfAllBookings(uuid: any, size: any, page: any, sort: any, search?: any): Observable<any> {
    let endPoint = `/business-bookings/${uuid}/?page_size=${size}&page=${page}&v=${this.store.getRandomNumber(true)}`;
    if (sort && sort.by) {
      endPoint += `&order_by=${sort.name === 'desc' ? '-' : ''}${sort.by}`;
    }
    if (search) {
      endPoint += `&qf=${search}`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getListOfBookings(bookingDate: any, uuid: any, size: any, page: any, sort: any, search?: any): Observable<any> {
    let endPoint = `/business-bookings/${uuid}/?date=${bookingDate}&page_size=${size}&page=${page}&v=${this.store.getRandomNumber(
      true
    )}`;
    if (sort && sort.by) {
      endPoint += `&order_by=${sort.name === 'desc' ? '-' : ''}${sort.by}`;
    }
    if (search) {
      endPoint += `&qf=${search}`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  changeApptStatus(data: any, id: any): Observable<any> {
    const endPoint = '/change-appointment-status/' + id + '/';
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  getSlotAvailability(
    service_id: any,
    prac_id: any,
    appointment_date: any,
    current_time: any,
    timezone: string
  ): Observable<any> {
    const endPoint = `/slot/availability/${service_id}/${prac_id}/?existing=True&appointment_date=${appointment_date}&current_time=${current_time}&timezone=${timezone}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getBookingSlots(service_id: any, prac_id: any, start_date: any, end_date: any, current_time: any): Observable<any> {
    const endPoint = `/booking-slots/${service_id}/${prac_id}/?existing=True&start_date=${start_date}&end_date=${end_date}&current_time=${current_time}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getAllBusinessAppointments(uuid: string, params?: any): Observable<any> {
    let endPoint = `/get-business-appointments/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    if (params) {
      endPoint += `&page_size=${params.pageCount}&page=${params.currentPage}`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getAllBusinessClasses(uuid: string, params?: any): Observable<any> {
    let endPoint = `/get-business-classes/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    if (params) {
      endPoint += `&page_size=${params.pageCount}&page=${params.currentPage}&current_time=${moment().format(
        'YYYY-MM-DD hh:mm A'
      )}`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getAllBusinessPractitioners(uuid: string, params?: any): Observable<any> {
    let endPoint = `/get-business-practitioners/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    if (params) {
      endPoint += `&page_size=${params.pageCount}&page=${params.currentPage}`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getBusinessPractitionerDetail(uuid: string): Observable<any> {
    const endPoint = `/get-business-practitioner-detail/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getServiceDetail(id: string): Observable<any> {
    const endPoint = `/get-business-appointment-detail/${id}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getClassDetail(id: string): Observable<any> {
    const endPoint = `/get-business-class-detail/${id}/?v=${this.store.getRandomNumber(
      true
    )}&current_time=${moment().format('YYYY-MM-DD hh:mm A')}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  updateServiceDetail(data: any, id: string): Observable<any> {
    const endPoint = `/update-service-detail/${id}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  validateBusinessABN(data: any, uuid: string): Observable<any> {
    const endPoint = `/validate-business-abn/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  abnLookupBusinessName(data: any): Observable<any> {
    const endPoint = `/abn-lookup-business-name/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post(BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  getQualifications(): Observable<any> {
    const endPoint = '/fetch-qualifications/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getProfessions(): Observable<any> {
    const endPoint = '/fetch-professions/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getLanguages(): Observable<any> {
    const endPoint = '/fetch-languages/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getHolidaysList(uuid: string): Observable<any> {
    let endPoint = '';
    if (uuid) {
      endPoint += `/get-practitioner-holidays-list/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    } else {
      endPoint += `/get-avaana-holidays-list/?v=${this.store.getRandomNumber(true)}`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getOverrideSchedule(uuid: string): Observable<any> {
    const endPoint = `/get-practitioner-override-schedule/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getInsurances(): Observable<any> {
    const endPoint = '/fetch-insurances/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getCategories(): Observable<any> {
    const endPoint = '/fetch-categories/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getSpecializations(): Observable<any> {
    const endPoint = '/fetch-specializations/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getBusinessBlockTimes(uuid: string): Observable<any> {
    const endPoint = `/business-block-times/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getPaymentsList(uuid: string, filters: any): Observable<any> {
    let endPoint = `/payments/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    if (filters.start_date && filters.end_date) {
      endPoint += `&start_date=${filters.start_date}&end_date=${filters.end_date}`;
    }
    if (filters.status) {
      endPoint += `&payment_status=${filters.status}`;
    }
    if (filters.service_type) {
      endPoint += `&service_type=${filters.service_type}`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  exportPayments(uuid: string, filters: any): Observable<any> {
    let endPoint = `/export-payments/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    if (filters.start_date && filters.end_date) {
      endPoint += `&start_date=${filters.start_date}&end_date=${filters.end_date}`;
    }
    if (filters.status) {
      endPoint += `&payment_status=${filters.status}`;
    }
    if (filters.service_type) {
      endPoint += `&service_type=${filters.service_type}`;
    }
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: any): Observable<never> {
    // Call the global error handler
    this.errorHandler.handleError(error);

    // Re-throw the error so it can be caught by the component's subscribe error handler
    return throwError(() => error);
  }
  syncSpecificBusiness(uuid: string): Observable<any> {
    const endPoint = '/sync-specific-business/' + uuid + '/';
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getPercentage(uuid: string): Observable<any> {
    const endPoint = `/business-profile-percentage/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getDashboardAppointments(uuid: string): Observable<any> {
    const endPoint = `/appointment-dashboard/${uuid}/`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getInvitedPracdetails(uuid: string): Observable<any> {
    const endPoint = `/fetch-invited-practitioner/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  getUninvitedsDetails(uuid: string): Observable<any> {
    const endPoint = `/get-uninvited-practitioner/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }
  sendInvitationToPrac(payload: any, uuid: string): Observable<any> {
    const endPoint = `/send-invitation-to-practitioners/${uuid}/`;
    return this.httpClient.post(BASE_URL + endPoint, payload).pipe(catchError(this.handleError.bind(this)));
  }
  resendInvitationToPrac(uuid: string): Observable<any> {
    const endPoint = `/resend-invitation-to-practitioners/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getTinyClaimantLink(data: any): Observable<any> {
    const endPoint = '/get-tiny-claimant-link/';
    const params = {
      postcode: data.post_code,
      suburb: data.suburb,
      businessunit: data.businessunit,
      pivotalProcessedDateTime: data.pivotalProcessedDateTime
    };
    const headers = {
      userID: data.userID,
      claimNo: data.claimNo,
      modality: data.modality
    };
    return this.httpClient.post(BASE_URL + endPoint, params, { headers }).pipe(catchError(this.handleError.bind(this)));
  }

  sendCaseNoteUploadPdf(uuid: string, post_data: any): Observable<any> {
    const endPoint = `/send-case-note-upload-pdf/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post(BASE_URL + endPoint, post_data).pipe(catchError(this.handleError.bind(this)));
  }
}
