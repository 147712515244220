import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import { Constants } from '../../../services/constants';

@Injectable({
  providedIn: 'root'
})
export class MegaMenuService {
  constructor(
    private httpClient: HttpClient,
    private injector: Injector
  ) {
    this.errorHandler = this.injector.get(ErrorHandler);
  }
  private errorHandler: ErrorHandler;

  getHeaderUrls(): Observable<any> {
    const endPoint = '/mega-menu-categories/';
    return this.httpClient.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getPopularHealthConditionList(): Observable<any> {
    const endPoint = '/symptoms/';
    return this.httpClient.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: any): Observable<never> {
    // Call the global error handler
    this.errorHandler.handleError(error);

    // Re-throw the error so it can be caught by the component's subscribe error handler
    return throwError(() => error);
  }
}
