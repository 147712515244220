import { SocialAuthService } from '@abacritt/angularx-social-login';
import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';
import { User } from 'avn/models/user';
import { SharedCompsService } from 'avn/shared/shared-comps.service';

import { AuthAppService } from './auth-app/auth-app.service';

@Injectable()
export class ErrorInterceptor implements ErrorHandler {
  constructor(
    private router: Router,
    private authService: AuthAppService,
    private socialAuth: SocialAuthService,
    private shared: SharedCompsService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  handleError(error: any) {
    if (isPlatformBrowser(this.platformId)) {
      console.info('CAME TO CUSTOM ERROR HANDLER', error, this.router.url);
    }
    if ((error?.url || error?.error?.url)?.includes('validate-token')) {
      return;
    }

    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection; // get the error object
    }

    const url = this.router.url.split('/')[1];

    if (!['blog'].includes(url) && (error?.status === 401 || error?.status === 403)) {
      // // invalid session token
      this.authService.purgeAuth();
      this.authService.setloggedUser(new User());
      this.socialAuth.signOut();
      if (this.router.url.split('?')[0] !== '/login') {
        this.router.navigate(['/login'], { queryParams: { redirect: this.router.url } });
      }
    }
    if (!['blog'].includes(url) && error?.error?.message) {
      this.shared.showSnackBar(error?.error?.message, 5);
    }

    Sentry.captureException(error?.originalError || error);
  }
}
