<div class="notifications-group">
  <div class="notifications-head">
    <h3 (click)="unreadAll()">Notifications</h3>
    <div class="btns-grp">
      <button class="read-all" (click)="readAllClick()">Mark all as read</button>
      <button class="arrow-btn" (click)="closeModal()"><img src="assets/images/close.svg" alt=""></button>
    </div>
  </div>
  <div class="notifications-body">
    <ul class="tabs">
      <li class="tab" [ngClass]="{'active': activeTab === 'new'}" (click)="activeTab = 'new'">New <span *ngIf="getTabCount('new') > 0">{{getTabCount('new')}}</span></li>
      <li class="tab" [ngClass]="{'active': activeTab === 'bookings'}" (click)="activeTab = 'bookings'">Bookings <span *ngIf="getTabCount('bookings') > 0">{{getTabCount('bookings')}}</span></li>
      <li class="tab" [ngClass]="{'active': activeTab === 'forms'}" (click)="activeTab = 'forms'">Actions <span *ngIf="getTabCount('forms') > 0">{{getTabCount('forms')}}</span></li>
    </ul>
    <ul class="notification-ul">
      <ng-container *ngFor="let item of getNotifications(activeTab)">
        <li [ngClass]="{'unread': !item.is_read}">
          <div class="li-container" (click)="gotoNotificationItem(item)">
            <h3 class="head"><span class="circle" [ngClass]="{'unread': !item.is_read}"></span>{{item.title}}</h3>
            <p class="tags">
              <span class="tag {{item.platform | slugText : 'lowercase' : 'yes'}}">{{item.platform}}</span>
              <span class="tag {{item.type | slugText : 'lowercase' : 'yes'}}">{{item.type}}</span>
            </p>
            <p class="message">{{item.message}}</p>
            <p class="time">{{item.created_at | momentFormat : 'MMM, DD YYYY - hh:mm A' : 'YYYY-MM-DDTHH:mm:ssZ'}}</p>
          </div>
        </li>
      </ng-container>
      <li class="empty" *ngIf="getNotifications(activeTab).length === 0">
        <!-- <img src="assets/images/no-notifications.svg" alt=""> -->
        <p class="empty-notifications">
          No notifications yet
        </p>
      </li>
    </ul>
  </div>
</div>
