import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { BehaviorSubject, Subject, distinctUntilChanged } from 'rxjs';

import { Location } from '../interfaces/homepage';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  meraLocation: any = new Location();

  meraLocationSub = new BehaviorSubject<Location>(this.getmeraLocation());
  meraLocationSubject = this.meraLocationSub.asObservable().pipe(distinctUntilChanged());

  fetchmeraLocation = new Subject<any>();

  searchBoxParams = new Subject<any>();

  headerCategories: any = {};
  healthConditionList: any[] = [];

  homePageSearchServices: any[] = [];

  currentPageType = new Subject<any>();

  businessDashboardUpdate = new Subject<string>();

  setmeraLocation(location: Location) {
    this.meraLocation = location;
    const regex = new RegExp(/^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/);
    if (this.meraLocation.post_code && !regex.test(this.meraLocation.post_code)) {
      this.setDefaultLocation();
      return;
    }
    this.meraLocationSub.next(location);
    try {
      localStorage.setItem('meraLocation', JSON.stringify(location));
    } catch (error) {
      console.info('localStorage error');
    }
  }

  setDefaultLocation() {
    const location = this.meraLocation;
    location.city_name = 'Melbourne';
    location.post_code = '3000';
    location.lng = '144.9630576';
    location.lat = '-37.8136276';
    location.state_name = 'Victoria';
    this.setmeraLocation(location);
  }

  getmeraLocation() {
    try {
      if (this.meraLocation && this.meraLocation.city_name) {
        this.meraLocation;
      } else if (localStorage.getItem('meraLocation')) {
        this.meraLocation = JSON.parse(localStorage.getItem('meraLocation') || '');
        this.setmeraLocation(this.meraLocation);
      } else {
        this.meraLocation = new Location();
      }
      return this.meraLocation;
    } catch (error) {
      console.info('local storage error: IGNORE');
      return this.meraLocation;
    }
  }

  getRandomNumber(time = false) {
    if (time) return moment().format('YYYYMMDDhhmmss');
    return moment().format('YYYYMMDD');
  }

  generateHmac(data: string, secretKey: string): string {
    return CryptoJS.HmacSHA256(data, secretKey).toString(CryptoJS.enc.Hex);
  }
}
