export interface PopularService {
  status: number;
  Message: string;
  Popular_Service_Data: PopularServiceDatum[];
}

export interface PopularServiceDatum {
  id: number;
  name: string;
  category_link: string;
  category_business_count: number;
  category_images: CategoryImage[];
  category_group: string;
  tag_health_condition: string;
}

export interface BusinessSearchList {
  name: string;
  slug: string;
  distance: number;
  city: string;
  online_booking: boolean;
  business_class_active: boolean;
}

export interface HealthConditionList {
  id: number;
  name: string;
  is_online: boolean;
  suburb: HealthConditionSuburb[];
}

export interface HealthConditionSuburb {
  lat: number;
  lng: number;
  state: string;
  city: string;
  post_code: string;
}

export interface CategoryImage {
  id: number;
  correct_image: string;
  correct_thumb_image: string;
  image: string;
  thumb_image: null | string;
  name: string;
  type_of_url: string;
  category: number;
}

export class Prediction {
  reference = '';
  description = '';
  place_id = '';
  latitude = '';
  longitude = '';
  postal_code = '';
  structured_formatting = {
    main_text: ''
  };
}

export interface LocationDropdownList {
  text: string;
  displayText: string;
  place_id: string;
  prediction: Prediction;
}

// =================================================================================================

export interface AllCategories {
  status: number;
  Message: string;
  avaana_categories: AvaanaCategory[];
}

export interface AvaanaCategory {
  id: number;
  category_serviceArray: CategoryServiceArray[];
  category_images: CategoryImage[];
  category_business_count: number;
  name: string;
  seo_name: null | string;
  category_link: string;
  category_group: string;
  category_image: null | string;
  category_image_name: null | string;
  cities: string[];
  tag_health_condition: null | string;
  helping_tags: Array<null | string>;
  added_by: string;
  season_priority: number;
  category_business_type: string;
  physical: boolean;
  mental: boolean;
  natural: boolean;
  fitness: boolean;
  online: boolean;
}

export interface CategoryServiceArray {
  id: number;
  name: string;
  added_by: string;
}

// Location Interface

export class Location {
  lat: string | number | null;
  lng: string | number | null;
  post_code: string;
  city_name: string;
  state_name: string;
}

export interface BusinessMain {
  name: string;
  id: number;
  slug: string;
  online_booking: boolean;
  business_class_active: boolean;
  mobile_booking: boolean;
  business_location: BusinessLocation;
  business_detail: BusinessDetail;
  business_images: BusinessImage[];
  business_service: BusinessService[];
  business_practitioner: BusinessPractitioner[];
  business_service_typeArray: BusinessServiceTypeArray[];
  business_rating: BusinessRating;
  premium: boolean;
  badges: Badge2[];
}

export interface BusinessLocation {
  id: number;
  distance: string;
  uuid: string;
  address: string;
  street: string;
  city: string;
  state: string;
  postal_code: string;
  lat: number;
  lng: number;
  is_active: boolean;
  business: number;
}

export interface BusinessDetail {
  id: number;
  logo_image: string;
  correct_image: string;
  thumb_image: string;
  business_profile: string;
  logo_name: string;
  cancelation_policy: number;
  max_lead_time: number;
  max_feature_time: number;
  reviews_active: string;
  website: string;
  facebook_link: string;
  instagram_link: string;
  certifications: string;
  insurance_accepted: string;
  business_incorporation: string;
  security_policy: string;
  provide_service: string;
  philosophy: string;
  mobile_service: boolean;
  suburb: string;
  post_code: string;
  business_radius: number;
  online_service: boolean;
  software: string;
  link: string;
  google_rating: number;
  google_total_reviews: number;
  business: number;
}

export interface BusinessImage {
  id: number;
  correct_image: string;
}

export interface BusinessService {
  id: number;
  images: BusinessImage[];
  badges: Badge[];
  service_practitioner_detail: ServicePractitionerDetail[];
  uuid: string;
  service_name: string;
  improvised_name: string;
  slug: string;
  online_booking: boolean;
  category_name: string;
  length_of_service: string;
  length_of_booking_slot?: string;
  service_practitioner: string[];
  price: number;
  cliniko_appointment_type: string;
  discount: number;
  description: string;
  discount_description: string;
  discount_offer: string;
  image1?: string;
  image2: string;
  service_type: string;
  mode_of_service: string[];
  customer_intake: number;
  business_type: string;
  priority: number;
  sync_status: boolean;
  last_sync: string;
  enable_exclude_search_service: boolean;
  app_service: boolean;
  external_calendar_status: boolean;
  hide_in_market_place: boolean;
  hide_in_avaana_live: boolean;
  created_at: string;
  updated_at: string;
  business: number;
}

export interface Badge {
  text: string;
  color: string;
}

export interface ServicePractitionerDetail {
  id: number;
  name: string;
  slug: string;
  email: string;
}

export interface BusinessPractitioner {
  id: number;
  logo_image: string;
  image_1: string;
  image_2: string;
  practiitioner_appointments: [];
  practitioner_new_appointments: [];
  practitioner_block_times: [];
  temporary_appointments: [];
  email: string;
  uuid: string;
  logo_name: string;
  name: string;
  slug: string;
  practitioner_gender: string;
  practitioner_certificates: string[];
  profession_title: string;
  spoken_languages: string[];
  profile: string;
  external_calendar_status: boolean;
  practitioner_status: string;
  practitioner_hours: [];
  ahpra: string;
  external_calendar_url: string;
  acuity_external_calendar_id: string;
  timezone: string;
  timezone_id: string;
  logic_health_partner: boolean;
  specialization: string[];
  qualification: string[];
  badges: string;
  hide: boolean;
  sync_status: boolean;
  last_sync: string;
  business: number;
  link_calendar: number;
}

export interface BusinessServiceTypeArray {
  id: number;
  category_serviceArray: CategoryServiceArray[];
  category_images: CategoryImage[];
  name: string;
  seo_name: string;
  category_link: string;
  category_group: string;
  category_image: string;
  category_image_name: string;
  cities: string[];
  tag_health_condition: string;
  helping_tags: string | undefined[];
  added_by: string;
  season_priority: number;
  category_business_type: string;
  physical: boolean;
  mental: boolean;
  natural: boolean;
  fitness: boolean;
  online: boolean;
}

export interface BusinessRating {
  rate: number;
  count: number;
  score: number;
}

export interface Badge2 {
  text: string;
  color: string;
}

export interface BlogPost {
  id: number;
  post_date: string;
  post_title: string;
  image: string;
  description: string;
  url: string;
  categories: number[];
  author: number;
}

export interface IconsList {
  path: string;
  name: string;
}

/* *******************
business provider
********************** */

export class BusinessProviderLocation {
  id: number;
  address: string;
  city: string;
  state: string;
  postal_code: string;
  lat: string;
  lng: string;
  business: string;
  street: string;
}

export class BusinessProviderInfo {
  id: number;
  business_telephone: string;
  contact_name: string;
  email: string;
  facebook_link: string;
  location_data: BusinessProviderLocation;
  name: string;
  tel_no: string;
  website: string;
}

export class BusinessProviderSchedule {
  id: number;
  cancelation_policy: number;
  max_lead_time: number;
  Business_timing: BusinessProviderTiming[];
  booking_mode: string;
  business: string;
}

export class BusinessProviderServices {
  id: number;
  service_name: string;
  category_name: string;
  business_practitioner: string[];
  length_of_service: string;
  price: number;
  discount: number;
  discount_offer: string;
  discount_description: string;
  description: string;
  service_type: string;
  length_of_booking_slot: number;
  image1: string;
  image2: string;
  imagePath: string;
  business_type: string;
  customer_intake: number;
  capacity_total: number;
  service_image: any[];
  images: any[];
  online_booking: boolean;
  mobile_booking: boolean;
  spotsLeft: any;
  additional_info = '';
  cancellation_policy = '';

  business: string;
  service_practitioner: string[];
  mode_of_service?: any[];
  image_name: string;
}

export class BusinessProviderClasses {
  id: number;
  service_name: string;
  category_name: string;
  business_practitioner: string[];
  length_of_service: string;
  price: number;
  class_type: number;
  discount: number;
  discount_offer: string;
  discount_description: string;
  description: string;
  service_type: string;
  length_of_booking_slot: number;
  image1: string;
  image2: string;
  imagePath: string;
  business_type: string;
  customer_intake: number;
  service_image: string;
  online_booking: boolean;
  mobile_booking: boolean;
  spotsLeft: any;
  additional_info = '';
  cancellation_policy = '';
  start_date: Date;
  end_date: Date;
  capacity_total: number;
  capacity_used: number;
  class_location: string;
  class_start_time: any;
  class_end_time: any;
  repeat_mode: string;
  repeat_type: string;
  weekly_days: string[];
  comment: string;
  status: string;
  business_id: string;
  service_id: string;
  mode_of_service: string;
  business: string;
  image_name: string;
  images: any[];
  start_times: any;
}

export class BusinessProviderDetail {
  id: number;
  logo_image: string;
  correct_image: string;
  thumb_image: string;
  website: string;
  booking_mode = 'None';
  booking_mode_api: string;
  logo_name: string;
  facebook_link: string;
  instagram_link: string;
  cancelation_policy: number;
  max_lead_time: number;
  reviews_active: string;
  business: string;
  access_key?: string;
  secret_key?: string;
  business_phone: string;
  business_profile = '';
  business_profile_major_city = '';
  business_profile_subhurb = '';
  business_incorporation: any;
  insurance_accepted: any;
  security_policy: any;
  cliniko_business_id: any;
  nookal_business_id: any;
  coreplus_business_id: any;
  acuity_business_id: any;
  cliniko_shard: any;
  business_cliniko_api: any;
  business_nookal_api: any;
  business_coreplus_api: any;
  business_acuity_api: any;
  api_key: any;
  business_id: any;
  allow_sync: boolean;
  certifications: any;
  business_radius: any;
  mobile_service: boolean;
  online_service: boolean;
}

export class BusinessProviderPhoto {
  id: number;
  image: string;
  correct_image: string;
  thumb_image: string;
  name: string;
  type_of_url: string;
  business: string;
}

export class BusinessProviderTiming {
  id: number;
  business_day: string;
  day_status: 'Closed' | 'Open';
  business: string;
  start_timing: string;
  end_timing: string;
  is_multiple = false;
  second_slot?: boolean = false;
}

export class BusinessProviderPractitioner {
  id: number;
  logo_image: any;
  correct_image: string;
  logo_name: string;
  name: string;
  profile: string;
  practitioner_service: string[];
  //  practitioner_service_category: PractitionerServiceCategory[];
  email: string;
  link_calendar: any;
  created_at: Date;
  updated_at: Date;
  business: string;
  practitioner_hours: BusinessProviderTiming[];
  practitioner_status: string;
  practitioner_gender: any;
  nookal_external_calendar_id: any;
  mindbody_external_calendar_id: any;
  coreplus_external_calendar_id: any;
  external_calendar_id: any; //cliniko
  google_calendar_id: any;
  external_calendar_type: any;
  calendar_id: any;
  practitioner_certificates: any;
  spoken_languages: any;
  profession_title: any;
  specialization: any;
  qualification: any;
}

export class BusinessProviderStripe {
  routing_number: string;
  bank_name: string;
  first_name: string;
  last_name: string;
  account_holder_name: string;
  business_name: string;
  type: string;
  dob = { year: 0, day: 0, month: 0 };
  account_number: any;
  express_url: any;
  exist: any;
}

export class BusinessProviderData {
  id: number;
  business: number;
  live_status: string;
  abn: string;
  acconut_id: string;
  card_token: string;
  stripe_customer_id: string;
  online_pay_via_stripe: string;
  name: string;
  email: string;
  contact_name: string;
  tel_no: string;
  slug: string;
  is_active: boolean;
  about_practitioner_check: boolean;
  payment_gateway_check: boolean;
  sponsership_payemnt: string;
  sponsership_upto: string;
  pay_venue: string;
  disable_cliniko_hours: boolean;
  premium: boolean;
  online_booking: boolean;
  mobile_booking: boolean;
  avaana_commission: string;
  uuid: string;
  distance: string;
  amenities: string;
  insurance: any;
  business_cliniko_api: any;
  is_logic_health?: boolean;

  business_location: BusinessProviderLocation;
  business_detail: BusinessProviderDetail;
  business_photos: BusinessProviderPhoto[];
  business_images: BusinessProviderPhoto[];
  business_days: BusinessProviderTiming[];
  business_holidays = [];
  business_practitioner: BusinessProviderPractitioner[];
  business_stripe: BusinessProviderStripe;
  business_service: BusinessProviderServices[];
  business_class: any[];
  business_radius: any[];
  allWeeksList: any = [];
  business_stripe_customer: any;
}

export interface IPaginationOptions {
  showResultsCount: boolean;
  showPrevNextButtons: boolean;
  showResultText: string;
  totalRecordsPerPage: number;
  totalRecords: number;
  currentPage: number;
}
