import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Constants } from 'avn/services/constants';
import { CanonicalUrlPipe } from 'avn/shared/pipes/canonical-url.pipe';
import { SlugTextPipe } from 'avn/shared/pipes/slug-text.pipe';
import { JsonLdService } from 'ngx-seo';

import { subcategory_data } from './category-list';
import { SchemaService } from './schema.service';

@Component({
  selector: 'app-seo-extention',
  template: '',
  styles: ['']
})
export class SeoExtentionComponent {
  constructor(
    private routr: Router,
    private currentRoute: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    @Inject(DOCUMENT) private dom: any,
    private readonly jsonLdService: JsonLdService,
    private schemaService: SchemaService,
    private slugText: SlugTextPipe,
    private canonical: CanonicalUrlPipe
  ) {}

  service = '';
  suburb = '';
  state = '';
  postCode = '';
  searchBy = '';
  isMajorCity = false;

  conditions = Constants.HEALTH_CONDITIONS;
  isSubCategoryPage = false;
  subCatObj = Constants.SUBCATEGORY_LIST;
  mainCategory = '';

  checkIsSubCategoryPage(category: string) {
    this.subCatObj = Constants.SUBCATEGORY_LIST;
    for (const key in this.subCatObj) {
      if (this.subCatObj[key].findIndex((x: any) => x.key === category) > -1) {
        this.mainCategory = key;
        return true;
      }
    }
    return false;
  }

  initialCall() {
    this.routr.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const params: any = { ...this.currentRoute.firstChild?.firstChild?.snapshot.params };
        if (this.routr.url === '/' || this.routr.url === '') {
          this.homeMeta();
        } else if (this.routr.url === '/blog') {
          this.blogMeta();
        } else if (
          this.routr.url.includes('/booking/') ||
          this.routr.url.includes('/checkout/') ||
          this.routr.url.includes('/confirm/')
        ) {
          this.bookingMeta();
        } else if (this.routr.url.includes('/category/')) {
          this.isSubCategoryPage = this.checkIsSubCategoryPage(params.city);
          if (this.isSubCategoryPage) {
            params.parent_category = params.category_name;
            params.category_name = params.city;
            params.city = params.postcode;
            params.postcode = undefined;
          }
          this.service = params?.['category_name'] || '';
          this.suburb = params?.['city'] || '';
          this.postCode = params?.['postcode'] || '';
          this.setMajorCityAndSuburbInfo();
          this.setMetaForCategory();
          this.searchBy = 'Category';
        } else if (this.routr.url.includes('/search/')) {
          this.isSubCategoryPage = this.checkIsSubCategoryPage(params['location']);
          if (this.isSubCategoryPage) {
            params.parent_category = params.service;
            params.service = params.location;
            params.location = params.postCode;
            params.postCode = params.date;
          }
          this.service = params?.['service'] || '';
          this.suburb = params?.['location'] || '';
          this.postCode = params?.['postCode'] || '';
          this.searchBy = this.conditions.indexOf(this.service) > -1 ? 'Symptom' : 'Service';
          this.setMajorCityAndSuburbInfo();
          if (this.isMajorCity === true) {
            this.setMetaForMajorCity();
          } else {
            this.setMetaForSuburb();
          }
        } else if (this.routr.url.includes('/provider/') && !this.dom.URL.includes('href')) {
          this.updateCanonicalUrl(this.dom.URL);
          return;
        } else {
          this.homeMeta();
        }
        this.updateCanonicalUrl(this.dom.URL);
        // console.info('called this seo', this.routr, this.currentRoute, params);
        // this.setMajorCityAndSuburbInfo();
      }
    });
  }

  bookingMeta() {
    let title = 'Booking | Avaana';
    if (this.routr.url.includes('/checkout/')) {
      title = 'Checkout | Avaana';
    } else if (this.routr.url.includes('/confirm/')) {
      title = 'Booking confirm | Avaana';
    }
    const description =
      'Book on demand physio, chiro, massage therapist and other wellness services in Australia via Avaana. Get 20% off & best price guarantee. Click to explore all the services now!';
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      name: 'keywords',
      content: 'health center near you, telehealth services, wellness clinic, wellbeing clinics, Avaana'
    });
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:description', content: description });
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://avaana.com.au/static/image/Avaana-homepage-bg-img_3.jpg'
    });
    this.meta.updateTag({ name: 'robots', content: 'noindex, nofollow' });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'og:url', content: 'https://avaana.com.au' });
  }

  homeMeta() {
    const jsonld = this.schemaService.getHomeSchema();
    const searchJsonld = this.schemaService.getSearchSiteLinkSchema();
    const homeNavigationLink = this.schemaService.getHomeSiteNavigationLink();
    const multipleJsonLds = [];
    multipleJsonLds.push(jsonld);
    multipleJsonLds.push(searchJsonld);
    multipleJsonLds.push(homeNavigationLink);
    this.jsonLdService.setData(multipleJsonLds);
    const title = 'Avaana: Find and book health and wellbeing services in real-time near you';
    const description =
      'Book on demand physio, chiro, massage therapist and other wellness services in Australia via Avaana. Get 20% off & best price guarantee. Click to explore all the services now!';
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({
      name: 'keywords',
      content: 'health center near you, telehealth services, wellness clinic, wellbeing clinics, Avaana'
    });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:description', content: description });
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
    });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'og:url', content: 'https://avaana.com.au' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:description', content: description });
    this.meta.updateTag({ name: 'twitter:site', content: 'Avaana' });
    this.meta.updateTag({ name: 'twitter:url', content: 'https://avaana.com.au' });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
    });
  }

  blogMeta() {
    const blogNavigationLink = this.schemaService.getBlogSchema();
    const multipleJsonLds = [];
    multipleJsonLds.push(blogNavigationLink);
    this.jsonLdService.setData(multipleJsonLds);
    const title = 'Avaana Answers | Avaana';
    const metaStr = 'Best Tips for Your Health, Fitness and Wellbeing Journey by Experts';
    const metaKeywords = 'Avaana';
    this.title.setTitle(title);
    this.meta.updateTag({ name: 'description', content: metaStr });
    this.meta.updateTag({ name: 'keywords', content: metaKeywords });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    this.meta.updateTag({ name: 'og:title', content: title });
    this.meta.updateTag({ name: 'og:description', content: metaStr });
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
    });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'og:url', content: 'https://avaana.com.au/blog' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: 'Avaana' });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'twitter:url', content: 'https://avaana.com.au/blog' });
    this.meta.updateTag({ name: 'twitter:description', content: metaStr });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
    });
  }

  setMajorCityAndSuburbInfo() {
    if (
      this.suburb &&
      (this.suburb.toLowerCase() === 'brisbane' ||
        this.suburb.toLowerCase() === 'sydney' ||
        this.suburb.toLowerCase() === 'perth' ||
        this.suburb.toLowerCase() === 'melbourne' ||
        this.suburb.toLowerCase() === 'adelaide')
    ) {
      this.isMajorCity = true;
      const mc = this.suburb.toLowerCase();
      this.state = 'VIC';
      if (mc === 'brisbane') {
        this.state = 'Qld';
      } else if (mc === 'perth') {
        this.state = 'WA';
      } else if (mc === 'melbourne') {
        this.state = 'VIC';
      } else if (mc === 'adelaide') {
        this.state = 'SA';
      } else if (mc === 'sydney') {
        this.state = 'NSW';
      }
    }
  }

  setMetaForSuburb() {
    const service = this.slugText.transform(this.service, 'capitalize');
    const suburb = this.suburb ? this.slugText.transform(this.suburb, 'capitalize', '', '-') : '';
    const metaData: any = this.getSuburbMetaTag(service, suburb, this.searchBy);
    let titleStr = metaData.title;
    let metaStr = metaData.description;

    let metaKeywords =
      this.service.toLowerCase() +
      ' ' +
      suburb.toLowerCase() +
      ', ' +
      this.service.toLowerCase() +
      ' in ' +
      suburb.toLowerCase() +
      ', book ' +
      this.service.toLowerCase() +
      ' in ' +
      suburb.toLowerCase() +
      ', book online ' +
      this.service.toLowerCase() +
      ' in ' +
      suburb.toLowerCase() +
      ', ' +
      this.service.toLowerCase() +
      ' services in ' +
      suburb.toLowerCase() +
      ', ' +
      this.service.toLowerCase() +
      ' center in ' +
      suburb.toLowerCase() +
      ', ' +
      this.service.toLowerCase() +
      ' session in ' +
      suburb.toLowerCase() +
      ', online ' +
      this.service.toLowerCase() +
      ' in ' +
      suburb.toLowerCase() +
      ', Avaana';

    if (this.searchBy === 'Symptom') {
      titleStr = suburb + ' Practitioners who treat ' + service + ' (Book Now)';
      metaStr = service + ' Help - These practitioners in ' + suburb + 'can help.';

      metaKeywords =
        this.service.toLowerCase() +
        ' ' +
        suburb.toLowerCase() +
        ', ' +
        this.service.toLowerCase() +
        ' in ' +
        suburb.toLowerCase() +
        ', book ' +
        this.service.toLowerCase() +
        ' in ' +
        suburb.toLowerCase() +
        ', book online ' +
        this.service.toLowerCase() +
        ' in ' +
        suburb.toLowerCase() +
        ', ' +
        this.service.toLowerCase() +
        ' services in ' +
        suburb.toLowerCase() +
        ', ' +
        this.service.toLowerCase() +
        ' center in ' +
        suburb.toLowerCase() +
        ', ' +
        this.service.toLowerCase() +
        ' session in ' +
        suburb.toLowerCase() +
        ', online ' +
        this.service.toLowerCase() +
        ' in ' +
        suburb.toLowerCase() +
        ', Avaana';

      const datStr = this.getSymptomTitle(suburb);
      if (datStr.titleStr) {
        titleStr = datStr.titleStr;
        metaStr = datStr.metaStr;
      }
      this.meta.updateTag({
        name: 'og:image',
        content: 'https://avaana.com.au/static/image/Avaana-homepage-bg-img_3.jpg'
      });
      this.meta.updateTag({
        name: 'og:url',
        content: this.dom?.location?.href
      });
      this.meta.updateTag({
        name: 'twitter:url',
        content: this.dom?.location?.href
      });
    } else {
      this.meta.updateTag({
        name: 'og:image',
        content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
      });
      this.meta.updateTag({
        name: 'og:url',
        content: this.dom?.location?.href
      });
      this.meta.updateTag({
        name: 'twitter:url',
        content: this.dom?.location?.href
      });
    }

    this.title.setTitle(titleStr);
    this.meta.updateTag({ name: 'description', content: metaStr });
    this.meta.updateTag({ name: 'keyword', content: metaKeywords });
    if (this.searchBy === 'Symptom') {
      this.meta.updateTag({ name: 'robots', content: 'noindex, follow' });
    } else {
      this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    }
    this.meta.updateTag({ name: 'og:title', content: titleStr });
    this.meta.updateTag({ name: 'og:description', content: metaStr });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:title', content: titleStr });
    this.meta.updateTag({ name: 'twitter:description', content: metaStr });
    this.meta.updateTag({ name: 'twitter:site', content: 'Avaana' });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
    });
  }

  setMetaForMajorCity() {
    const service = this.slugText.transform(this.service, 'capitalize');
    const suburb = this.suburb ? this.slugText.transform(this.suburb, 'capitalize', '', '-') : '';
    const state = this.state.charAt(0).toUpperCase() + this.state.slice(1);
    let titleStr = service + ' ' + suburb + ' : Book ' + service + ' in ' + state + ' | Avaana';
    let metaStr =
      'Looking for the best ' +
      service.toLowerCase() +
      ' services in ' +
      state +
      '? See availability & book an appointment for ' +
      service.toLowerCase() +
      ' on demand. Get 20% off & best price guarantee via Avaana';

    let metaKeywords =
      this.service.toLowerCase() +
      ' ' +
      this.suburb.toLowerCase() +
      ', ' +
      this.service.toLowerCase() +
      ' in ' +
      this.suburb.toLowerCase() +
      ', book ' +
      this.service.toLowerCase() +
      ' in ' +
      this.suburb.toLowerCase() +
      ', book online ' +
      this.service.toLowerCase() +
      ' in ' +
      this.suburb.toLowerCase() +
      ', ' +
      this.service.toLowerCase() +
      ' services in ' +
      this.suburb.toLowerCase() +
      ', ' +
      this.service.toLowerCase() +
      ' center in ' +
      this.suburb.toLowerCase() +
      ', ' +
      this.service.toLowerCase() +
      ' session in ' +
      this.suburb.toLowerCase() +
      ', online ' +
      this.service.toLowerCase() +
      ' in ' +
      this.suburb.toLowerCase() +
      ', Avaana';

    if (service === 'Physiotherapy') {
      titleStr = 'Book in today with experienced physios in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Recovering from an injury?  Consult Leading Physios for rehab and pain management in ' +
        suburb +
        ', ' +
        state +
        'and get 20% off via Avaana.';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Expert Physios in Melbourne and throughout VIC | Avaana';
        metaStr =
          'Get the help of trusted Physios in Melbourne and Regional VIC for aching muscles and mobility issues | 20% off your first booking on Avaana.';
        metaKeywords = 'physiotherapy in melbourne, physio elbourne, best physio melbourne.';
      } else if (suburb.toLowerCase() === 'brisbane') {
        titleStr = 'Expert Physios in Brisbane and throughout QLD | Avaana';
        metaStr =
          'Get the help of trusted Physios in Brisbane and Regional Qld for aching muscles and mobility issues | 20% off your first booking on Avaana.';
        metaKeywords = 'physiotherapy in brisbane, physio brisbane, physiotherapist brisbane city.';
      } else if (suburb.toLowerCase() === 'perth') {
        titleStr = 'Expert Physios in Perth and throughout WA | Avaana';
        metaStr =
          'Get the help of trusted Physios in Perth and Regional WA for aching muscles and mobility issues | 20% off your first booking on Avaana.';
        metaKeywords = 'physiotherapist in perth, physio perth, best physio perth, Avaana';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Expert Physios in Sydney and throughout NSW | Avaana';
        metaStr =
          'Get the help of trusted Physios in Sydney and Regional NSW for aching muscles and mobility issues | 20% off your first booking on Avaana.';
        metaKeywords = 'physio in sydney, physiotherapist in sydney, best physio sydney.';
      } else if (suburb.toLowerCase() === 'adelaide') {
        titleStr = 'Expert Physios in Adelaide and throughout SA | Avaana';
        metaStr =
          'Get the help of trusted Physios in Adelaide and Regional SA for aching muscles and mobility issues | 20% off your first booking on Avaana.';
        metaKeywords = 'physio in adelaide, physiotherapist adelaide.';
      }
    } else if (service.toLowerCase() === 'personal training') {
      titleStr = 'Personal Trainers in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Weight loss, muscle gain or cardio. Get quicker results and 20% off your first session with a qualified personal trainer in ' +
        suburb +
        ', ' +
        state +
        ' on Avaana';
      metaKeywords = 'personal training ' + suburb.toLowerCase() + '';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Certified Personal Trainers in Melbourne Find and book a session on Avaana';
        metaStr =
          'Bulking, shredding or just looking to lead a healthy and active life? Compare Personal Trainers throughout Melbournefor online, gym or studio sessions.';
        metaKeywords = 'personal training melbourne, best personal trainer melbourne, Avaana';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Certified Personal Trainers in Sydney Find and book a session on Avaana';
        metaStr =
          'Bulking, shredding or just looking to lead a healthy and active life? Compare Personal Trainers throughout Sydney for online, gym or studio sessions.';
        metaKeywords = 'personal training sydney, best personal trainers sydney';
      } else if (suburb.toLowerCase() === 'brisbane') {
        titleStr = 'Certified Personal Trainers in Brisbane Find and book a session on Avaana';
        metaStr =
          'Bulking, shredding or just looking to lead a healthy and active life? Compare Personal Trainers throughout Brisbane for online, gym or studio sessions.';
        metaKeywords = 'personal training brisbane, Avaana';
      } else if (suburb.toLowerCase() === 'adelaide') {
        titleStr = 'Certified Personal Trainers in Adelaide Find and book a session on Avaana';
        metaStr =
          'Bulking, shredding or just looking to lead a healthy and active life? Compare Personal Trainers throughout Adelaide for online, gym or studio sessions.';
        metaKeywords = 'personal training adelaide, Avaana';
      } else if (suburb.toLowerCase() === 'perth') {
        titleStr = 'Certified Personal Trainers in Perth Find and book a session on Avaana';
        metaStr =
          'Bulking, shredding or just looking to lead a healthy and active life? Compare Personal Trainers throughout Perth for online, gym or studio sessions.';
        metaKeywords = 'personal training perth, Avaana';
      }
    } else if (service === 'Chiropractic') {
      titleStr = 'Book an experienced chiro in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Have back, joint or neck pain and looking for relief today? Get a chiropractic adjustment from an experienced chiro in ' +
        suburb +
        ', ' +
        state +
        ', through Avaana';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Trusted Chiros in Melbourne with same day availability | Avaana';
        metaStr =
          'In pain and need a chiropractor in Melbourne urgently? See available times of Chiros throughout VIC and get 20% off first booking!';
        metaKeywords = 'chiropractor in melbourne, best chiropractor melbourne, Avaana';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Trusted Chiros in Sydney with same day availability | Avaana';
        metaStr =
          'In pain and need a chiropractor in Sydney urgently? See available times of Chiros throughout NSW and get 20% off first booking!';
        metaKeywords = 'chiropractor in sydney, best chiropractor sydney, chiro sydney, Avaana';
      } else if (suburb.toLowerCase() === 'adelaide') {
        titleStr = 'Trusted Chiros in Adelaide with same day availability | Avaana';
        metaStr =
          'In pain and need a chiropractor in Adelaide urgently? See available times of Chiros throughout SA and get 20% off first booking!';
        metaKeywords = 'chiropractor in adelaide, chiropractor adelaide prices, Avaana';
      }
    } else if (service === 'Myotherapy') {
      titleStr = 'Myotherapy in ' + suburb + ', ' + state + ': Book a session now | Avaana';
      metaStr =
        'Alleviate muscle soreness & pain. Find experienced Myotherapists in ' +
        suburb +
        ', ' +
        state +
        ', via Avaana. Book an appointment now to get 20% off.';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Myotherapy in Melbourne: Book a Myotherapy Session on Avaana';
        metaStr =
          'Treat myofascial pain, carpel tunnel syndrome, back and neck pain with Myotherapy. Book a treatment session with a Myotherapist in Melbourne on Avaana.';
        metaKeywords = 'Myotherapy in melbourne, myotherapist melbourne, Avaana';
      }
    } else if (service === 'Massage') {
      titleStr = 'Book the Best Massage in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Sore muscles? Book a massage with the best massage therapists in ' +
        suburb +
        ', ' +
        state +
        '. Book your Appointment on Avaana and enjoy 20% off.';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Book Relaxation and Remedial Massages in Melbourne | Avaana';
        metaStr =
          'Take the stress out of your search for a professional relaxation or remedial massage in Melbourne Metro and Regional VIC | 20% off your first booking on Avaana.';
        metaKeywords =
          'massage in melbourne, day spas melbourne, spas in melbourne, best massage melbourne, best spa in melbourne';
      } else if (suburb.toLowerCase() === 'brisbane') {
        titleStr = 'Book Relaxation and Remedial Massages in Brisbane | Avaana';
        metaStr =
          'Take the stress out of your search for a professional relaxation or remedial massage in Brisbane Metro and Regional QLD | 20% off your first booking on Avaana.';
        metaKeywords = 'massage in brisbane, day spas brisbane, massage brisbane city, best massage brisbane';
      } else if (suburb.toLowerCase() === 'perth') {
        titleStr = 'Book Relaxation and Remedial Massages in Perth | Avaana';
        metaStr =
          'Take the stress out of your search for a professional relaxation or remedial massage in Perth Metro and Regional WA | 20% off your first booking on Avaana.';
        metaKeywords =
          'massage in perth, best massage in perth, massage perth wa, massage perth near you, massage therapist perth, Avaana';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Book Relaxation and Remedial Massages in Sydney | Avaana';
        metaStr =
          'Take the stress out of your search for a professional relaxation or remedial massage in Sydney Metro and Regional NSW | 20% off your first booking on Avaana.';
        metaKeywords = 'massage in sydney, best massage sydney, mobile massage in sydney, massage therapist in sydney';
      } else if (suburb.toLowerCase() === 'adelaide') {
        titleStr = 'Book Relaxation and Remedial Massages in Adelaide | Avaana';
        metaStr =
          'Take the stress out of your search for a professional relaxation or remedial massage in Adelaide Metro and Regional SA | 20% off your first booking on Avaana.';
        metaKeywords = 'massage in adelaide, adelaide massage therapy, best massage adelaide';
      }
    } else if (service === 'Psychology') {
      titleStr = 'Trusted Psychologists in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Knowing you need help is the most important step. You are stronger than you think. Find and book a psychologist and get 20% off via Avaana';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Psychology in Melbourne for anxiety, depression, mental health | Avaana';
        metaStr =
          'See a Psychologist today in Melbourne for anxiety, depression, relationship or mental health issues. Real time availability and online and in clinic appointments';
        metaKeywords =
          'psychologist in melbourne, melbourne psychiatrist, therapist in melbourne, best psychologist melbourne.';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Psychology in Sydney for anxiety, depression, mental health | Avaana';
        metaStr =
          'See a Psychologist today in Sydney for anxiety, depression, relationship or mental health issues. Real time availability and online and in clinic appointments';
        metaKeywords = 'psychiatrist in sydney, psychologist in sydney, therapist sydney.';
      }
    } else if (service === 'Yoga') {
      titleStr = 'Yoga Classes with Certified Teachers in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Book a yoga class in ' +
        suburb +
        ', ' +
        state +
        '. Classes for beginners and experts with 20% off your first booking.';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Yoga in Melbourne: Book a one-on-one session on Avaana';
        metaStr =
          'Improve your mental and spiritual wellbeing with the best yoga classes in Melbourne. Check availability and book a session via Avaana to get 20% off.';
        metaKeywords = 'yoga melbourne, yoga studios melbourne, yoga classes in melbourne, best yoga melbourne';
      } else if (suburb.toLowerCase() === 'brisbane') {
        titleStr = 'Yoga in Brisbane: Book a one-on-one session on Avaana';
        metaStr =
          'Improve your mental and spiritual wellbeing with the best yoga classes in Brisbane. Check availability and book a session via Avaana to get 20% off.';
        metaKeywords = 'yoga brisbane, yoga classes in brisbane';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Yoga in Sydney: Book a one-on-one session on Avaana';
        metaStr =
          'Improve your mental and spiritual wellbeing with the best yoga classes in Sydney. Check availability and book a session via Avaana to get 20% off.';
        metaKeywords = 'yoga sydney, yoga classes in sydney, yoga studios sydney, best yoga studios sydney';
      }
    } else if (service === 'Acupuncture') {
      titleStr = 'Book Acupuncture in ' + suburb + ' | Avaana';
      metaStr =
        'Looking for help with persistent pain, migraines or gut issues? Try Acupuncture in ' +
        suburb +
        ', ' +
        state +
        '. Consult a Practitioner through Avaana';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Acupuncture in Melbourne: Book your appointment on Avaana';
        metaStr =
          'Manage pain, migraines and other ailments. Work with registered acupuncture and TCM practitioners in Melbourne. Book on Avaana to get 20% off.';
        metaKeywords = 'acupuncture melbourne, best acupuncture melbourne, Avaana';
      } else if (suburb.toLowerCase() === 'brisbane') {
        titleStr = 'Acupuncture in Brisbane: Book your appointment on Avaana';
        metaStr =
          'Manage pain, migraines and other ailments. Work with registered acupuncture and TCM practitioners in Brisbane. Book on Avaana to get 20% off.';
        metaKeywords = 'acupuncture brisbane, best acupuncture brisbane, Avaana';
      } else if (suburb.toLowerCase() === 'perth') {
        titleStr = 'Acupuncture in Perth: Book your appointment on Avaana';
        metaStr =
          'Manage pain, migraines and other ailments. Work with registered acupuncture and TCM practitioners in Perth. Book on Avaana to get 20% off.';
        metaKeywords = 'acupuncture perth, Avaana';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Acupuncture in Sydney: Book your appointment on Avaana';
        metaStr =
          'Manage pain, migraines and other ailments. Work with registered acupuncture and TCM practitioners in Sydney. Book on Avaana to get 20% off.';
        metaKeywords = 'acupuncture sydney';
      } else if (suburb.toLowerCase() === 'adelaide') {
        titleStr = 'Acupuncture in Adelaide: Book your appointment on Avaana';
        metaStr =
          'Manage pain, migraines and other ailments. Work with registered acupuncture and TCM practitioners in Adelaide. Book on Avaana to get 20% off.';
        metaKeywords = 'acupuncturist near you, acupuncture clinics, australia acupuncture, Avaana';
      }
    } else if (service === 'Ayurveda') {
      titleStr = 'Ayurveda in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Looking for Ayurveda Professionals in ' +
        suburb +
        ', ' +
        state +
        '? Check availability & book an appointment on-demand via Avaana and get 20% off your first booking';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Ayurvedic Doctors in Melbourne: Find your dosha with a booking on Avaana';
        metaStr =
          'Looking for balance between mind, body and spirit? Your diet may be the key and Qualified Ayurvedic Doctors in Melbourne can help! Book an appointment via Avaana!';
        metaKeywords = 'ayurveda melbourne, ayurvedic clinic melbourne, ayurvedic medicine melbourne, Avaana';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Ayurvedic Doctors in Sydney: Find your dosha with a booking on Avaana';
        metaStr =
          'Looking for balance between mind, body and spirit? Your diet may be the key and Qualified Ayurvedic Doctors in Sydney can help! Book an appointment via Avaana!';
        metaKeywords = 'ayurveda sydney, ayurvedic doctor sydney, Avaana';
      }
    } else if (service === 'Counselling') {
      titleStr = 'Compare Counselling services in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Don’t struggle in silence. Seek Counselling in ' +
        suburb +
        ', ' +
        state +
        '. Schedule an appointment via Avaana and get professional help online or in clinic.';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Counsellors in Melbourne: Book a Counselling Session on Avaana';
        metaStr =
          'Struggling with stress, anxiety or need relationship counselling? Get support from Counsellors in Melbourne and throughout VIC. Find and book on Avaana.';
        metaKeywords = 'counsellors in melbourne, counselling in melbourne, melbourne counselling services, Avaana';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Counsellors in Sydney: Book a Counselling Session on Avaana';
        metaStr =
          'Struggling with stress, anxiety or need relationship counselling? Get support from Counsellors in Sydney and throughout NSW. Find and book on Avaana.';
        metaKeywords = 'counsellors in sydney, counselling in sydney, sydney counselling services, Avaana';
      }
    } else if (service === 'Naturopathy') {
      titleStr = 'Book a Naturopath in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'There is nothing better than a natural treatment for your health concerns. Get to the root cause by seeing a Naturopath in ' +
        suburb +
        ', ' +
        state +
        ' and get 20% off via Avaana';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Book certified Naturopaths in Melbourne and throughout VIC: Find & book natural health on Avaana';
        metaStr =
          'Find Naturopaths that use evidence based natural remedies to support acute and persistent health conditions. 20% off when booking with our trusted VIC network of natural health professionals';
        metaKeywords = 'naturopath melbourne, natural therapy melbourne, best naturopath melbourne, Avaana';
      } else if (suburb.toLowerCase() === 'brisbane') {
        titleStr = 'Book certified Naturopaths in Brisbane and throughout QLD: Find & book natural health on Avaana';
        metaStr =
          'Find Naturopaths that use evidence based natural remedies to support acute and persistent health conditions. 20% off when booking with our trusted QLD network of natural health professionals';
        metaKeywords = 'naturopath brisbane, holistic doctor brisbane.';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Book certified Naturopaths in Sydney and throughout NSW: Find & book natural health on Avaana';
        metaStr =
          'Find Naturopaths that use evidence based natural remedies to support acute and persistent health conditions. 20% off when booking with our trusted NSW network of natural health professionals';
        metaKeywords = 'naturopath sydney, best naturopath sydney, Avaana';
      }
    } else if (service === 'Nutrition') {
      titleStr = 'Consult a Nutritionist in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'A balanced diet is key to weight management and a healthy lifestyle. Get your diet planned with the help of a Nutritionist and get 20% off your appointment';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Melbourne Nutritionists: Book an online or in person appointment on Avaana';
        metaStr =
          'Get in control of your diet with the help of leading Nutritionists in Melbourne. Check availability and book an appointment for an online or in person consultation on Avaana.';
        metaKeywords = 'nutritionist in melbourne, dietitian melbourne, dietician melbourne.';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Sydney Nutritionists: Book an online or in person appointment on Avaana';
        metaStr =
          'Get in control of your diet with the help of leading Nutritionists in Sydney. Check availability and book an appointment for an online or in person consultation on Avaana.';
        metaKeywords = 'nutritionist in sydney, dietitian sydney, dietician sydney.';
      }
    } else if (service === 'Osteopathy') {
      titleStr = 'Find & Book an Osteopathy Session ' + suburb + ' | Avaana';
      metaStr =
        'Identify the root cause of your pain and manage it with Osteopathy. Book a session with qualified Osteos in ' +
        suburb +
        ', ' +
        state +
        ' and get 20% off on Avaana';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Osteopathy in Melbourne: Book an appointment on Avaana';
        metaStr =
          'Persistent pain with no relief in sight? Tried Osteopathy? We swear by it! Connect with the best Osteopaths in Melbourne, on demand, via Avaana with 20% off your booking.';
        metaKeywords = 'osteopathy in melbourne, melbourne osteo, melbourne osteopathy clinic, Avaana';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Osteopathy in Sydney: Book an appointment on Avaana';
        metaStr =
          'Persistent pain with no relief in sight? Tried Osteopathy? We swear by it! Connect with the best Osteopaths in Sydney, on demand, via Avaana with 20% off your booking.';
        metaKeywords = 'osteopathy In sydney, best osteopath sydney.';
      }
    } else if (service === 'Pilates') {
      titleStr = 'Book Pilates Classes in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Book a Pilates Class to improve your flexibility. Find & book a reformer or other class for your experience level in ' +
        suburb +
        ', ' +
        state +
        '. Book via Avaana to get 20% off to kickstart your fitness journey!';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Pilates in Melbourne: Book a Pilates Session on Avaana';
        metaStr =
          'Get coaching for strengthening and toning your core through Pilates in Melbourne. Find and book a session with a Pilates Instructor on Avaana.';
        metaKeywords = 'pilates in melbourne, pilates studio melbourne';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Pilates in Sydney: Book a Pilates Session on Avaana';
        metaStr =
          'Get coaching for strengthening and toning your core through Pilates in Sydney. Find and book a session with a Pilates Instructor on Avaana.';
        metaKeywords = 'pilates sydney, pilates classes sydney';
      } else if (suburb.toLowerCase() === 'adelaide') {
        titleStr = 'Pilates in Adelaide: Book a Pilates Session on Avaana';
        metaStr =
          'Get coaching for strengthening and toning your core through Pilates in Adelaide. Find and book a session with a Pilates Instructor on Avaana.';
        metaKeywords = 'pilates adelaide, pilates studio adelaide';
      }
    } else if (service === 'Podiatry') {
      titleStr = 'Explore Podiatrists in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Prolonged foot pain is not normal. Get it treated today by a podiatrist in ' +
        suburb +
        ', ' +
        state +
        '. Book an appointment and get 20% off on Avaana';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Podiatrists in Melbourne for toenail, skin, muscle and feet conditions | Avaana';
        metaStr =
          'Keep your feet and legs in optimal health with treatment from a registered Podiatrist in Melbourne. Get 20% off and book a session with them on Avaana.';
        metaKeywords = 'podiatry in melbourne, foot clinic melbourne, best podiatrist melbourne.';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Podiatrists in Sydney for toenail, skin, muscle and feet conditions | Avaana';
        metaStr =
          'Keep your feet and legs in optimal health with treatment from a registered Podiatrist in Sydney. Get 20% off and book a session with them on Avaana.';
        metaKeywords = 'podiatry in sydney, mobile podiatrist sydney, best podiatrist in sydney.';
      } else if (suburb.toLowerCase() === 'brisbane') {
        titleStr = 'Podiatrists in Brisbane for toenail, skin, muscle and feet conditions | Avaana';
        metaStr =
          'Keep your feet and legs in optimal health with treatment from a registered Podiatrist in Brisbane. Get 20% off and book a session with them on Avaana.';
        metaKeywords = 'podiatry in brisbane, mobile podiatrist brisbane, best podiatrist in brisbane.';
      }
    } else if (service === 'Reiki') {
      titleStr = 'Reiki in ' + suburb + ', ' + state + ': Book a Reiki Session | Avaana';
      metaStr =
        'Energy blockages can leave you feeling drained out. Clear them through Reiki. Book a session now via Avaana to get 20% off.';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Reiki in Melbourne: Book a Reiki Practitioner appointment on Avaana';
        metaStr =
          'Clear any blocked energy pathways with Reiki. Find and book a session with a Reiki Practitioner in Melbourne on Avaana.';
        metaKeywords = 'reiki in melbourne, reiki healing melbourne, best reiki melbourne.';
      } else if (suburb.toLowerCase() === 'perth') {
        titleStr = 'Reiki in Perth: Book a Reiki Practitioner appointment on Avaana';
        metaStr =
          'Clear any blocked energy pathways with Reiki. Find and book a session with a Reiki Practitioner in perth on Avaana.';
        metaKeywords = 'reiki in perth, reiki healing perth, best reiki perth.';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Reiki in Sydney: Book a Reiki Practitioner appointment on Avaana';
        metaStr =
          'Clear any blocked energy pathways with Reiki. Find and book a session with a Reiki Practitioner in Sydney on Avaana.';
        metaKeywords = 'reiki in sydney, reiki healing sydney.';
      }
    } else if (service === 'Homeopathy') {
      titleStr = 'Book Trusted Homeopathy in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Stimulate your body’s healing response with Homoeopathy. Schedule a session with Certified Homeopathy in ' +
        suburb +
        ', ' +
        state +
        '. Book via Avaana for 20% off.';
      metaKeywords = 'homeopathy ' + suburb.toLowerCase() + ', homeopathy medicine ' + suburb.toLowerCase() + '.';
      if (suburb.toLowerCase() === 'brisbane') {
        titleStr = 'Homeopathy in Brisbane: Book a Homeopathy Doctor’s Consultation on Avaana';
        metaStr =
          'Explore the advantages of homeopathic remedies. Book a consultation with a Homeopathy Doctor in Brisbane on Avaana.';
      } else if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Homeopathy in Melbourne: Book a Homeopathy Doctor’s Consultation on Avaana';
        metaStr =
          'Explore the advantages of homeopathic remedies. Book a consultation with a Homeopathy Doctor in Melbourne on Avaana.';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Homeopathy in Sydney: Book a Homeopathy Doctor’s Consultation on Avaana';
        metaStr =
          'Explore the advantages of homeopathic remedies. Book a consultation with a Homeopathy Doctor in Sydney on Avaana.';
        metaKeywords =
          'homeopathy ' + suburb.toLowerCase() + ', homeopathy medicine ' + suburb.toLowerCase() + ', Avaana';
      }
    } else if (service.toLowerCase() === 'life coaching') {
      titleStr = 'Find the best Life Coaches  in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Take charge of your life. Find Certified Life Coachies in ' +
        suburb +
        ', ' +
        state +
        ', through Avaana. Schedule a session now and to become the best version of yourself.';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Life Coaching in Melbourne: Find a Life Coach on Avaana';
        metaStr =
          'Looking to build confidence, work-life balance, relationships, etc. in Melbourne? A Life Coach may help. Book a session on Avaana.';
        metaKeywords = 'life coaching in melbourne, life coach psychologist melbourne, Avaana';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Life Coaching in Sydney: Find a Life Coach on Avaana';
        metaStr =
          'Looking to build confidence, work-life balance, relationships, etc. in Sydney? A Life Coach may help. Book a session on Avaana.';
        metaKeywords = 'life coach in sydney, online life coach sydney, personal coach sydney, Avaana';
      } else if (suburb.toLowerCase() === 'adelaide') {
        titleStr = 'Life Coaching in Adelaide: Find a Life Coach on Avaana';
        metaStr =
          'Looking to build confidence, work-life balance, relationships, etc. in Adelaide? A Life Coach may help. Book a session on Avaana.';
        metaKeywords = 'life coach in adelaide, adelaide life coach, Avaana';
      }
    } else if (service === 'Meditation') {
      titleStr = 'Explore Meditation Centres in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Calm your mind & re-centre yourself. Find the best Meditation Centres in ' +
        suburb +
        ', ' +
        state +
        ' on Avaana. Save with 20% off to start your meditation journey.';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Meditation in Melbourne: Book a Meditation Class on Avaana';
        metaStr =
          'Looking to improve mindfulness with the help of Meditation Classes in Melbourne? Find a Meditation Class near you, book a consultation on Avaana.';
        metaKeywords =
          'meditation in melbourne, meditation center in melbourne, meditation classes melbourne, melbourne meditation centre.';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Meditation in Sydney: Book a Meditation Class on Avaana';
        metaStr =
          'Looking to improve mindfulness with the help of Meditation Classes in Sydney? Find a Meditation Class near you, book a consultation on Avaana.';
      }
    } else if (service === 'Reflexology') {
      titleStr = 'Discover Reflexology  in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Kiss lethargy & chronic pains goodbye. Find the best Reflexology practitioners in ' +
        suburb +
        ', ' +
        state +
        ', via Avaana. Book an appointment now and get 20% off.';
      metaKeywords = 'reflexology in ' + suburb.toLowerCase() + '';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Reflexology in Melbourne: Book a Reflexology Session on Avaana';
        metaStr =
          'Looking for a therapeutic Reflexology session in Melbourne? Find a Reflexology Clinic near you, book a session on Avaana to assist with pain and release of tension.';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Reflexology in Sydney: Book a Reflexology Session on Avaana';
        metaStr =
          'Looking for a therapeutic Reflexology session in Sydney? Find a Reflexology Clinic near you, book a session on Avaana to assist with pain and release of tension.';
      } else if (suburb.toLowerCase() === 'perth') {
        titleStr = 'Reflexology in Perth: Book a Reflexology Session on Avaana';
        metaStr =
          'Looking for a therapeutic Reflexology session in Perth? Find a Reflexology Clinic near you, book a session on Avaana to assist with pain and release of tension.';
      }
    } else if (service.toLowerCase() === 'exercise physiology') {
      titleStr = 'Accredited Exercise Physiologists in ' + suburb + ', ' + state + ' | Avaana';
      metaStr =
        'Get customised exercise treatment plans based on your medical or rehab requirements. Connect with an Accredited Exercise Physiologist in ' +
        suburb +
        ', ' +
        state +
        ', via Avaana';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Exercise Physiology in Melbourne: Book an Exercise Physiologist’s Consultation on Avaana';
        metaStr =
          'Get assistance recovering from an injury or a chronic illness with Exercise Physiology in Melbourne. Find and book a consultation with an Exercise Physiologist on Avaana.';
        metaKeywords = 'exercise physiologist melbourne';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Exercise Physiology in Sydney: Book an Exercise Physiologist’s Consultation on Avaana';
        metaStr =
          'Get assistance recovering from an injury or a chronic illness with Exercise Physiology in Sydney. Find and book a consultation with an Exercise Physiologist on Avaana.';
        metaKeywords = 'exercise physiology sydney';
      } else if (suburb.toLowerCase() === 'perth') {
        titleStr = 'Exercise Physiology in Perth: Book an Exercise Physiologist’s Consultation on Avaana';
        metaStr =
          'Get assistance recovering from an injury or a chronic illness with Exercise Physiology in Perth. Find and book a consultation with an Exercise Physiologist on Avaana.';
        metaKeywords = 'exercise physiologist perth';
      }
    } else if (service.toLowerCase() === 'group fitness') {
      titleStr = 'Affordable Group Fitness in ' + suburb + ', ' + state + '  | Avaana';
      metaStr =
        'Want to exercise with your workout buddies? Join a Group Fitness in ' +
        suburb +
        ', ' +
        state +
        '. Book via Avaana to get your hands on the best deals.';
      if (suburb.toLowerCase() === 'melbourne') {
        titleStr = 'Group Fitness in Melbourne: Book a Group Fitness Class on Avaana';
        metaStr =
          'Looking to group exercise, Pilates and Crossfit programs in Melbourne? Find Group Fitness Classes near you, book a class on Avaana.';
        metaKeywords = 'fitness class melbourne';
      } else if (suburb.toLowerCase() === 'sydney') {
        titleStr = 'Group Fitness in Sydney: Book a Group Fitness Class on Avaana';
        metaStr =
          'Looking to group exercise, Pilates and Crossfit programs in Sydney? Find Group Fitness Classes near you, book a class on Avaana.';
        metaKeywords = 'fitness classes sydney';
      }
    } else if (this.conditions.includes(this.service)) {
      metaStr = '';
      titleStr = '';
      const datStr = this.getSymptomTitle(suburb);
      if (datStr.titleStr) {
        titleStr = datStr.titleStr;
        metaStr = datStr.metaStr;
      }
      metaKeywords = service.toLowerCase() + ' near you,' + service + ', Avaana';
    }
    this.title.setTitle(titleStr);
    this.meta.updateTag({ name: 'description', content: metaStr });
    this.meta.updateTag({ name: 'keywords', content: metaKeywords });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    this.meta.updateTag({ name: 'og:title', content: titleStr });
    this.meta.updateTag({ name: 'og:description', content: metaStr });
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
    });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
    this.meta.updateTag({
      name: 'og:url',
      content: this.dom?.location?.href
    });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:title', content: titleStr });
    this.meta.updateTag({ name: 'twitter:description', content: metaStr });
    this.meta.updateTag({ name: 'twitter:site', content: 'Avaana' });
    this.meta.updateTag({
      name: 'twitter:url',
      content: this.dom?.location?.href
    });
    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
    });
  }

  setMetaForCategory() {
    let titleStr = '';
    let metaStr = '';
    let metaKeywords = '';
    const subCaps = (this.suburb || 'Me').replace(/-/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase());
    if (this.service) {
      this.service = this.service.toLowerCase();
      if (this.service === 'physiotherapy') {
        titleStr = `Physiotherapy - Connect With Certified Physiotherapists Near ${subCaps} | Avaana`;
        metaStr = `Physio Near ${subCaps} - Restore your body’s mobility and overall well-being with Physiotherapy. Schedule an appointment with certified Physiotherapists via Avaana.`;
        metaKeywords = `physio near ${subCaps.toLowerCase()}, physiotherapist near ${subCaps.toLowerCase()}, physiotherapy clinic, physiotherapy centre, Avaana`;
      } else if (this.service === 'personal-training') {
        titleStr = `Personal Training - Book Personal Trainers Near ${subCaps} | Avaana`;
        metaStr = `Personal Trainer Near ${subCaps} - Struggling with your fitness goals? Appoint a Personal Trainer via Avaana. Find the best Gym Trainers with guaranteed price.`;
        metaKeywords = `personal trainer, personal trainer near ${subCaps.toLowerCase()}, gym trainer, online fitness coach, mobile personal trainers, Avaana`;
      } else if (this.service === 'chiropractic') {
        titleStr = `Chiropractors Near ${subCaps}: Book Your First Appointment Now | Avaana`;
        metaStr = `Looking for trusted Chiropractors? Connect with the best Chiropractic Clinics near ${subCaps} via Avaana. Get discounts, appealing deals, and more.`;
        metaKeywords = `chiropractors near ${subCaps.toLowerCase()}, chiropractic near ${subCaps.toLowerCase()}, chiropractic doctors, chiro near ${subCaps.toLowerCase()}, Avaana`;
      } else if (this.service === 'myotherapy') {
        titleStr = `Schedule A Myotherapy Session Near ${subCaps} | Avaana`;
        metaStr = `Bid adieu to chronic aches and pains with Myotherapy. Get 20% off on your first session and the best prices on certified Myotherapists near ${subCaps}`;
        metaKeywords = `myotherapist near ${subCaps.toLowerCase()}, dry needling near ${subCaps.toLowerCase()}, cupping therapy near ${subCaps.toLowerCase()}, Avaana`;
      } else if (this.service === 'massage') {
        titleStr = `Relaxation and Remedial Massages Near ${subCaps}  | Avaana`;
        metaStr =
          'Want to indulge in a relaxing massage? Book an appointment at a nearby day spa or massage clinic via Avaana. Get 15% off with guaranteed best prices.';
        metaKeywords = `massage near ${subCaps.toLowerCase()}, massage places near ${subCaps.toLowerCase()}, day spas near ${subCaps.toLowerCase()}, spa near ${subCaps.toLowerCase()}, massage centre, Avaana`;
      } else if (this.service === 'psychology') {
        titleStr = 'Schedule A Session With a Psychologist | Avaana';
        metaStr =
          'Do not ignore your mental health! Book an appointment for eTherapy and get treated with interactive counselling';
        metaKeywords = `psychologist near ${subCaps.toLowerCase()}, psychiatrist near ${subCaps.toLowerCase()}, find a psychologist, online psychologist australia, Avaana`;
      } else if (this.service === 'yoga') {
        titleStr = `Yoga Classes - Find Yoga Studios Near ${subCaps} | Avaana`;
        metaStr =
          'Yoga Centre - Heal your mind, body, and spirit with Yoga. Connect with the best Yoga instructors near you to stay fit mentally and physically.';
        metaKeywords = 'yoga near ${subCaps}, yoga classes near ${subCaps}, yoga studios, Avaana';
      } else if (this.service === 'ayurveda') {
        titleStr = `Ayurveda Wellness Center: Get Great Discounts On Ayurveda Services Near ${subCaps} | Avaana`;
        metaStr =
          'Ayurvedic Clinic - Ayurveda heals your mind, body, and spirit. Book an Ayurveda Provider via Avaana and get access to different treatments at affordable rates';
        metaKeywords = `ayurvedic wellness centre, ayurvedic doctor near ${subCaps.toLowerCase()}, ayurveda clinic, Avaana`;
      } else if (this.service === 'counselling') {
        titleStr = `Counselling Near ${subCaps} - Find And Book The Best Counsellors Near ${subCaps} | Avaana`;
        metaStr =
          'Seeking help for deteriorating mental health? Book a Counselling Session now via Avaana and get access to the best Counsellors.';
        metaKeywords = `counsellor online, counsellors near ${subCaps.toLowerCase()}, counselling near ${subCaps.toLowerCase()}, find a counsellor, Avaana`;
      } else if (this.service === 'osteopathy') {
        titleStr = 'Osteopathy Near ${subCaps} - Connect With The Best Osteopaths | Avaana';
        metaStr =
          'Experiencing low body energy? Schedule an appointment with your nearest Osteopath via Avaana. Get flat 10% off on your first session';
        metaKeywords = `osteopathy near ${subCaps.toLowerCase()}, osteopath near ${subCaps.toLowerCase()}, osteo near ${subCaps.toLowerCase()}, Avaana`;
      } else if (this.service === 'reiki') {
        titleStr = `Find A Reiki Practitioner Near ${subCaps} | Avaana`;
        metaStr = 'Relax your mind and body with a rejuvenating energy therapy. Book a Reiki Session via Avaana';
        metaKeywords = `reiki near ${subCaps}, reiki healing near ${subCaps}, reiki therapy, Avaana`;
      } else if (this.service === 'podiatry') {
        titleStr = `Explore Podiatry Clinics Near ${subCaps} | Avaana`;
        metaStr =
          'Feet problems hint toward underlying health issues. Don’t neglect them and book an appointment with your nearest Podiatry Clinic now';
        metaKeywords = `podiatrist near ${subCaps.toLowerCase()}, chiropodists near ${subCaps.toLowerCase()}, podiatry clinic, foot doctor near ${subCaps.toLowerCase()}, Avaana`;
      } else if (this.service === 'acupuncture') {
        titleStr = `Acupuncture Clinic - Find & Book Acupuncturists Near ${subCaps} | Avaana`;
        metaStr =
          'Looking for trusted Acupuncturists at affordable prices? Connect with the best acupuncture clinics via Avaana and schedule an appointment. Book Now!';
        metaKeywords = `acupuncturist near ${subCaps}, acupuncture clinics, australia acupuncture, Avaana`;
      } else if (this.service === 'nutrition') {
        titleStr = 'Nutrition Consultation - 10% Off On Nutritionists Online | Avaana';
        metaStr =
          'Struggling with unhealthy eating habits? Seek professional guidance from certified Nutritionists. Get 20% off on your first appointment via Avaana. Book now!';
        metaKeywords = `dietician near ${subCaps.toLowerCase()}, nutritionist near ${subCaps.toLowerCase()}, nutritionist online, online nutrition coaching, Avaana`;
      } else if (this.service === 'pilates') {
        titleStr = `Best Pilates Classes Near ${subCaps} | Avaana`;
        metaStr =
          'Celebrities swear by it, and so do we! Book a Pilates Class to build muscle strength and lose those extra kilos. Get discounts on your first session';
        metaKeywords = `pilates near ${subCaps.toLowerCase()}, pilates studios, reformer pilates near ${subCaps.toLowerCase()}, pilates classes near ${subCaps.toLowerCase()}, Avaana`;
      } else if (this.service === 'naturopathy') {
        titleStr = `Naturopathy Consultant - Get Access To Certified Naturopaths Near ${subCaps} | Avaana`;
        metaStr =
          'Looking for Certified Naturopaths in your area? Connect with the best via Avanna and enjoy 10% off on your first appointment.';
        metaKeywords = `naturopath near ${subCaps.toLowerCase()}, holistic doctor near ${subCaps.toLowerCase()}, naturopathic practitioners, Avaana`;
      } else if (this.service === 'homeopathy') {
        titleStr = `Find The Best Homoeopaths near ${subCaps} | Avaana`;
        metaStr =
          'Want to maximise Homeopathy sessions? Schedule an appointment with the best Homeopaths near you and get access to appealing discounts';
        metaKeywords = `homeopathy near ${subCaps.toLowerCase()}, homeopathy doctor near ${subCaps.toLowerCase()}, Avaana`;
      } else if (this.service === 'reflexology') {
        titleStr = `Book A Reflexology Session Near ${subCaps} | Avaana`;
        metaStr =
          "Feeling stressed? Fix it by restoring your body's balance with experienced Reflexologists. Find and book your first appointment";
        metaKeywords = `reflexologists near ${subCaps.toLowerCase()}, reflexology near ${subCaps.toLowerCase()}, foot reflexology near ${subCaps.toLowerCase()}, Avaana`;
      } else if (this.service === 'meditation') {
        titleStr = `Find The Best Meditation Classes Near ${subCaps} | Avaana`;
        metaStr =
          'Allow meditation to transform your life. Book your first Meditation Class and Get 20% off. Explore the best Mediation Centres in your area';
        metaKeywords = `meditation class near ${subCaps.toLowerCase()}, meditation classes, meditation near ${subCaps.toLowerCase()}, meditation center, Avaana`;
      } else if (this.service === 'life-coaching') {
        titleStr = `Book Life Coaches Online Or Near ${subCaps} | Avaana`;
        metaStr =
          'Want to live life according to your terms? Allow a Life Coach to guide you. Book certified Life Coaches via Avaana.';
        metaKeywords = `life coach near ${subCaps.toLowerCase()}, health and wellness coaching, personal coach, online life coaching, Avaana`;
      } else if (this.service === 'group-fitness') {
        titleStr = `Connect With Group Fitness Providers Near ${subCaps} | Avaana`;
        metaStr =
          'Having an exercise buddy helps! Book a Group Fitness Class with your friend via Avaana. Avail of exciting discounts and access to different classes.';
        metaKeywords = `group fitness classes near ${subCaps.toLowerCase()}, group fitness, exercise classes near ${subCaps.toLowerCase()}, Avaana`;
      } else if (this.service === 'exercise-physiology') {
        titleStr = 'Find an appropriate Exercise Physiology Sessions | Avaana';
        metaStr =
          'Experiencing constant chronic pain, illnesses, and other physical ailments? Connect with the best and affordable practitioners via Avaana.';
        metaKeywords = `exercise physiologist near ${subCaps.toLowerCase()}, exercise therapist, Avaana`;
      } else if (this.service === 'gym') {
        titleStr = `Gym Near ${subCaps} - Explore & Join Best Gym Now | Avaana`;
        metaStr =
          'Gym near you - Find a list of best Gyms nearby and get huge booking discounts on Avaana. Visit now and join your Gym Class today!';
        metaKeywords = `gym near ${subCaps.toLowerCase()}, best gym class near ${subCaps.toLowerCase()}, gyms nearby, fitness center near ${subCaps.toLowerCase()}`;
      } else if (this.service === 'martial-arts') {
        titleStr = `Martial Arts Near ${subCaps} - Find & Join Best Martial Arts Classes Now | Avaana`;
        metaStr = `Martial Arts Near ${subCaps} - Find the list of best Martial Arts classes near ${subCaps} and get huge booking discounts on Avaana. Visit now and join your Martial Arts Class today!`;
        metaKeywords = `martial arts near ${subCaps.toLowerCase()}, best martial arts class near ${subCaps.toLowerCase()}, martial arts classes near ${subCaps.toLowerCase()}, martial arts schools near ${subCaps.toLowerCase()}`;
      } else if (this.service === 'therapeutic-supports') {
        titleStr = `Therapeutic Supports Near ${subCaps} - Find & Join Best Therapeutic SupportsClasses Now | Avaana`;
        metaStr = `Therapeutic supports Near ${subCaps} - Find the list of best Therapeutic supports classes near ${subCaps} and get huge booking discounts on Avaana. Visit now and join your Therapeutic supports Class today!`;
        metaKeywords = `Therapeutic supports near ${subCaps.toLowerCase()}, best Therapeutic supports class near ${subCaps.toLowerCase()}, Therapeutic supports classes near ${subCaps.toLowerCase()}, Therapeutic supports schools near ${subCaps.toLowerCase()}`;
      } else if (this.conditions.includes(this.service)) {
        metaKeywords = `${this.service.toLowerCase()} near ${subCaps.toLowerCase()}, ${this.service} , Avaana`;
      } else {
        try {
          let parent = '';
          const subCatObj = Constants.SUBCATEGORY_LIST;
          for (const key in subCatObj) {
            if (subCatObj[key].findIndex((x: any) => x.key === this.service) > -1) {
              parent = key;
            }
          }

          titleStr = subcategory_data?.[parent]?.[this.service]?.titleStr;
          metaStr = subcategory_data?.[parent]?.[this.service]?.metaStr;
          metaKeywords = this.service.toLowerCase() + ' near ${subCaps},' + this.service + ', Avaana';
          if (parent) {
            metaKeywords = subCatObj?.[this.service]?.keywords;
          }
        } catch (error) {
          console.info(error);
        }
      }
    }

    const datStr = this.getSymptomTitle(this.suburb);
    if (datStr.titleStr) {
      titleStr = datStr.titleStr;
      metaStr = datStr.metaStr;
    }
    this.title.setTitle(titleStr);

    this.meta.updateTag({ name: 'description', content: metaStr });
    this.meta.updateTag({ name: 'keywords', content: metaKeywords });
    this.meta.updateTag({ name: 'robots', content: 'index, follow' });
    this.meta.updateTag({ name: 'og:title', content: titleStr });
    this.meta.updateTag({ name: 'og:description', content: metaStr });
    this.meta.updateTag({
      name: 'og:image',
      content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
    });
    this.meta.updateTag({ name: 'og:type', content: 'website' });
    this.meta.updateTag({
      name: 'og:url',
      content: this.dom?.location?.href
    });
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'twitter:site', content: 'Avaana' });
    this.meta.updateTag({ name: 'twitter:title', content: titleStr });
    this.meta.updateTag({
      name: 'twitter:url',
      content: this.dom?.location?.href
    });
    this.meta.updateTag({ name: 'twitter:description', content: metaStr });

    this.meta.updateTag({
      name: 'twitter:image',
      content: 'https://res.cloudinary.com/avaana/image/upload/v1713869618/bg-cover.webp'
    });
  }

  updateCanonicalUrl(url: string) {
    const head = this.dom.getElementsByTagName('head')[0];
    let element: HTMLLinkElement = this.dom.querySelector("link[rel='canonical']") || null;
    if (element === null) {
      element = this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel', 'canonical');
    element.setAttribute('href', this.canonical.transform(url));
  }

  getSuburbMetaTag(service = '', suburb = '', searchBy = '') {
    const metadata = {
      title: service + ' ' + suburb + ' : Book ' + service + ' in ' + suburb + ' | Avaana',
      description:
        'Looking for the best ' +
        service.toLowerCase() +
        ' services in ' +
        suburb +
        '? See availability & book an appointment for ' +
        service.toLowerCase() +
        ' on demand. Get 20% off & best price guarantee via Avaana'
    };
    if (searchBy === 'online') {
      suburb = 'online';
    } else {
      suburb = 'in ' + suburb;
    }
    switch (service.toLowerCase()) {
      case 'physiotherapy':
        metadata.title = 'Book in today with experienced physios ' + suburb + ' | Avaana';
        metadata.description =
          'Recovering from an injury?  Consult Leading Physios for rehab and pain management ' +
          suburb +
          ' and get 20% off via Avaana';
        break;
      case 'personal-training':
        metadata.title = 'Personal Trainers ' + suburb + ' | Avaana';
        metadata.description =
          'Weight loss, muscle gain or cardio. Get quicker results and 20% off your first session with a qualified personal trainer' +
          suburb +
          ' on Avaana';
        break;
      case 'chiropractic':
        metadata.title = 'Book an experienced chiro ' + suburb + ' | Avaana';
        metadata.description =
          'Have back, joint or neck pain and looking for relief today? Get a chiropractic adjustment from an experienced chiro ' +
          suburb +
          ', through Avaana';
        break;
      case 'myotherapy':
        metadata.title = 'Myotherapy ' + suburb + ': Book a session now | Avaana';
        metadata.description =
          'Alleviate muscle soreness & pain. Find experienced Myotherapists ' +
          suburb +
          ', via Avaana. Book an appointment now to get 20% off';
        break;
      case 'massage':
        metadata.title = 'Book the Best Massage ' + suburb + ' | Avaana';
        metadata.description =
          'Sore muscles? Book a massage with the best massage therapists ' +
          suburb +
          '. Book your Appointment on Avaana and enjoy 20% off.';
        break;
      case 'psychology':
        metadata.title = 'Trusted Psychologists ' + suburb + ' | Avaana';
        metadata.description =
          'Knowing you need help is the most important step. You are stronger than you think. Find and book a psychologist and get 20% off via Avaana';
        break;
      case 'yoga':
        metadata.title = 'Yoga Classes with Certified Teachers ' + suburb + ' | Avaana';
        metadata.description =
          'Book a yoga class ' + suburb + '. Classes for beginners and experts with 20% off your first booking';
        break;
      case 'ayurveda':
        metadata.title = 'Ayurveda ' + suburb + ' | Avaana';
        metadata.description =
          'Looking for Ayurveda Professionals ' +
          suburb +
          '? Check availability & book an appointment on-demand via Avaana and get 20% off your first booking';
        break;
      case 'counselling':
        metadata.title = 'Compare Counselling services ' + suburb + ' | Avaana';
        metadata.description =
          "Don't struggle in silence. Seek counselling " +
          suburb +
          '. Schedule an appointment via Avaana and get professional help online or in clinic';
        break;
      case 'osteopathy':
        metadata.title = 'Find & Book an Osteopathy Session ' + suburb + ' | Avaana';
        metadata.description =
          'Identify the root cause of your pain and manage it with Osteopathy. Book a session with qualified Osteos ' +
          suburb +
          ' and get 20% off on Avaana';
        break;
      case 'reiki':
        metadata.title = 'Reiki ' + suburb + ': Book a Reiki Session | Avaana';
        metadata.description =
          'Energy blockages can leave you feeling drained out. Clear them through Reiki. Book a session now via Avaana to get 20% off';
        break;
      case 'podiatry':
        metadata.title = 'Explore Podiatrists ' + suburb + ' | Avaana';
        metadata.description =
          'Prolonged foot pain is not normal. Get it treated today by a podiatrist ' +
          suburb +
          '. Book an appointment and get 20% off on Avaana';
        break;
      case 'acupuncture':
        metadata.title = 'Book Acupuncture ' + suburb + ' | Avaana';
        metadata.description =
          'Looking for help with persistent pain, migraines or gut issues? Try Acupuncture ' +
          suburb +
          '. Consult a Practitioner through Avaana';
        break;
      case 'nutrition':
        metadata.title = 'Consult a Nutritionist ' + suburb + ' | Avaana';
        metadata.description =
          'A balanced diet is key to weight management and a healthy lifestyle. Get your diet planned with the help of a Nutritionist and get 20% off your appointment';
        break;
      case 'pilates':
        metadata.title = 'Book Pilates Classes ' + suburb + ' | Avaana';
        metadata.description =
          'Book a Pilates Class to improve your flexibility. Find & book a reformer or other class for your experience level ' +
          suburb +
          '. Book via Avaana to get 20% off to kickstart your fitness journey';
        break;
      case 'naturopathy':
        metadata.title = 'Book a Naturopath ' + suburb + ' | Avaana';
        metadata.description =
          'There is nothing better than a natural treatment for your health concerns. Get to the root cause by seeing a Naturopath ' +
          suburb +
          ' and get 20% off via Avaana';
        break;
      case 'homeopathy':
        metadata.title = 'Book Trusted Homeopaths ' + suburb + ' | Avaana';
        metadata.description =
          "Stimulate your body's healing response with Homoeopathy. Schedule a session with Certified Homeopaths " +
          suburb +
          '. Book via Avaana for 20% off';
        break;
      case 'reflexology':
        metadata.title = 'Discover Reflexology ' + suburb + ' | Avaana';
        metadata.description =
          'Kiss lethargy & chronic pains goodbye. Find the best Reflexology practitioners ' +
          suburb +
          ', via Avaana. Book an appointment now and get 20% off';
        break;
      case 'meditation':
        metadata.title = 'Explore Meditation Centres ' + suburb + ' | Avaana';
        metadata.description =
          'Calm your mind & re-centre yourself. Find the best Meditation Centres ' +
          suburb +
          ' on Avaana. Save with 20% off to start your meditation journey';
        break;
      case 'life-coaching':
        metadata.title = 'Find the best Life Coaches ' + suburb + ' | Avaana';
        metadata.description =
          'Take charge of your life. Find Certified Life Coachies ' +
          suburb +
          ', through Avaana. Schedule a session now and to become the best version of yourself';
        break;
      case 'group-fitness':
        metadata.title = 'Affordable Group Fitness ' + suburb + '  | Avaana';
        metadata.description =
          'Want to exercise with your workout buddies? Join a Group Fitness ' +
          suburb +
          '. Book via Avaana to get your hands on the best deals';
        break;
      case 'exercise-physiology':
        metadata.title = 'Accredited Exercise Physiologists ' + suburb + ' | Avaana';
        metadata.description =
          'Get customised exercise treatment plans based on your medical or rehab requirements. Connect with an Accredited Exercise Physiologist ' +
          suburb +
          ', via Avaana';
        break;
      case 'gym':
      case 'martial-arts':
      default:
        break;
    }

    if (searchBy === 'online') {
      metadata.title = 'Book an Online or Telehealth session for ' + service + ' | Avaana';
    }

    return metadata;
  }

  toTitleCase(str: string): string {
    return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  getSymptomTitle(suburb = '') {
    let titleStr = '';
    let metaStr = '';
    if (this.service === 'stress') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Stress (Book Now)';
      metaStr =
        'Stressed? These Naturopathy, Counselling and Massage practitioners in' +
        this.toTitleCase(suburb) +
        'can help you relax.';
    } else if (this.service === 'fatigue') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Fatigue (Book Now)';
      metaStr =
        'Need MORE energy? These Acupuncture, Naturopathy and Massage practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'gut problems') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Gut Problems (Book Now)';
      metaStr =
        'Struggling with gut problem? These Nutrition, Naturopathy and Acupuncture practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'anxiety') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Anxiety (Book Now)';
      metaStr =
        'Need support for anxiety? These Psychology, Naturopathy and Meditation practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'depression') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Depression (Book Now)';
      metaStr =
        'Need support for depression? These Psychology, Naturopathy and Yoga practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'headaches') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Headaches (Book Now)';
      metaStr =
        'Struggling with headaches? These Chiropractic, Acupuncture and Naturopathy practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'insomnia') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Insomnia (Book Now)';
      metaStr =
        'Struggling with lack of sleep? These Naturopathy, Acupuncture and Yoga practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'sleep - insomnia') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Sleep Insomnia (Book Now)';
      metaStr =
        'Struggling with lack of sleep? These Naturopathy, Acupuncture and Yoga practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'infertility') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Infertility (Book Now)';
      metaStr =
        'Trying to conceive? These Naturopathy, Acupuncture and Ayurveda practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'pms') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat PMS (Book Now)';
      metaStr =
        'Struggling with PMS? These Naturopathy, Acupuncture and Ayurveda practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'hot flashes') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Hot Flushes (Book Now)';
      metaStr =
        'Struggling with menopause? These Acupuncture, Naturopathy and Massage practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'high-blood-pressure') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat High Blood Pressure (Book Now)';
      metaStr =
        'Have high blood pressure? These Homeopathy, Naturopathy and Ayurveda practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'arthritis') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Arthritis (Book Now)';
      metaStr =
        'Struggling with arthritis? These Acupuncture, Pilates and Naturopathy practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'back-pain') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Back Pain (Book Now)';
      metaStr =
        'Struggling with back pain? These Acupuncture, Physiotherapy and Massage practitioners in  ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'neck pain') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Neck Pain (Book Now)';
      metaStr =
        'Struggling with neck pain? These Acupuncture, Physiotherapy and Chiropractic practitioners in  ' +
        this.toTitleCase(suburb) +
        ' can help.';
    } else if (this.service === 'diabetes') {
      titleStr = this.toTitleCase(suburb) + ' Practitioners who treat Diabetes (Book Now)';
      metaStr =
        'Have diabetes? These Nutrition, Naturopathy and Acupuncture practitioners in ' +
        this.toTitleCase(suburb) +
        ' can help.';
    }

    return { titleStr, metaStr, suburb };
  }
}
