import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { JsonLdService } from 'ngx-seo';

import { Location } from './interfaces/homepage';
import { User } from './models/user';
import { ApiService } from './services/api.service';
import { AuthAppService } from './services/auth-app/auth-app.service';
import { DomHelperService } from './services/dom-helper.service';
import { StoreService } from './services/store.service';
import { SchemaService } from './shared/components/seo-extention/schema.service';
import { SeoExtentionComponent } from './shared/components/seo-extention/seo-extention.component';
import { CanonicalUrlPipe } from './shared/pipes/canonical-url.pipe';
import { SlugTextPipe } from './shared/pipes/slug-text.pipe';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends SeoExtentionComponent implements OnInit {
  loggedUser: User;

  showHeader = true;

  isBrowser = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthAppService,
    private api: ApiService,
    private store: StoreService,
    @Inject(PLATFORM_ID) private platformId: any,
    private titleService: Title,
    private metaService: Meta,
    @Inject(DOCUMENT) private dom_: any,
    private readonly jsonLd: JsonLdService,
    private schema: SchemaService,
    private slugPipe: SlugTextPipe,
    private canonical_: CanonicalUrlPipe,
    private domHelper: DomHelperService
  ) {
    super(router, route, titleService, metaService, dom_, jsonLd, schema, slugPipe, canonical_);
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  showFooter = true;

  ngOnInit(): void {
    super.initialCall();
    this.domHelper.loadScript(
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbfv-NcSLHO0FJo90VA0lfR5kflxcpcso&region=AU&v=weekly&libraries=places,geometry&loading=async'
    );

    this.checkValidJWT();
    if (this.isBrowser) {
      this.fetchHeaderUrls();
      this.store.fetchmeraLocation.subscribe(() => {
        this.getmeraLocation();
      });
      this.store.meraLocationSub.subscribe((resp) => {
        if (!resp.post_code) {
          this.getmeraLocation();
        }
        const regex = new RegExp(/^(0[289][0-9]{2})|([1-9][0-9]{3})$/);
        if (resp.post_code && !regex.test(resp.post_code)) {
          resp.city_name = 'Melbourne';
          resp.post_code = '3000';
          resp.lng = '';
          resp.lat = '';
          resp.state_name = 'Victoria';
          this.store.setmeraLocation(resp);
        }
      });
      this.getloggedUser();
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          setTimeout(() => {
            const contentContainer: any = document.querySelector('body') || window;
            contentContainer?.scrollIntoView('top');
          }, 300);

          const url_split = event.url.split('/');
          if (
            url_split[1] === 'search' ||
            (url_split[1] === 'business' && event.url !== '/business/provider/signup') ||
            url_split[1] === 'wellbeing' ||
            url_split[1] === 'booking' ||
            url_split[1] === 'checkout' ||
            url_split[1] === 'confirm' ||
            url_split[1] === 'profile' ||
            url_split[1] === 'work-cover'
          ) {
            this.showFooter = false;
          } else {
            this.showFooter = true;
          }
          if (url_split[1] === 'booking' || url_split[1] === 'checkout' || url_split[1] === 'confirm') {
            this.showHeader = false;
          } else {
            this.showHeader = true;
          }
        }
      });
    }
  }

  checkValidJWT() {
    const token = this.authService.getToken();
    if (token) {
      this.api.checkValidSession().subscribe({
        next: (response: any) => {
          console.info(response);
        },
        error: (error: any) => {
          console.info(error);
          this.authService.purgeAuth();
        }
      });
    }
  }

  fetchHeaderUrls() {
    if (Object.keys(this.store.headerCategories).length === 0) {
      this.api.getHeaderUrls().subscribe((response) => {
        this.store.headerCategories = response;
      });
    }
  }

  getloggedUser() {
    try {
      const user = localStorage.getItem('loggedUser');
      if (!user) return;
      this.authService.setloggedUser(JSON.parse(user));
    } catch (error) {
      console.info('local storage error: IGNORE');
    }
  }

  getmeraLocation() {
    // Check if geolocation is supported by the browser
    if (this.isBrowser && 'geolocation' in window.navigator) {
      // Prompt user for permission to access their location
      window.navigator.geolocation.getCurrentPosition(
        // Success callback function
        (position) => {
          // Get the user's latitude and longitude coordinates
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          // const lat = '-37.8156954';
          // const lng = '144.8828322';

          this.getCoordinates(lat, lng);

          // Do something with the location data, e.g. display on a map
        },
        // Error callback function
        (error) => {
          // Handle errors, e.g. user denied location sharing permissions
          console.error('Error getting user location:', error);
        }
      );
    } else {
      // Geolocation is not supported by the browser
      console.error('Geolocation is not supported by this browser.');
      const lat = '-37.8156954';
      const lng = '144.8828322';

      this.getCoordinates(lat, lng);
    }
  }

  getCoordinates(latitude: string | number, longitude: string | number) {
    const lat_lon = latitude + ', ' + longitude;
    this.api.getGeoCodingByAddress(lat_lon).subscribe({
      next: (response) => {
        let post_code = '3000';
        let state_name = 'Victoria';
        let city_name = 'Melbourne';
        const list_with_post_code = response.geocode_details.results.find(
          (geocode: any) => geocode.address_components.findIndex((x: any) => x.types.indexOf('postal_code') > -1) > -1
        );

        let addresscomponents = [];
        if (list_with_post_code && list_with_post_code.address_components) {
          addresscomponents = [...list_with_post_code.address_components];
        } else {
          addresscomponents = [...response.geocode_details.results[0].address_components];
        }

        for (let i = 0; i < addresscomponents.length; i++) {
          const component = addresscomponents[i];
          if (component.types.indexOf('postal_code') > -1) {
            post_code = component.long_name;
          } else if (component.types.indexOf('administrative_area_level_1') > -1) {
            state_name = component.long_name;
          } else if (
            component.types.indexOf('locality') > -1 ||
            component.types.indexOf('sublocality') > -1 ||
            component.types.indexOf('neighborhood') > -1
          ) {
            city_name = component.long_name;
          }
        }
        const location = new Location();
        location.lat = latitude;
        location.lng = longitude;
        location.post_code = post_code;
        location.city_name = city_name;
        location.state_name = state_name;
        this.store.setmeraLocation(location);
      }
    });
  }
}
