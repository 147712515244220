import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedCompsService } from 'avn/shared/shared-comps.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  constructor(
    private sharedService: SharedCompsService,
    private router: Router
  ) {}

  showSnack = false;
  message = '';
  sendClosedEvent = false;

  buttonText = 'Close';
  routerLink: any;

  timerSubscription: ReturnType<typeof setTimeout>;

  ngOnInit(): void {
    this.sharedService.snackBar.subscribe((data) => {
      this.showSnack = false;
      if (!data.message) return;
      if (this.timerSubscription) {
        clearTimeout(this.timerSubscription);
      }
      this.sendClosedEvent = data.sendBack;
      this.message = data.message;
      this.buttonText = data.buttonText || 'Close';
      this.showSnack = true;
      if (data.duration) {
        this.startTimer(data.duration);
      }
      if (data?.routerLink) {
        this.routerLink = data?.routerLink;
      }
    });
  }

  startTimer(timer: number) {
    this.timerSubscription = setTimeout(() => {
      this.showSnack = false;
    }, timer * 1000);
  }

  closeBtn() {
    this.showSnack = false;
    if (!this.sendClosedEvent) return;
    this.sharedService.snackBarClose.next(true);
    if (this?.routerLink) {
      this.router.navigate([this?.routerLink]);
    }
  }
}
