<div class="login-buttons">
  <button class="secondary" (click)="closeModal()" [routerLink]="['/login']">Login</button>
  <button class="primary" (click)="closeModal()" [routerLink]="['/sign-up']">Register</button>
</div>
<div class="menu-set-1">
  <p class="head">For Health and Wellbeing Providers</p>
  <ul class="external">
    <li>
      <a routerLink="/work-cover/sign-up" (click)="closeModal();">
        <p class="name">Physios and Psychs Join our Injury Management Program</p>
        <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
      </a>
    </li>
    <li>
      <a href="https://listyourbusiness.avaana.com.au" target="_blank" (click)="closeModal();">
        <p class="name">Wellbeing Businesses list your business for free</p>
        <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
      </a>
    </li>
  </ul>
</div>
<div class="menu-set-2">
  <a href="https://ndis.avaana.com.au/" target="_blank" (click)="closeModal();">
    <p class="name">Get NDIS Registered</p>
    <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
  </a>
  <a routerLink="/workplacewellbeing" (click)="closeModal();">
    <p class="name">Corporate Wellbeing</p>
    <button class="arrow-btn no-bg"><img loading="lazy" src="assets/images/booking/right-arrow.svg" alt="right arrow"></button>
  </a>
</div>