import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthAppService } from './auth-app/auth-app.service';

@Injectable({
  providedIn: 'root'
})
export class WellbeingGuard implements CanActivate {
  constructor(
    private auth: AuthAppService,
    private router: Router
  ) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.auth.getSavedUser()?.is_wellbeing_owner) {
      return true;
    }
    this.router.navigate(['']);
    return false;
  }
}
