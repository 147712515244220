<h2 class="main-head">Search for health & wellbeing <button class="arrow-btn" (click)="closeModal()"><img src="assets/images/close.svg" alt=""></button></h2>
<div class="search-box {{dropdownType}} {{searchType}}">
  <div class="search-input-box service field-type-1" [ngClass]="{'show': dropdownType === 'search'}">
    <button><img src="assets/images/search.svg" alt=""></button>
    <img (click)="selectOption('', 'service')" *ngIf="serviceText" class="clear-input" src="assets/images/close.svg" alt="">
    <div class="field-input" (click)="openDropdown('search', searchInputMob)">{{serviceText}}<span *ngIf="!serviceText" class="placeholder">Service, provider or symptom</span></div>
    <div class="search-service-pop">
      <div class="mobile-input">
        <button><img src="assets/images/search.svg" alt=""></button>
        <input #searchInputMob type="text" placeholder="Service, provider or symptom" [(ngModel)]="serviceText" (input)="searchInput()">
        <button (click)="openDropdown('')">Cancel</button>
      </div>
      <div class="search-service-wrapper for-service">
        <div class="popular-services" *ngIf="filteredPopularCategories().length > 0">
          <p class="services-title">Popular services</p>
          <ul>
            <ng-container *ngFor="let popular of filteredPopularCategories()">
              <li [innerHTML]="popular.name | highlight:serviceText" (click)="selectOption(popular.name, 'service')"></li>
            </ng-container>
          </ul>
        </div>
        <div class="more-services" *ngIf="filteredAllServices().length > 0">
          <p class="services-title">More services (a-z)</p>
          <ul>
            <ng-container *ngFor="let service of filteredAllServices()">
              <li [innerHTML]="service.name | highlight:serviceText" (click)="selectOption(service.name, 'service')"></li>
            </ng-container>
          </ul>
        </div>
        <div class="more-services" *ngIf="filteredBusinesses().length > 0">
          <p class="services-title">Wellbeing Businesses</p>
          <ul>
            <ng-container *ngFor="let biz of filteredBusinesses() | slice : 0 : 10">
              <li [innerHTML]="biz.name | highlight:serviceText" (click)="selectBizOption(biz, 'business', biz.slug)"></li>
            </ng-container>
          </ul>
        </div>
        <div class="more-services" *ngIf="filteredHealthConditions().length > 0">
          <p class="services-title">Health Conditions</p>
          <ul>
            <ng-container *ngFor="let health_condition of filteredHealthConditions() | slice : 0 : 10">
              <li [innerHTML]="health_condition.name | highlight:serviceText" (click)="selectOption(health_condition.name, 'health_condition')"></li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="search-input-box location field-type-1" *ngIf="searchType !== 'business'" [ngClass]="{'show': dropdownType === 'location'}">
    <button><img src="assets/images/pin.svg" alt=""></button>
    <div class="field-input" placeholder="Select a location" #locationInput (click)="openDropdown('location', locationMainInput)">{{locationText}}<span *ngIf="!locationText" class="placeholder">Select a location</span></div>
    <div class="search-service-pop">
      <div class="mobile-input">
        <button><img src="assets/images/pin.svg" alt=""></button>
        <input #locationMainInput type="text" id="location-input" placeholder="Select a location" (focus)="locationFocus()" (blur)="locationFocusOut($event)" (input)="onLocationTextChanged($event)">
        <button (click)="openDropdown('')">Cancel</button>
      </div>
      <div class="search-service-wrapper">
        <button class="current-location-btn" loading="lazy" (click)="getmeraLocation()"><img src="assets/images/map-location-arrow.svg" alt=""> USE MY CURRENT LOCATION</button>
        <ul>
          <ng-container *ngFor="let location of filteredLocationsList">
            <li (click)="selectLocation(location)">{{location.displayText}}</li>
          </ng-container>
        </ul>
        <div class="online-section">
          <p>Looking for online telehealth? Select the button below for online appointments.</p>
          <button class="online-btn" (click)="changeToOnline()"><img src="assets/images/video.svg" alt=""> ONLINE AND TELEHEALTH APPOINTMENTS</button>
        </div>
      </div>
    </div>
  </div>
  <div class="search-input-box calendar field-type-1" id="calendar-input-id">
    <button><img src="assets/images/calendar.svg" alt=""></button>
    <app-forms-datepicker [value]="selectedDate" [min]="todayDate" [hasIcon]="true" (changed)="dateChanged($event)"></app-forms-datepicker>
  </div>
  <p class="fetching-location" *ngIf="loading"><i class="fa fa-spin fa-spinner"></i> Fetching coordinates</p>
  <button class="main-search" [disabled]="loading" (click)="searchNow()">Search</button>
</div>
