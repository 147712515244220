import { Directive, ElementRef, HostListener, Injectable } from '@angular/core';

@Directive({
  selector: '[appAbnNumberMask]'
})
@Injectable({
  providedIn: 'root'
})
export class AbnNumberMaskDirective {
  previousValue = '';

  constructor(private el: ElementRef) {}

  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  @HostListener('input', ['$event'])
  onInput(event: KeyboardEvent): void {
    const input = this.el.nativeElement;
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    let value = input.value.replace(/\D/g, '').replace(/ /g, '');

    if (value.length > 11) {
      value = value.slice(0, 11);
    }
    value = value.replace(/(\d{2})(\d{3})(\d{3})(\d{3})?/, (match: any, p1: any, p2: any, p3: any, p4: any) => {
      return `${p1} ${p2} ${p3} ${p4 || ''}`.trim();
    });

    if (input.value !== value) {
      input.value = value;
      this.previousValue = value;
    }
  }
}
