import { GoogleLoginProvider, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  ShowOnDirtyErrorStateMatcher
} from '@angular/material/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Router } from '@angular/router';
import { AuthClientConfig, AuthGuard, AuthModule } from '@auth0/auth0-angular';
import * as Sentry from '@sentry/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SsrReplaceService } from './guards/ssr-replace.service';
import { AuthAppService } from './services/auth-app/auth-app.service';
import { BusinessMainService } from './services/business-main.service';
import { Constants } from './services/constants';
import { ErrorInterceptor } from './services/error-handler';
import { JWTAuthInterceptor } from './services/interceptors/jwt-auth-interceptor';
import { UserService } from './services/user.service';
import { CanonicalUrlPipe } from './shared/pipes/canonical-url.pipe';
import { SharedModule } from './shared/shared.module';

export const MY_FORMATS = {
  parse: { dateInput: 'DD/MM/YYYY' },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

function configInitializer(ssr: SsrReplaceService, config: AuthClientConfig) {
  return () => {
    const origin = ssr.getWindow()?.location.origin;
    console.info(`${origin}/login`);
    config.set({
      domain: Constants.AUTH0_CONFIG.domain,
      clientId: Constants.AUTH0_CONFIG.clientId,
      useRefreshTokens: true,
      authorizationParams: { redirect_uri: `${origin}/login` }
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    GoogleMapsModule,
    MatSnackBarModule,
    SharedModule,
    AuthModule.forRoot()
  ],
  providers: [
    AuthAppService,
    UserService,
    BusinessMainService,
    CanonicalUrlPipe,
    { provide: HTTP_INTERCEPTORS, useClass: JWTAuthInterceptor, multi: true },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'auto', appearance: 'outline' } },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '292507877877-isbutjmkpdg7762t6pvbd0f21v2c2r4b.apps.googleusercontent.com',
              { oneTapEnabled: false }
            )
          }
        ]
      } as SocialAuthServiceConfig
    },
    { provide: ErrorHandler, useClass: ErrorInterceptor },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: configInitializer,
      deps: [SsrReplaceService, AuthClientConfig],
      multi: true
    },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
