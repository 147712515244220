import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-forms-datepicker',
  templateUrl: './forms-datepicker.component.html',
  styleUrls: ['./forms-datepicker.component.scss']
})
export class FormsDatepickerComponent implements OnInit {
  dateValue = '';
  browserTest: any;
  minDate = '';

  constructor(
    private platform: Platform,
    @Inject(DOCUMENT) private dom: any
  ) {
    this.browserTest = platform;
  }

  @Input() set value(data: any) {
    this.dateValue = moment(data).format('YYYY-MM-DD');
  }

  @Input() set min(data: any) {
    this.minDate = data ? moment(data).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
  }

  @Input() max: string;

  @Input() placeholder = 'DD/MM/YYYY';

  @Output() changed = new EventEmitter();

  @Input() readonly = false;

  @Input() hasIcon = false;

  documentSize = 0;

  ngOnInit(): void {
    setTimeout(() => {
      this.onResize();
    }, 0);
  }

  @HostListener('window:resize')
  onResize() {
    this.documentSize = this.dom.getElementById('calendar-input-id')?.clientWidth || 0;
  }

  dateChanged(e: any) {
    this.changed.emit(e.value);
  }

  nativeDateChanged(e: any) {
    this.changed.emit(e.target.value);
  }
}
