import { HttpClient } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';

import { User } from '../models/user';
import { Constants } from '../services/constants';

import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private httpClient: HttpClient,
    private store: StoreService,
    private injector: Injector
  ) {
    this.errorHandler = this.injector.get(ErrorHandler);
  }

  private errorHandler: ErrorHandler;

  updatePassword(oldPassword: any, newPassword: any, confirmPassword: any): Observable<any> {
    const endPoint = '/change-password/';
    const body = { current_password: oldPassword, new_password: newPassword, confirm_password: confirmPassword };
    return this.httpClient.post(Constants.BASE_URL + endPoint, body).pipe(catchError(this.handleError.bind(this)));
  }

  addUser(user: User): Observable<any> {
    const endPoint = '/customer-sign-up/';
    return this.httpClient.post(Constants.BASE_URL + endPoint, user).pipe(catchError(this.handleError.bind(this)));
  }

  registerBusiness(data: any): Observable<any> {
    const endPoint = '/business-sign-up/';
    return this.httpClient.post(Constants.BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  activateAccount(slug: string, token: string, data: any): Observable<any> {
    const endPoint = '/activate-account-password/' + slug + '/' + token + '/';
    return this.httpClient.post(Constants.BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  createPracAccount(slug: string, token: string, data: any): Observable<any> {
    const endPoint = '/create-practitioner-password/' + slug + '/' + token + '/';
    return this.httpClient.post(Constants.BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
  login(params: any): Observable<any> {
    const endPoint = `/login/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.post(Constants.BASE_URL + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }

  getUserDetail(): Observable<any> {
    const endPoint = `/get-user-detail/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getDashboardBusinessDetail(): Observable<any> {
    const endPoint = `/get-dashboard-business-detail/?v=${this.store.getRandomNumber(true)}`;
    return this.httpClient.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  socialLogin(email: any, firstName: any, lastName: any, avatar: any, id: any, provider: any): Observable<any> {
    const endPoint = '/social-auth-login/';
    const body = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      avatar: avatar,
      u_id: id,
      provider: provider
    };
    return this.httpClient.post(Constants.BASE_URL + endPoint, body).pipe(catchError(this.handleError.bind(this)));
  }

  logout(): Observable<any> {
    const endPoint = '/logout/';
    return this.httpClient.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  resetPassword(email: string): Observable<any> {
    const endPoint = '/forgot-password/';
    const body = { email: email };
    return this.httpClient.post(Constants.BASE_URL + endPoint, body).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: any): Observable<never> {
    // Call the global error handler
    this.errorHandler.handleError(error);

    // Re-throw the error so it can be caught by the component's subscribe error handler
    return throwError(() => error);
  }
  validateBusinessABN(data: any): Observable<any> {
    const endPoint = '/abn-lookup/';
    return this.httpClient.post(Constants.WORKCOVER + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  createWorkcoverBusiness(data: any): Observable<any> {
    const endPoint = '/create-business/';
    return this.httpClient.post(Constants.WORKCOVER + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  updateWorkcoverServices(uuid: string, data: any): Observable<any> {
    const endPoint = `/add-business-categories/${uuid}/`;
    return this.httpClient.post(Constants.WORKCOVER + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  sendOTP(data: any): Observable<any> {
    const endPoint = '/send-otp/';
    return this.httpClient.post(Constants.WORKCOVER + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  verifyOTP(uuid: string, data: any): Observable<any> {
    const endPoint = `/verify-otp/${uuid}/`;
    return this.httpClient.post(Constants.WORKCOVER + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }
}
