<div class="saved-list-group">
  <div class="saved-list-header">
    <h2>My List</h2>
    <button class="arrow-btn" (click)="closeModal(false)"><img src="assets/images/close.svg" alt=""></button>
  </div>
  <div class="saved-list-body">
    <form class="fields-group" [formGroup]="saveListForm" (submit)="createList()">
      <div class="field-type-1">
        <label for="list_name" class="field-label">List Name</label>
        <input type="text" class="field-input" formControlName="list_name">
      </div>
      <div class="field-type-1">
        <label for="description" class="field-label">Description</label>
        <textarea class="field-input" formControlName="description"></textarea>
      </div>
      <button class="create-btn" [disabled]="saveListForm.invalid" type="submit">Create</button>
    </form>
    <div class="saved-list" *ngIf="savedList.length > 0">
      <h3>Available Lists</h3>
      <ul>
        <ng-container *ngFor="let item of savedList">
          <li>{{item.list_name}} - ({{item.items}} items) <button (click)="addItemToList(item.uuid)">Add to this</button></li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>



