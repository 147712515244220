import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slugText'
})
export class SlugTextPipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    const style = args[0];
    const reverse = args[1];
    const split_by = args[2] || ' ';
    let converted_text = value || '';
    if (style === 'capitalize') {
      const words = converted_text.split(split_by);
      const capitalizedWords = words.map((word) =>
        word !== 'and' ? word.charAt(0).toUpperCase() + word.slice(1) : word
      );
      converted_text = capitalizedWords.join(' ');
    } else if (style === 'uppercase') {
      converted_text = converted_text.toUpperCase();
    } else if (style === 'lowercase') {
      converted_text = converted_text.toLowerCase();
    }
    if (reverse === 'yes') {
      converted_text = converted_text.replace(/ /g, '-');
    } else {
      converted_text = converted_text.replace(/-/g, ' ');
    }
    return converted_text;
  }
}
