import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from 'avn/services/constants';
import { StoreService } from 'avn/services/store.service';
import { Observable, catchError, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileNewService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private store: StoreService,
    private injector: Injector
  ) {
    this.errorHandler = this.injector.get(ErrorHandler);
  }

  private errorHandler: ErrorHandler;

  redirectToLogin() {
    this.router.navigate(['/login'], { queryParams: { redirect: this.router.url } });
  }

  getProfile(): Observable<any> {
    const endPoint = `/profile/?v=${this.store.getRandomNumber(true)}`;
    return this.http.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  fetchCustomerBookings(): Observable<any> {
    const endPoint = `/customer-bookings/?v=${this.store.getRandomNumber(true)}`;
    return this.http.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  editProfile(data: any): Observable<any> {
    const endPoint = `/edit-profile/?v=${this.store.getRandomNumber(true)}`;
    return this.http.post(Constants.BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  cancelBooking(id: any): Observable<any> {
    const endPoint = '/cancel-appointment/' + id + '/';
    return this.http.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  appointmentRating(data: any): Observable<any> {
    const endPoint = '/add-appointment-review/';
    return this.http.post(Constants.BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  updatePassword(data: any): Observable<any> {
    const endPoint = '/change-password/';
    return this.http.post(Constants.BASE_URL + endPoint, data).pipe(catchError(this.handleError.bind(this)));
  }

  getWellbeingJourney(): Observable<any> {
    const endPoint = `wellbeing/personal-detail/?v=${this.store.getRandomNumber(true)}`;
    return this.http.get(Constants.BASE_URL_withoutAPI + endPoint);
  }

  getMyList(): Observable<any> {
    const endPoint = `/get-lists/?v=${this.store.getRandomNumber(true)}`;
    return this.http.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  updateMyList(uuid: string, list: any): Observable<any> {
    const endPoint = `/update-list/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.http.post(Constants.BASE_URL + endPoint, list).pipe(catchError(this.handleError.bind(this)));
  }

  getMyListDetail(uuid: string): Observable<any> {
    const endPoint = `/get-list-detail/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.http.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  createMyList(listData: any): Observable<any> {
    const endPoint = '/create-list/';
    return this.http.post(Constants.BASE_URL + endPoint, listData).pipe(catchError(this.handleError.bind(this)));
  }

  addToList(uuid: string, params: any): Observable<any> {
    const endPoint = `/add-to-list/${uuid}/`;
    return this.http.post(Constants.BASE_URL + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }

  deleteMyList(listId: any): Observable<any> {
    const endPoint = `/delete-list/${listId}/?v=${this.store.getRandomNumber(true)}`;
    return this.http.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  deleteMyListItem(uuid: any): Observable<any> {
    const endPoint = `/remove-from-list/${uuid}/?v=${this.store.getRandomNumber(true)}`;
    return this.http.get(Constants.BASE_URL + endPoint).pipe(catchError(this.handleError.bind(this)));
  }

  getBlogsPage(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .get(Constants.BLOG_POST_API_URL + '?per_page=3', { headers: headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  getCategoryById(id: number): Observable<any> {
    const endPoint = '/' + id;
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http
      .get(Constants.BLOG_CATEGORIES_API_URL + endPoint, { headers: headers })
      .pipe(catchError(this.handleError.bind(this)));
  }

  updateNotification(params: any): Observable<any> {
    const endPoint = '/update-notification-preferences/';
    return this.http.post(Constants.BASE_URL + endPoint, params).pipe(catchError(this.handleError.bind(this)));
  }

  private handleError(error: any): Observable<never> {
    // Call the global error handler
    this.errorHandler.handleError(error);

    // Re-throw the error so it can be caught by the component's subscribe error handler
    return throwError(() => error);
  }
}
