import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Location } from 'avn/interfaces/homepage';
import { ApiService } from 'avn/services/api.service';
import { StoreService } from 'avn/services/store.service';

import { MegaMenuService } from './mega-menu.service';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss']
})
export class MegaMenuComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<MegaMenuComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private api: MegaMenuService,
    private locService: ApiService,
    private store: StoreService,
    private router: Router
  ) {}

  citiesSlugs = [
    {
      name: 'Melbourne',
      slug: 'melbourne',
      post_code_short: '3',
      post_code: '3000'
    },
    {
      name: 'Sydney',
      slug: 'sydney',
      post_code_short: '2',
      post_code: '2000'
    },
    {
      name: 'Brisbane',
      slug: 'brisbane',
      post_code_short: '4',
      post_code: '4000'
    },
    {
      name: 'Perth',
      slug: 'perth',
      post_code_short: '6',
      post_code: '6000'
    },
    {
      name: 'Adelaide',
      slug: 'adelaide',
      post_code_short: '5',
      post_code: '5000'
    }
  ];

  loading = false;
  selectedMenu: 'wellbeing' | 'online' | 'health_condition' = 'wellbeing';
  selectedGroup = 'Physical';
  selectedService: any = {};
  headerCategories: any = {};
  healthConditionList: any[] = [];
  selectedCity: any = {};

  ngOnInit(): void {
    this.selectedMenu = this.data.menu;
    if (this.selectedMenu === 'wellbeing') {
      this.selectedGroup = 'Physical';
      this.fetchHeaderUrls();
    } else if (this.selectedMenu === 'online') {
      this.selectedGroup = 'Online';
      this.fetchHeaderUrls();
    } else if (this.selectedMenu === 'health_condition') {
      this.selectedGroup = 'Health Conditions';
      this.fetchHealthConditionList();
    }
  }

  fetchHeaderUrls() {
    if (Object.keys(this.store.headerCategories).length > 0) {
      this.headerCategories = this.store.headerCategories;
      this.setInitialSelections();
    } else {
      this.loading = true;
      this.api.getHeaderUrls().subscribe((response) => {
        this.loading = false;
        this.headerCategories = response;
        this.store.headerCategories = response;
        this.setInitialSelections();
      });
    }
  }

  setInitialSelections() {
    if (this.selectedMenu === 'wellbeing') {
      this.selectedService = this.headerCategories[this.selectedGroup][0];
      this.selectedCity = this.citiesSlugs[0];
    } else if (this.selectedMenu === 'health_condition') {
      this.selectedService = this.healthConditionList[0];
      this.selectedCity = this.citiesSlugs[0];
    }
  }

  fetchSuburbsByCity() {
    try {
      const category_item = this.headerCategories[this.selectedGroup].find(
        (x: any) => x.slug === this.selectedService.slug
      );
      const list = category_item.cities.find((x: any) => x.slug === this.selectedCity.slug).suburbs;

      return list.filter((x: any) => x.name.toLowerCase().replace(/ /g, '-') !== this.selectedCity.slug);
    } catch (error) {
      return [];
    }
  }

  fetchHCSuburbsByCity() {
    const list = this.selectedService?.suburb?.filter(
      (x: any) =>
        x.post_code.charAt(0) === this.selectedCity.post_code_short && x.post_code !== this.selectedCity.post_code
    );
    return list || [];
  }

  fetchHealthConditionList() {
    if (this.store.healthConditionList.length > 0) {
      this.healthConditionList = this.store.healthConditionList;
      this.setInitialSelections();
    } else {
      this.loading = true;
      this.api.getPopularHealthConditionList().subscribe((response) => {
        this.loading = false;
        this.healthConditionList = response.filter((x: any) => x.is_online === true);
        this.setInitialSelections();
      });
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  selectService(item: any) {
    if (this.selectedGroup === 'Online') {
      const url = `online/${item.slug}`;
      this.router.navigate([url]);
      this.closeModal();
    } else {
      this.selectedService = item;
    }
  }

  fetchServiceUrl(suburb: any) {
    const url = `/search/${this.selectedService.slug}/${suburb.slug}`;
    return [url];
  }

  isCityAvailable(city: string) {
    if (this.selectedMenu === 'health_condition') {
      return true;
    } else {
      const category = this.headerCategories[this.selectedGroup].find((x: any) => x.slug === this.selectedService.slug);
      if (category && category.cities) {
        const index = category.cities.findIndex((x: any) => x.slug === city);
        return index > -1;
      }
      return false;
    }
  }

  fetchHealthConditionUrl(suburb?: any, post_code?: any) {
    const item = this.selectedService?.name;
    if (item) {
      let url = `/category/${item.toLowerCase().replace(/ /g, '-')}`;
      if (suburb) {
        url += `/${suburb.toLowerCase().replace(/ /g, '-')}`;
      }
      if (post_code) {
        url += `/${post_code}`;
      }
      return [url];
    }
    return [];
  }

  replaceString(value: any) {
    return value.replace(/ /g, '-').toLowerCase();
  }

  updateLocation(city: any, state: any, post_code: any) {
    const loc = [city, state, post_code];
    this.getReverseGeoCodeByAddress(loc.join(', '));
  }

  getReverseGeoCodeByAddress(address: string) {
    const responseObs = this.locService.getGeoCodingByAddress(address);
    responseObs.subscribe({
      next: (response: any) => {
        let state = '';
        let city = '';
        try {
          let postcode = '';

          if (response.geocode_details.results[0].address_components) {
            for (let i = 0; i < response.geocode_details.results[0].address_components.length; i++) {
              const component = response.geocode_details.results[0].address_components[i];
              if (component.types[0] === 'postal_code') {
                postcode = component.long_name;
              } else if (component.types[0] === 'administrative_area_level_1') {
                state = component.long_name;
              } else if (
                component.types[0] === 'locality' ||
                (component.types.length > 2 && component.types[1] === 'locality') ||
                (component.types.length > 3 && component.types[2] === 'locality')
              )
                city = component.long_name;
            }
          }
          if (postcode === '') {
            postcode = this.setMajorCityAndSuburbInfo(city);
          }

          const loc = new Location();
          loc.lat = response.geocode_details.results[0].geometry.location.lat;
          loc.lng = response.geocode_details.results[0].geometry.location.lng;
          loc.post_code = postcode;
          loc.city_name = city;
          loc.state_name = state;
          localStorage.setItem('meraLocation', JSON.stringify(loc));
          this.store.setmeraLocation(loc);
        } catch (e) {
          console.info(e);
        }
      }
    });
  }

  setMajorCityAndSuburbInfo(suburb: string) {
    const mc = suburb.toLowerCase();
    if (mc === 'brisbane') {
      return '4000';
    } else if (mc === 'perth') {
      return '6000';
    } else if (mc === 'melbourne') {
      return '3000';
    } else if (mc === 'adelaide') {
      return '5000';
    } else if (mc === 'sydney') {
      return '2000';
    }
    return '';
  }

  setStateByPostCode(post_code: string) {
    if (post_code.charAt(0) === '2') {
      return 'New South Wales';
    } else if (post_code.charAt(0) === '3') {
      return 'Victoria';
    } else if (post_code.charAt(0) === '4') {
      return 'Queensland';
    } else if (post_code.charAt(0) === '5') {
      return 'South Australia';
    } else if (post_code.charAt(0) === '6') {
      return 'Western Australia';
    }
    return '';
  }

  setLocation(location: any) {
    const meraLocation = new Location();
    meraLocation.city_name = location.name;
    meraLocation.post_code = location.post_code;
    meraLocation.state_name = location.state || this.setStateByPostCode(location.post_code);
    this.locService
      .getGeoCodingByAddress(`${meraLocation.city_name}, ${meraLocation.state_name}, ${meraLocation.post_code}`)
      .subscribe((response: any) => {
        meraLocation.lat = response.geocode_details.results[0].geometry.location.lat;
        meraLocation.lng = response.geocode_details.results[0].geometry.location.lng;
        this.store.setmeraLocation(meraLocation);
        this.router.navigate([`/search/${this.selectedService.slug}/${location.slug}`]);
      });
    this.store.setmeraLocation(meraLocation);
  }
}
