import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { SsrReplaceService } from 'avn/guards/ssr-replace.service';
import { AuthAppService } from 'avn/services/auth-app/auth-app.service';
import { Constants } from 'avn/services/constants';

@Component({
  selector: 'app-auth-signin-check',
  templateUrl: './auth-signin-check.component.html',
  styleUrls: ['./auth-signin-check.component.scss']
})
export class AuthSigninCheckComponent {
  constructor(
    private auth: AuthService,
    private ssr: SsrReplaceService,
    private myAuth: AuthAppService
  ) {
    if (Constants.LOGIN_TYPE === 'auth0') {
      this.loginCheck();
    }
  }

  loginCheck(): void {
    this.auth.idTokenClaims$.subscribe((id: any) => {
      if (id.__raw) {
        console.info(id);
      }
    });
    this.auth.isAuthenticated$.subscribe((au: any) => {
      if (!au) {
        this.auth.loginWithRedirect();
      }
    });
  }

  signOut() {
    this.auth.logout({ logoutParams: { returnTo: this.ssr.getLocation().origin + '/' } });
  }
}
