import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentFormat'
})
export class MomentFormatPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    const format = args[0];
    const value_format = args[1];
    const dateString = new Date(value);
    if (value_format) {
      return moment(value, value_format).format(format);
    } else if (moment(dateString).isValid()) {
      return moment(dateString).format(format);
    }
    return value;
  }
}
