import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { BusinessGuard } from './guards/business.guard';
import { ProfileGaurd } from './guards/profile.guard';
import { WellbeingGuard } from './services/wellbeing.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then((mod) => mod.HomeModule)
  },
  {
    path: 'notfound',
    loadChildren: () => import('./shared/components/not-found/not-found.module').then((mod) => mod.NotFoundModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./modules/auth-check/sign-in/sign-in.module').then((mod) => mod.SignInModule)
  },
  {
    path: 'sign-up',
    loadChildren: () => import('./modules/auth-check/auth-check.module').then((mod) => mod.AuthCheckModule)
  },
  {
    path: 'business/provider/signup',
    loadChildren: () =>
      import('./modules/auth-check/business-sign-up/business-sign-up.module').then((mod) => mod.BusinessSignUpModule)
  },
  // {
  //   path: 'work-cover/sign-up',
  //   children: [
  //     {
  //       path: '',
  //       component: WorkcoverBusinessSignUpComponent
  //     },
  //     {
  //       path: ':business_id',
  //       component: WorkcoverOnboardingProcessComponent
  //     }
  //   ]
  // },
  {
    path: 'work-cover-signup/internal',
    loadChildren: () =>
      import('./modules/workcover-business-sign-up/workcover-business-sign-up.module').then(
        (mod) => mod.WorkcoverBusinessSignUpModule
      )
  },
  {
    path: 'work-cover/sign-up',
    loadChildren: () =>
      import('./modules/workcover-business-sign-up/workcover-business-sign-up.module').then(
        (mod) => mod.WorkcoverBusinessSignUpModule
      )
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./modules/auth-check/forgot-password/forgot-password.module').then((mod) => mod.ForgotPasswordModule)
  },
  {
    path: 'create-password/:slug/:token',
    loadChildren: () =>
      import('./modules/auth-check/create-password/create-password.module').then((mod) => mod.CreatePasswordModule)
  },
  {
    path: 'reset-password/:slug/:token',
    loadChildren: () =>
      import('./modules/auth-check/activate-account/activate-account.module').then((mod) => mod.ActivateAccountModule)
  },
  {
    path: 'workplacewellbeing',
    loadChildren: () =>
      import('./modules/workplace-wellbeing-site/workplace-wellbeing-site.module').then(
        (mod) => mod.WorkplaceWellbeingSiteModule
      )
  },
  {
    path: 'wellbeing',
    loadChildren: () =>
      import('./modules/wellbeing-dashboard/wellbeing-dashboard.module').then((mod) => mod.WellbeingDashboardModule),
    canActivate: [WellbeingGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile-new/profile-new.module').then((mod) => mod.ProfileNewModule),
    canActivate: [ProfileGaurd]
  },
  {
    path: 'business',
    loadChildren: () =>
      import('./modules/business-dashboard/business-dashboard.module').then((mod) => mod.BusinessDashboardModule),
    canActivate: [BusinessGuard]
  },
  {
    path: 'about-avaana',
    loadChildren: () => import('./modules/home/about-avaana/about.module').then((mod) => mod.AboutModule)
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./modules/terms/sitemap/sitemap.module').then((mod) => mod.SitemapModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then((mod) => mod.BlogModule)
  },
  {
    path: 'disclaimer',
    loadChildren: () => import('./modules/terms/disclaimer/disclaimer.module').then((mod) => mod.DisclaimerModule)
  },
  {
    path: 'collection-statements',
    loadChildren: () =>
      import('./modules/terms/collection-statements/collection-statements.module').then(
        (mod) => mod.CollectionStatementsModule
      )
  },
  {
    path: 'provider-terms',
    loadChildren: () =>
      import('./modules/terms/provider-terms/provider-terms.module').then((mod) => mod.ProviderTermsModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./modules/terms/privacy-policy/privacy-policy.module').then((mod) => mod.PrivacyPolicyModule)
  },
  {
    path: 'booking-terms',
    loadChildren: () =>
      import('./modules/terms/customer-terms/customer-terms.module').then((mod) => mod.CustomerTermsModule)
  },
  {
    path: 'community-guidelines',
    loadChildren: () =>
      import('./modules/terms/community-guidelines/community-guidelines.module').then(
        (mod) => mod.CommunityGuidelinesModule
      )
  },
  {
    path: 'physical',
    loadChildren: () => import('./modules/category/category.module').then((mod) => mod.CategoryModule)
  },
  {
    path: 'mental',
    loadChildren: () => import('./modules/category/category.module').then((mod) => mod.CategoryModule)
  },
  {
    path: 'natural',
    loadChildren: () => import('./modules/category/category.module').then((mod) => mod.CategoryModule)
  },
  {
    path: 'fitness',
    loadChildren: () => import('./modules/category/category.module').then((mod) => mod.CategoryModule)
  },
  {
    path: 'health-conditions',
    loadChildren: () => import('./modules/category/category.module').then((mod) => mod.CategoryModule)
  },
  {
    path: 'online/:service',
    data: {
      url_type: 'online'
    },
    loadChildren: () => import('./modules/service-list/service-list.module').then((mod) => mod.ServiceListModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./modules/booking-flow/booking-flow.module').then((mod) => mod.BookingFlowModule)
  },
  {
    path: 'checkout/:temp_slug',
    loadChildren: () =>
      import('./modules/booking-flow/booking-confirm/booking-confirm.module').then((mod) => mod.BookingConfirmModule)
  },
  {
    path: 'confirm/:appointment_id',
    loadChildren: () =>
      import('./modules/booking-flow/booking-review/booking-review.module').then((mod) => mod.BookingReviewModule)
  },
  {
    path: 'provider/:provider_name/:suburb',
    loadChildren: () => import('./modules/new-provider/new-provider.module').then((mod) => mod.ProviderModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/service-list/service-list.module').then((mod) => mod.ServiceListModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./modules/category/category.module').then((mod) => mod.CategoryModule)
  },
  { path: '**', redirectTo: '/notfound' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
