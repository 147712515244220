<!-- <input class="field-input" placeholder="DD/MM/YYYY" readonly [(ngModel)]="dateValue" (click)="datepicker.open()" [min]="min" [max]="max" (dateChange)="dateChanged($event)" [matDatepicker]="datepicker">
<mat-datepicker #datepicker></mat-datepicker> -->
<input
  *ngIf="browserTest.IOS || browserTest.ANDROID"
  [ngClass]="{ 'ios-platform': browserTest.IOS, 'android-platform': browserTest.ANDROID, 'has-icon': hasIcon }"
  [style.width.px]="documentSize"
  [style.height.px]="43"
  [readonly]="readonly"
  type="date"
  class="field-input native-input"
  [placeholder]="placeholder"
  [(ngModel)]="dateValue"
  (change)="nativeDateChanged($event)"
  [min]="minDate" />
<ng-container *ngIf="!browserTest.IOS && !browserTest.ANDROID">
  <input
    class="field-input"
    [ngClass]="{ 'has-icon': hasIcon }"
    readonly
    [disabled]="readonly"
    [placeholder]="placeholder"
    [(ngModel)]="dateValue"
    (click)="calender1.open()"
    (dateChange)="dateChanged($event)"
    [matDatepicker]="calender1"
    [min]="minDate" />
  <mat-datepicker #calender1></mat-datepicker>
</ng-container>
