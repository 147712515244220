export const environment = {
  production: false,
  apiUrl: 'https://dev.avaana.com.au/api',
  googleAuth: {
    CLIENT_ID: '495374583784-ncmrke8q11ojkg351llikujeqbpboucb.apps.googleusercontent.com',
    API_KEY: 'AIzaSyCbfv-NcSLHO0FJo90VA0lfR5kflxcpcso'
  },
  mapbox: {
    accessToken: 'pk.eyJ1Ijoia2FtYWwyNzMwIiwiYSI6ImNscjdld210MjIydnMya3BubmN5MG5hbmsifQ.6hr-rtyierLdnUWUK7C9sw',
    style: 'mapbox://styles/kamal2730/clywpcs48008001p6ar1xgizx'
  },
  loading: true,
  aws: {
    secretName: 'avaana-marketplace-hashkey-secretsmanager',
    accessKey: 'AKIAS6APILNA6B5LH2G7',
    secretAccessKey: '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'
  },
  login_encrypt: false,
  login_type: 'normal', //normal || auth0
  auth0: {
    domain: 'dev-7pagwz76jcbszsy3.au.auth0.com',
    clientId: 'BZx1tQ5tqGPqzh931u8vhg7K1NfGZq02',
    clientSecret: 'p-T6fELTU5oIfqXxgiuZn097YlrFc2grHEcT1YJie1R9NySFxpkxZR2OflPdMVBg'
  },
  intercom: {
    appId: 'tl9uvp8y',
    secretKey: 'Rv9dmgDL53AXEhR6HMj7bBIFt_Hkoek4Z7jJi_2-'
  }
};
