<div class="confirm-pop">
  <div class="confirm-head">
    <h2>{{confirmTitle}}</h2>
    <button (click)="cancelClick()" *ngIf="cancelType === 'icon'" class="arrow-btn"><img src="assets/images/close.svg" alt=""></button>
  </div>
  <div class="confirm-body">
    <p>{{confirmMessage}}</p>
  </div>
  <div class="confirm-footer">
    <button type="button" class="btn btn-cancel" (click)="cancelClick()" *ngIf="cancelType === 'button'">
      {{cancelButtonText}}
    </button>
    <button type="button" class="btn {{okClassName}}" (click)="okClick()">
      {{okButtonText}}
    </button>
    <ng-container *ngFor="let btn of secondaryButtons">
      <button type="button" class="btn {{btn.class_name}}" (click)="secondarybuttonClick(btn)">{{btn.name}}</button>
    </ng-container>
  </div>
</div>
