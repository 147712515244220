export const subcategory_data: any = {
  acupuncture: {
    moxibustion: {
      titleStr: 'Moxibustion - Find And Book Your First Session | Avaana',
      metaStr:
        'Do chronic aches and pains leave you feeling tired? Book a relaxing moxibustion session with the best acupuncture therapists near you via Avaana.'
    },
    guasha: {
      titleStr: 'Gua Sha Therapy - Book A Guasha Appointment Near You | Avaana',
      metaStr:
        'Gua Sha Therapy - Pamper your skin with a relaxing Gua Sha appointment with certified massage therapists. Book today and get 15% off on your first session.'
    }
  },
  ayurveda: {
    shirodhara: {
      titleStr: 'Shirodhara Massage - Enjoy 15% Off On Your First Shirodhara Session | Avaana',
      metaStr:
        'Shirodhara Massage Centre - Eliminate stress by booking a Shirodhara Session near you. Enjoy 15% off and other exciting deals on your first appointment via Avaana.'
    },
    abhyanga: {
      titleStr: 'Abhyanga Massage - Find And Book Abhyanga Massage With Ayurveda Professionals',
      metaStr:
        'Looking for ways to relax and unwind? Book an Abhyanga Massage session and connect with expert massage therapists near you.'
    }
  },
  counselling: {
    'cognitive-behavioral-therapy': {
      titleStr: 'Book Your First Cbt Session & Enjoy 15% Off | Avaana',
      metaStr:
        'Navigate and manage life’s challenges by booking your first Cognitive Therapy Session with leading therapists. Get 15% off on your first appointment.'
    },
    'couples-counselling': {
      titleStr: 'Couples Counselling Therapists Near You: Get 15% Off On First Session | Avaana',
      metaStr:
        'Wondering how to revive romance back? Book a Couples Counselling Session with certified therapists and get 15% off.'
    },
    'general-counselling': {
      titleStr: 'Enjoy 15% Off On Your First General Counselling Session | Avaana',
      metaStr:
        'Stress and anxiety crippling you? Connect with trusted counsellors and get 15% off on your first General Counselling Session.'
    },
    'neuro-linguistic-programming': {
      titleStr: 'Book an NLP Session With Leading Practitioners Near You',
      metaStr:
        'Unlock your true potential by booking your first Neuro-Linguistic Programming Session. Connect with leading practitioners and get exciting deals.'
    }
  },
  'group-fitness': {
    'hiit-group-training': {
      titleStr: 'Book Your First HIIT Group Training Class & Get 15 % Off | Avaana',
      metaStr:
        'Kick-start your fitness journey by signing up for HIIT Group Training Classes. Enjoy exciting deals on your first class.'
    },
    boxing: {
      titleStr: 'Get 15% Off On Booking Your First Boxing Class | Avaana',
      metaStr:
        'Channel your inner Muhammad Ali by signing up for Boxing Classes near you. Get a 15% Discount on your first class.'
    },
    'jiu-jitsu': {
      titleStr: 'Enjoy 15% Off On Jiu-Jitsu Classes Near You | Avaana',
      metaStr:
        'Learn Jiu-Jitsu and master the art of delf-defense. Find the best Jiu-Jitsu classes and get a 15% discount and other exciting deals.'
    },
    karate: {
      titleStr: 'Book Karate Classes Near You & Get 15% Off | Avaana',
      metaStr:
        'There is no right age to learn Karate. Book your first class now and get a 15% Welcome Discount with the best prices guaranteed.'
    },
    dance: {
      titleStr: 'Dance Class Near Me - Find & Join Best Adult Dance Class Now | Avaana',
      metaStr:
        'Dance Class Near Me - Find a list of best Dance Classes near you and get huge booking discounts on Avaana. Visit now and join your Dance Class today!'
    },
    kickboxing: {
      titleStr: 'Kickboxing Near Me - Find & Join Best Kickboxing Classes Now | Avaana',
      metaStr:
        'Kickboxing Near Me - Find a list of best Kickboxing classes near you and get huge booking discounts at Avaana. Visit now and join your Kickboxing Class today!'
    }
  },
  massage: {
    'aromatherapy-massage': {
      titleStr: 'Book Local Aromatherapists Near You At Appealing Rates | Avaana',
      metaStr:
        'Indulge your senses with Aromatherapy Massage Session. Connect with certified aromatherapists near you and get access to exciting deals.'
    },
    'ayurvedic-massage': {
      titleStr: 'Enjoy 15% Off On Your First Ayurvedic Massage Session | Avaana',
      metaStr:
        'Kiss shoulder and back pain goodbye by indulging in a relaxing Ayurvedic Massage. Book to get 15% off on your first session.'
    },
    'chinese-massage': {
      titleStr: 'Grab Exciting Deals On First Chinese Massage Near You | Avaana',
      metaStr:
        'Reap the therapeutic benefits of Chinese Massage. Book your first Session now to get a 15% Welcome Discount.'
    },
    'deep-tissue-massage': {
      titleStr: 'Find Deep Tissue Massage Providers At Discounted Rates | Avaana',
      metaStr:
        'Relax your body, mind, and soul with a Deep Tissue Massage. Book your first session with Local Massage Therapists and enjoy 15% off.'
    },
    'pregnancy-massage': {
      titleStr: 'Book Local Pregnancy massage Near You At Appealing Rates | Avaana',
      metaStr:
        'Indulge your senses with Pregnancy Massage Session. Book your first Session now to get a 15% Welcome Discount.'
    },
    'couples-massage': {
      titleStr: 'Get Couples Massage At Massage Therapists Near You | Avaana',
      metaStr:
        'It’s time for you and your better half to unwind together. Schedule a relaxing Couple Massage Session today and get a 15% off.'
    },
    'cupping-therapy': {
      titleStr: 'Book Your First Cupping Therapy Session & Get 15% Off | Avaana',
      metaStr:
        'From Michael Phelps to your friendly neighbour, everyone swears by the benefits of Cupping Therapy. Book your first session and get 15% off.'
    },
    'foot-massage-reflexology': {
      titleStr: 'Get 15% Off On Your First Foot Reflexology Session | Avaana',
      metaStr:
        'Your feet go through a lot every day. Give them some much-needed love by booking a Foot Reflexology Session and enjoy 15% off on your first appointment.'
    },
    'lymphatic-drainage-massage': {
      titleStr: 'Enjoy 15% Off On Your First Lymphatic Drainage Massage | Avaana',
      metaStr:
        'Suffering from weak immunity, fatigue, and muscle soreness? Book a Lymphatic Drainage Massage near you & get 15% off on the first session'
    },
    'thai-massage': {
      titleStr: 'Connect With Thai Massage Providers Near You | Avaana',
      metaStr:
        'Looking for Professional Thai Massage Therapists? Find experienced practitioners near you and enjoy 15% off on your first session'
    },
    'hot-stone-massage': {
      titleStr: 'Hot Stone Massage Near Me - Book the Best Hot Stone Massage Now | Avaana',
      metaStr:
        'Hot Stone Massage Near Me - Relax and ease your tensed muscles throughout the body with the Hot Stone Massage from the best therapist near you. Book Hot Stone Massage now on Avaana!'
    },
    'remedial-massage': {
      titleStr: 'Remedial Massage Near Me - Book the Best Remedial Massage Now | Avaana',
      metaStr:
        'Remedial Massage Near Me - Stimulate the blood supply and repair damaged tissues with the Remedial Massage from the best therapist near you. Book Remedial Massage now on Avaana!'
    },
    'sports-massage': {
      titleStr: 'Sports Massage Near Me - Book the Best Sports Massage Now | Avaana',
      metaStr:
        'Sports Massage Near Me - Increase flexibility, range of motion and decrease muscle tension with the sports massage from the best therapist near you. Book Sports Massage now on Avaana!'
    }
  },
  myotherapy: {
    'dry-needling': {
      titleStr: 'Explore The Best Dry Needling Providers Near You | Avaana',
      metaStr:
        'Experience the remarkable health benefits of Dry Needling. Find affordable practitioners near you and get 15 % off on the first session'
    }
  },

  naturopathy: {
    'crystal-healing': {
      titleStr: 'Enjoy 15% Off On Your First Crystal Healing Session | Avaana',
      metaStr:
        'Release energy blockages and eliminate fear with Crystal Healing. Connect with the healers near you and get 15% off on your first session.'
    }
  },
  pilates: {
    'clinical-pilates': {
      titleStr: 'Find Clinical Pilates Instructors Online | Avaana',
      metaStr:
        'Bad posture invites a host of physical ailments. Rectify them by connecting with certified Clinical Pilates Instructors near you. Book now'
    }
  },
  reiki: {
    'energy-healing': {
      titleStr: 'Get An Energy Healing Session At 15% Off | Avaana ',
      metaStr:
        'Feeling out of your element? Relieve blocked energies at the nearest healing centre near you. Book your first appointment at discounted price'
    }
  },
  yoga: {
    'ashtanga-yoga': {
      titleStr: 'Ashtanga Yoga Session - Book Your First Ashtanga Yoga Class | Avaana',
      metaStr:
        'Want to make your body more flexible? Ashtanga Yoga offers more energetic approach. Book an online class and enjoy 15% off'
    },
    'bikram-yoga': {
      titleStr: 'Bikram Yoga Session - Find Bikram Yoga Class With Expert Instructors | Avaana',
      metaStr:
        'Bikram Yoga Classes - Looking for a full-body workout without a gym? Try Bikram Yoga targeting body muscles. Book your session and get 15% off'
    },
    'hatha-yoga': {
      titleStr: 'Hatha Yoga - Book Hatha Yoga Classes And Enjoy 15% Off | Avaana',
      metaStr:
        'Hatha Yoga Session - Do you have tensed muscles and a strained body? Try Hatha Yoga to relieve stiffness. Browse Avaana and find a class near you'
    },
    'hot-yoga': {
      titleStr: 'Hot Yoga Sessions - Find And Book Your First Hot Yoga Session At 15% | Avaana',
      metaStr:
        'Hot Yoga Classes - Looking to burn calories with regulated movements? Try a Hot Yoga session and get a 15% discount on your first class'
    },
    'iyengar-yoga': {
      titleStr: 'Ivengar Yoga - Enjoy 15% Off On Your First Ivengar Yoga Class | Avaana',
      metaStr:
        'Ivengar Yoga Classes - Get in shape and relieve stress with beginner-friendly Iyengar Yoga. Find certified Yoga teachers for clear demonstrations and support'
    }
  },
  'martial-arts': {
    judo: {
      titleStr: 'Judo Near Me - Find & Join Best Judo Classes Now | Avaana',
      metaStr:
        'Judo Near Me - Find the list of best Judo classes near you and get huge booking discounts at Avaana. Visit now and join your Judo Class today!'
    }
  }
};
