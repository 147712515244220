<div class="mega-menu-wrapper">
  <div class="mega-menu-header">
    <h1 *ngIf="selectedMenu === 'wellbeing'">Wellbeing Services</h1>
    <h1 *ngIf="selectedMenu === 'online'">Online Consults</h1>
    <h1 *ngIf="selectedMenu === 'health_condition'">Health Conditions</h1>
    <button class="arrow-btn" (click)="closeModal()"><img src="assets/images/close.svg" alt=""></button>
  </div>
  <div class="mega-menu-body">
    <div class="m-m-body-header">
      <ul>
        <ng-container *ngIf="selectedMenu === 'wellbeing'">
          <li (click)="closeModal()" (mouseover)="selectedGroup = 'Physical'; setInitialSelections()" [ngClass]="{'active': selectedGroup === 'Physical'}">
            <a routerLink="/physical">Physical</a>
          </li>
          <li (click)="closeModal()" (mouseover)="selectedGroup = 'Mental'; setInitialSelections()" [ngClass]="{'active': selectedGroup === 'Mental'}">
            <a routerLink="/mental">Mental</a>
          </li>
          <li (click)="closeModal()" (mouseover)="selectedGroup = 'Natural'; setInitialSelections()" [ngClass]="{'active': selectedGroup === 'Natural'}">
            <a routerLink="/natural">Natural</a>
          </li>
          <li (click)="closeModal()" (mouseover)="selectedGroup = 'Fitness'; setInitialSelections()" [ngClass]="{'active': selectedGroup === 'Fitness'}">
            <a routerLink="/fitness">Fitness</a>
          </li>
        </ng-container>
        <ng-container *ngIf="selectedMenu === 'online'">
          <li class="active">
            <a>Online</a>
          </li>
        </ng-container>
        <ng-container *ngIf="selectedMenu === 'health_condition'">
          <li class="active">
            <a>Health Conditions</a>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="loading-view" *ngIf="loading">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <ng-container *ngIf="!loading">
      <div class="m-m-body-list divide" *ngIf="selectedMenu !== 'health_condition' && selectedMenu !== 'online'">
        <div class="categories-list">
          <ul class="categories-ul show">
            <ng-container *ngFor="let item of headerCategories[selectedGroup]">
              <li [ngClass]="{'active': selectedService?.slug === item.slug, 'online-li': selectedGroup === 'Online'}">
                <a [routerLink]="'/category/'+ item.slug" (click)="closeModal()" (mouseover)="selectService(item)">{{item.name}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="suburbs-list">
          <ul class="cities-list">
            <ng-container *ngFor="let city_slug of citiesSlugs">
              <li [ngClass]="{'active': city_slug.slug === selectedCity.slug}" *ngIf="isCityAvailable(city_slug.slug)">
                <a [routerLink]="'/category/'+ selectedService.slug + '/' + selectedCity.slug" (click)="closeModal()" (mouseover)="selectedCity = city_slug">{{city_slug.name}}</a>
              </li>
            </ng-container>
          </ul>
          <ul class="suburb-item-list">
              <ng-container *ngFor="let suburb of fetchSuburbsByCity()">
                <li>
                  <a (click)="setLocation(suburb); closeModal()">{{suburb.name}}</a>
                </li>
              </ng-container>
            </ul>
        </div>
      </div>
      <div class="m-m-body-list online" *ngIf="selectedMenu === 'online'">
        <ul class="show">
          <ng-container *ngFor="let item of headerCategories[selectedGroup]">
            <li class="online-li" [ngClass]="{'active': selectedService?.slug === item.slug}">
              <a (click)="selectService(item);closeModal()">{{item.name}}</a>
            </li>
          </ng-container>
        </ul>
      </div>
      <div class="m-m-body-list divide" *ngIf="selectedMenu === 'health_condition'">
        <div class="categories-list">
          <ul class="categories-ul show">
            <ng-container *ngFor="let item of healthConditionList">
              <li [ngClass]="{'active': selectedService?.id === item.id}">
                <a [routerLink]="'/category/' + replaceString(item.name)" (click)="closeModal()" (mouseover)="selectService(item)">{{item.name}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
        <div class="suburbs-list">
         <ul class="cities-list">
            <ng-container *ngFor="let city_slug of citiesSlugs">
              <li [ngClass]="{'active': city_slug.slug === selectedCity.slug}" *ngIf="isCityAvailable(city_slug.slug)">
                <a routerLink="{{fetchHealthConditionUrl(selectedCity.slug)}}" (click)="closeModal()" (mouseover)="selectedCity = city_slug">{{city_slug.name}}</a>
              </li>
            </ng-container>
          </ul>
           <ul class="suburb-item-list">
            <ng-container *ngFor="let suburb of fetchHCSuburbsByCity()">
              <li>
                <a (click)="closeModal()" routerLink="{{fetchHealthConditionUrl(suburb.city, suburb.post_code)}}">{{suburb.city}}</a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
</div>
