<ng-container *ngIf="!checkIsSearchPage(); else searchPageViewTemplate">
  <div class="header" id="main-content-id" [ngClass]="{'dashboard': isDashboard}">
    <div class="header-wrapper">
      <a class="logo-place" [routerLink]="''">
        <img src="assets/images/avaana-logo.svg" alt="" width="100" height="auto" class="logo-img" aria-label="Avaana">
      </a>
      <div class="menu-group">
        <ul class="megamenu">
          <li><a (click)="openMainMenu('wellbeing')">Wellbeing Services</a></li>
          <li><a (click)="openMainMenu('online')">Online Consults</a></li>
          <li><a (click)="openMainMenu('health_condition')">Health Conditions</a></li>
          <li><a [routerLink]="['/blog']">Health Hub</a></li>
        </ul>
        <div class="side-menus">
          <button class="notification-btn" (click)="openNotificationMenu()" *ngIf="checkNotifications()">
            <img src="assets/images/bell.svg" alt="" class="notification-bell">
            <span *ngIf="notificationCount">{{notificationCount}}</span>
          </button>
          <button class="arrow-btn" aria-label="Menu" (click)="openMobileMainMenu('')"><img src="assets/images/hamburger.svg" alt=""></button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #searchPageViewTemplate>
  <div class="header for-search" id="main-content-id">
    <div class="header-wrapper">
      <a class="logo-place" [routerLink]="''">
        <img src="assets/images/avaana-logo.svg" alt="" width="100" height="auto" class="logo-img" aria-label="Avaana">
      </a>
      <div class="menu-group">
        <app-search-box isHeader="true" class="s-box" [label]="'city_name'" [viewType]="'mobile'" [showBack]="true"></app-search-box>
      </div>
      <!-- <button class="arrow-btn" *ngIf="!isServicePage" aria-label="Menu" (click)="openMobileMainMenu('')"><img src="assets/images/hamburger.svg" alt=""></button> -->
      <div class="side-menus" [ngClass]="{'only-search': checkIsOnlySearchPage()}">
        <button class="notification-btn" (click)="openNotificationMenu()" *ngIf="checkNotifications()">
          <img src="assets/images/bell.svg" alt="" class="notification-bell">
          <span>{{notificationCount}}</span>
        </button>
        <button class="arrow-btn" aria-label="Menu" (click)="openMobileMainMenu('')"><img src="assets/images/hamburger.svg" alt=""></button>
      </div>
    </div>
  </div>
</ng-template>
