import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
export class ConfirmPopData {
  title = 'Confirm';
  message = 'Are you sure?';
  cancelButtonText = 'Cancel';
  okButtonText = 'Confirm';
  okClassName: 'btn-success' | 'btn-warning' | 'btn-error' = 'btn-success';
  secondaryButtons?: { name: string; class_name: string; value: string }[] = [];
  cancelType: 'button' | 'icon' = 'button';
}

@Component({
  selector: 'app-confirm-pop',
  templateUrl: './confirm-pop.component.html',
  styleUrls: ['./confirm-pop.component.scss']
})
export class ConfirmPopComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmPopComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmPopData
  ) {}

  confirmTitle: string;
  confirmMessage: string;
  okButtonText: string;
  cancelButtonText: string;
  okClassName: string;
  secondaryButtons: { name: string; class_name: string; value: string }[] = [];
  cancelType: 'button' | 'icon' = 'button';

  ngOnInit(): void {
    this.confirmTitle = this.data.title || 'Confirm';
    this.confirmMessage = this.data.message || 'Are you sure?';
    this.cancelButtonText = this.data.cancelButtonText || 'Cancel';
    this.okButtonText = this.data.okButtonText || 'Confirm';
    this.okClassName = this.data.okClassName || 'btn-success';
    this.secondaryButtons = ((this.data.secondaryButtons || [])?.length === 0 ? [] : this.data.secondaryButtons) || [];
    this.cancelType = this.data.cancelType || 'button';
  }

  cancelClick() {
    this.dialogRef.close(false);
  }

  okClick() {
    this.dialogRef.close(true);
  }

  secondarybuttonClick(btn: any) {
    this.dialogRef.close(btn.value);
  }
}
