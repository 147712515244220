export interface TimezoneInfo {
  utc: string;
  full_name: string;
  short_name: string;
  timezone: string;
  offset: string;
}

export class timezoneConstants {
  public static get timezones(): TimezoneInfo[] {
    return [
      {
        utc: 'UTC +8:00',
        full_name: 'Australian Western Standard Time',
        short_name: 'AWST',
        timezone: 'Australia/Perth',
        offset: '+08:00'
      },
      {
        utc: 'UTC +9:30',
        full_name: 'Australian Central Standard Time',
        short_name: 'ACST',
        timezone: 'Australia/Adelaide',
        offset: '+09:30'
      },
      {
        utc: 'UTC +10:00',
        full_name: 'Australian Eastern Standard Time',
        short_name: 'AEST',
        timezone: 'Australia/Sydney',
        offset: '+10:00'
      },
      {
        utc: 'UTC +11:00',
        full_name: 'Australian Eastern Standard Time',
        short_name: 'AEST',
        timezone: 'Australia/Melbourne',
        offset: '+11:00'
      }
    ];
  }

  public static get timezoneToStatesMap(): { [timezone: string]: string[] } {
    return {
      AEST: [
        'New South Wales',
        'Victoria',
        'Queensland',
        'Tasmania',
        'Australian Capital Territory',
        'NSW',
        'TAS',
        'VIC',
        'QLD',
        'ACT'
      ],
      ACST: ['South Australia', 'Northern Territory', 'SA', 'NT'],
      AWST: ['Western Australia', 'WA'],
      LHST: ['Lord Howe Island', 'LHI'],
      NFT: ['Norfolk Island', 'NI'],
      CXT: ['Christmas Island', 'CI'],
      CCT: ['Cocos (Keeling) Islands', 'CKI']
    };
  }
}
