import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DomHelperService {
  private renderer: Renderer2;
  isBrowser = false;
  constructor(
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    // Create an instance of Renderer2
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.isBrowser = isPlatformBrowser(platformId);
  }

  loadScript(scriptUrl: string): void {
    // Check if the script already exists in the document
    const existingScript = this.document.querySelector(`script[src="${scriptUrl}"]`);
    if (!existingScript && this.isBrowser) {
      // Create and append the script element
      const script = this.renderer.createElement('script');
      script.src = scriptUrl;
      script.type = 'text/javascript';
      script.async = true;
      this.renderer.appendChild(this.document.body, script);
    }
  }

  loadStyle(cssUrl: string): void {
    const existingLink = this.document.querySelector(`link[href="${cssUrl}"]`);
    if (!existingLink && this.isBrowser) {
      const link = this.renderer.createElement('link');
      link.rel = 'stylesheet';
      link.href = cssUrl;
      this.renderer.appendChild(this.document.head, link);
    }
  }
}
