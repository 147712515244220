import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, textToHighlight: string, cssClass = 'highlighted'): string {
    if (!textToHighlight) {
      return value;
    }

    const pattern = new RegExp(textToHighlight, 'gi');
    return value.replace(pattern, `<span class="${cssClass}">$&</span>`);
  }
}
