import { Injectable } from '@angular/core';

@Injectable()
export class SchemaService {
  getHomeSchema() {
    const jsonld = {
      '@context': 'schema.org',
      '@type': 'Organization',
      name: 'Avaana Pty Ltd',
      url: 'https://avaana.com.au/',
      logo: 'https://avaana.com.au/static/image/Group%2023.png',
      brand: 'Avaana',
      contactPoint: {
        '@type': 'ContactPoint',
        email: 'hello@avaana.com.au'
      },
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Suite 5, 20 Cato Street',
        addressLocality: 'Hawthorn East',
        addressRegion: 'Victoria',
        postalCode: '3122',
        addressCountry: 'Australia'
      },
      founders: {
        '@type': 'Person',
        name: 'Rohan Pardasani'
      },

      sameAs: [
        'https://au.linkedin.com/company/avaana',
        'https://www.facebook.com/avaanawellbeing',
        'https://www.instagram.com/avaanawellbeing'
      ]
    };

    return jsonld;
  }

  getBlogSchema() {
    let jsonld = {};
    try {
      jsonld = {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        name: 'Avaana',
        url: 'https://avaana.com.au/',
        potentialAction: {
          '@type': 'Blog',
          target: 'https://avaana.com.au/blog'
        }
      };
    } catch (e) {
      // console.log ('exception in super category schema ='+e);
    }

    return jsonld;
  }

  getSuperCategorySchema(serviceName: string, imgPath: string | null) {
    let jsonld = {};
    try {
      jsonld = {
        '@context': 'https://schema.org/',
        '@type': 'BreadCrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: '1',
            item: {
              '@id': 'https://avaana.com.au/',
              name: 'Home',
              image: 'https://avaana.com.au/static/image/Group%2023.png'
            }
          },
          {
            '@type': 'ListItem',
            position: '2',
            item: {
              '@id': 'https://avaana.com.au/' + serviceName,
              name: serviceName,
              image: 'https://avaana.com.au/' + imgPath
            }
          }
        ]
      };
    } catch (e) {
      // console.log ('exception in super category schema ='+e);
    }

    return jsonld;
  }

  getSearchSiteLinkSchema() {
    let jsonld = {};
    try {
      jsonld = {
        '@context': 'https://schema.org/',
        '@type': 'WebSite',
        name: 'Avaana',
        url: 'https://avaana.com.au/',
        potentialAction: {
          '@type': 'SearchAction',
          target: 'https://avaana.com.au/service/' + '{search_term_string}' + '/melbourne/',
          'query-input': 'required name=search_term_string'
        }
      };
    } catch (e) {
      // console.log ('exception in super category schema ='+e);
    }

    return jsonld;
  }

  getHomeSiteNavigationLink() {
    let jsonld = {};
    try {
      jsonld = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: [
          {
            '@type': 'SiteNavigationElement',
            position: 1,
            name: 'Chiropractors Near Me: Book Your First Appointment Now | Avaana',
            description:
              'Looking for trusted Chiropractors? Connect with the best Chiropractic Clinics in your area via Avaana. Get discounts, appealing deals, and more.',
            url: 'https://avaana.com.au/chiropractic'
          },
          {
            '@type': 'SiteNavigationElement',
            position: 2,
            name: 'Relaxation and Remedial Massages Near Me  | Avaana',
            description:
              'Want to indulge in a relaxing massage? Book an appointment at a nearby day spa or massage clinic via Avaana. Get 15% off with guaranteed best prices.',
            url: 'https://avaana.com.au/massage'
          },
          {
            '@type': 'SiteNavigationElement',
            position: 3,
            name: 'Physiotherapy - Connect With Certified Physiotherapists Near You | Avaana',
            description:
              'Physio Near Me - Restore your body’s mobility and overall well-being with Physiotherapy. Schedule an appointment with certified Physiotherapists via Avaana.',
            url: 'https://avaana.com.au/physiotherapy'
          },
          {
            '@type': 'SiteNavigationElement',
            position: 4,
            name: 'Best Pilates Classes Near You | Avaana',
            description:
              'Celebrities swear by it, and so do we! Book a Pilates Class to build muscle strength and lose those extra kilos. Get discounts on your first session',
            url: 'https://avaana.com.au/pilates'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 5,
            name: 'Personal Training - Book Personal Trainers In Your Area | Avaana',
            description:
              'Personal Trainer Near Me - Struggling with your fitness goals? Appoint a Personal Trainer via Avaana. Find the best Gym Trainers with guaranteed price.',
            url: 'https://avaana.com.au/personal-training'
          },
          {
            '@type': 'SiteNavigationElement',
            position: 6,
            name: 'Schedule A Myotherapy Session Near You | Avaana',
            description:
              'Bid adieu to chronic aches and pains with Myotherapy. Get 20% off on your first session and the best prices on certified Myotherapists near you',
            url: 'https://avaana.com.au/myotherapy'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 7,
            name: 'Schedule A Session With a Psychologist | Avaana',
            description:
              'Do not ignore your mental health! Book an appointment for eTherapy and get treated with interactive counselling',
            url: 'https://avaana.com.au/psychology'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 8,
            name: 'Yoga Classes - Find Yoga Studios Near You | Avaana',
            description:
              'Yoga Centre - Heal your mind, body, and spirit with Yoga. Connect with the best Yoga instructors near you to stay fit mentally and physically.',
            url: 'https://avaana.com.au/yoga'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 9,
            name: 'Ayurveda Wellness Center: Get Great Discounts On Ayurveda Services Near You | Avaana',
            description:
              'Ayurvedic Clinic - Ayurveda heals your mind, body, and spirit. Book an Ayurveda Provider via Avaana and get access to different treatments at affordable rates',
            url: 'https://avaana.com.au/ayurveda'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 10,
            name: 'Counselling Near Me - Find And Book The Best Counsellors Near You | Avaana',
            description:
              'Seeking help for deteriorating mental health? Book a Counselling Session now via Avaana and get access to the best Counsellors.',
            url: 'https://avaana.com.au/counselling'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 11,
            name: 'Osteopathy Near Me - Connect With The Best Osteopaths | Avaana',
            description:
              'Experiencing low body energy? Schedule an appointment with your nearest Osteopath via Avaana. Get flat 10% off on your first session',
            url: 'https://avaana.com.au/osteopathy'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 12,
            name: 'Find A Reiki Practitioner Near You | Avaana',
            description: 'Relax your mind and body with a rejuvenating energy therapy. Book a Reiki Session via Avaana',
            url: 'https://avaana.com.au/reiki'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 13,
            name: 'Explore Podiatry Clinics In Your Area | Avaana',
            description:
              'Feet problems hint toward underlying health issues. Don’t neglect them and book an appointment with your nearest Podiatry Clinic now',
            url: 'https://avaana.com.au/podiatry'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 14,
            name: 'Acupuncture Clinic - Find & Book Acupuncturists Near You | Avaana',
            description:
              'Looking for trusted Acupuncturists at affordable prices? Connect with the best acupuncture clinics via Avaana and schedule an appointment. Book Now!',
            url: 'https://avaana.com.au/acupuncture'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 15,
            name: 'Naturopathy Consultant - Get Access To Certified Naturopaths Near You | Avaana',
            description:
              'Looking for Certified Naturopaths in your area? Connect with the best via Avanna and enjoy 10% off on your first appointment.',
            url: 'https://avaana.com.au/naturopathy'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 16,
            name: 'Find The Best Homoeopaths near you | Avaana',
            description:
              'Want to maximise Homeopathy sessions? Schedule an appointment with the best Homeopaths near you and get access to appealing discounts',
            url: 'https://avaana.com.au/homeopathy'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 17,
            name: 'Book A Reflexology Session Near You | Avaana',
            description:
              "Feeling stressed? Fix it by restoring your body's balance with experienced Reflexologists. Find and book your first appointment",
            url: 'https://avaana.com.au/reflexology'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 18,
            name: 'Find The Best Meditation Classes Near You | Avaana',
            description:
              'Allow meditation to transform your life. Book your first Meditation Class and Get 20% off. Explore the best Mediation Centres in your area',
            url: 'https://avaana.com.au/meditation'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 19,
            name: 'Book Life Coaches Online Or Near You | Avaana',
            description:
              'Want to live life according to your terms? Allow a Life Coach to guide you. Book certified Life Coaches via Avaana.',
            url: 'https://avaana.com.au/life-coaching'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 20,
            name: 'Connect With Group Fitness Providers Nearby | Avaana',
            description:
              'Having an exercise buddy helps! Book a Group Fitness Class with your friend via Avaana. Avail of exciting discounts and access to different classes.',
            url: 'https://avaana.com.au/group-fitness'
          },
          {
            '@type': 'SiteNavigationElement',
            position: 21,
            name: 'Find an appropriate Exercise Physiology Sessions | Avaana',
            description:
              'Experiencing constant chronic pain, illnesses, and other physical ailments? Connect with the best and affordable practitioners via Avaana.',
            url: 'https://avaana.com.au/exercise-physiology'
          },

          {
            '@type': 'SiteNavigationElement',
            position: 22,
            name: 'List Your Business on Avaana',
            description:
              'Aussies seeking Health and Wellbeing services in your area can find and book appointments with your business through Avaana in real-time.',
            url: 'https://listyourbusiness.avaana.com.au/'
          },
          {
            '@type': 'SiteNavigationElement',
            position: 23,
            name: 'Avaana Answers | Content to help your health, fitness and wellbeing journey today',
            description: 'Content to help your health, fitness and wellbeing journey today',
            url: 'https://avaana.com.au/blog'
          },
          {
            '@type': 'SiteNavigationElement',
            position: 24,
            name: 'Continue your health & wellbeing journey, anywhere you go.',
            description: 'Book online anytime with our app and get discounts , cashback on first purchase',
            url: 'https://apps.apple.com/au/app/avaana/id1540739725?ign-itsct=apps_box&ign-itscg=30200'
          }
        ]
      };
    } catch (e) {
      // console.log ('exception in super category schema ='+e);
    }

    return jsonld;
  }

  getMajorCityForSuburb(postcode: string) {
    let majorCity;
    if (postcode.indexOf('2') === 0) {
      majorCity = 'Sydney';
    } else if (postcode.indexOf('3') === 0) {
      majorCity = 'Melbourne';
    } else if (postcode.indexOf('4') === 0) {
      majorCity = 'Brisbane';
    } else if (postcode.indexOf('5') === 0) {
      majorCity = 'Adelaide';
    } else if (postcode.indexOf('6') === 0) {
      majorCity = 'Perth';
    }
    return majorCity;
  }

  getSearchSchema(
    service: string,
    serviceName: string,
    serviceLink: string,
    majorCityName: any,
    suburbName: string,
    postCode: string,
    imgPath: string
  ) {
    let jsonld = {};
    try {
      const breadCrumbList = [];
      if (majorCityName === null) {
        majorCityName = this.getMajorCityForSuburb(postCode);
      }
      if (imgPath === null) {
        imgPath = 'https://avaana.com.au/static/image/Group%2023.png';
      }
      const majorCityLink = 'https://avaana.com.au/' + service + '/' + majorCityName.toLowerCase() + '/';
      let suburbLink = '';
      if (suburbName) {
        const suburb = suburbName.split(' ').join('-').toLowerCase();
        suburbLink = 'https://avaana.com.au/' + service + '/' + suburb + '/' + postCode;
      }
      const blist1 = {
        '@type': 'ListItem',
        position: '1',
        item: {
          '@id': 'https://avaana.com.au/',
          name: 'Home',
          image: 'https://avaana.com.au/static/image/Group%2023.png'
        }
      };
      const blist2 = {
        '@type': 'ListItem',
        position: '2',
        item: {
          '@id': 'https://avaana.com.au/' + service,
          name: serviceName,
          image: 'https://avaana.com.au/' + imgPath
        }
      };
      const blist3 = {
        '@type': 'ListItem',
        position: '3',
        item: {
          '@id': majorCityLink,
          name: majorCityName,
          image: 'https://avaana.com.au/static/image/Group%2023.png'
        }
      };
      breadCrumbList.push(blist1);
      breadCrumbList.push(blist2);
      breadCrumbList.push(blist3);
      if (suburbName) {
        const blist4 = {
          '@type': 'ListItem',
          position: '4',
          item: {
            '@id': suburbLink,
            name: suburbName,
            image: 'https://avaana.com.au/' + imgPath
          }
        };
        breadCrumbList.push(blist4);
      }

      jsonld = {
        '@context': 'https://schema.org/',
        '@type': 'BreadCrumbList',
        itemListElement: breadCrumbList
      };
    } catch (e) {
      console.info(e);
    }

    return jsonld;
  }

  getProviderSchema(
    service: string,
    serviceName: string,
    serviceLink: string,
    majorCityName: any,
    suburbName: string,
    postCode: string,
    imgPath: string,
    businessName: string,
    businessLogo: string
  ) {
    let jsonld = {};
    try {
      if (majorCityName === null) {
        majorCityName = this.getMajorCityForSuburb(postCode);
      }
      let businessSlugLoc = '';
      if (majorCityName) {
        businessSlugLoc = majorCityName.toLowerCase();
      }
      const majorCityLink = 'https://avaana.com.au/' + service + '/' + businessSlugLoc + '/';
      let suburbLink = '';

      if (suburbName !== null) {
        const suburb = suburbName.split(' ').join('-').toLowerCase();
        suburbLink = 'https://avaana.com.au/' + service + '/' + suburb + '/' + postCode;
        businessSlugLoc = suburb;
      }

      const business = businessName.split(' ').join('-').toLowerCase();
      const businessLink = 'https://avaana.com.au/' + business + '/' + businessSlugLoc;
      const breadCrumbList = [];
      const blist1 = {
        '@type': 'ListItem',
        position: '1',
        item: {
          '@id': 'https://avaana.com.au/',
          name: 'Home',
          image: 'https://avaana.com.au/static/image/Group%2023.png'
        }
      };
      const blist2 = {
        '@type': 'ListItem',
        position: '2',
        item: {
          '@id': serviceLink,
          name: serviceName,
          image: 'https://avaana.com.au/' + imgPath
        }
      };
      const blist3 = {
        '@type': 'ListItem',
        position: '3',
        item: {
          '@id': majorCityLink,
          name: majorCityName,
          image: 'https://avaana.com.au/static/image/Group%2023.png'
        }
      };
      breadCrumbList.push(blist1);
      breadCrumbList.push(blist2);
      breadCrumbList.push(blist3);
      let position = '4';
      if (suburbName !== null) {
        position = '5';
        const blist4 = {
          '@type': 'ListItem',
          position: '4',
          item: {
            '@id': suburbLink,
            name: suburbName,
            image: 'https://avaana.com.au/' + imgPath
          }
        };
        breadCrumbList.push(blist4);
      }
      const blist5 = {
        '@type': 'ListItem',
        position: position,
        item: {
          '@id': businessLink,
          name: businessName,
          image: businessLogo
        }
      };
      breadCrumbList.push(blist5);
      jsonld = {
        '@context': 'https://schema.org/',
        '@type': 'BreadCrumbList',
        itemListElement: breadCrumbList
      };
    } catch (e) {
      console.info(e);
    }
    return jsonld;
  }

  getAvaanaFAQSchema(faqlist: any[]) {
    const faqschemas = [];

    for (let i = 0; i < faqlist.length; i++) {
      const singleFAQ = {
        '@type': 'Question',
        name: faqlist[i].ques,
        acceptedAnswer: { '@type': 'Answer', text: faqlist[i].response }
      };
      faqschemas.push(singleFAQ);
    }

    const jsonld = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqschemas
    };

    return jsonld;
  }

  getCategoryFAQSchema(faqlist: any[]) {
    const faqschemas = [];

    for (let i = 0; i < faqlist.length; i++) {
      const singleFAQ = {
        '@type': 'Question',
        name: faqlist[i].ques,
        acceptedAnswer: { '@type': 'Answer', text: faqlist[i].response }
      };
      faqschemas.push(singleFAQ);
    }

    const jsonld = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqschemas
    };

    return jsonld;
  }

  getSearchItemList(itemlist: any[]) {
    let jsonld = {};
    try {
      const searchList = [];

      for (let i = 0; i < itemlist.length; i++) {
        const itemJson = itemlist[i];
        const blist = {
          '@type': 'ListItem',
          position: Number(i + 1),
          name: itemJson.name,
          image: itemJson.logo_image,
          url:
            'https://avaana.com.au/' +
            itemJson.business.slug +
            '/' +
            itemJson.business.business_location.city.replace(/ /g, '-').toLowerCase() +
            '/' +
            itemJson.slug
        };
        searchList.push(blist);
      }
      jsonld = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: searchList
      };
    } catch (e) {
      console.info(e);
    }

    return jsonld;
  }
}
